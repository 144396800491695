import Breadcrumbs from "components/breadcrumbs";
import { SiteContext } from "context/SitesContext";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { BreadCrumbsProps } from "types";
import {
	SiteOwnerDataSource,
	SiteOwnerParams,
	SiteOwnerResponse,
} from "types/Stations";
import { getFulladdress } from "utils";
import AddEditStationOwners from "./AddEditSiteOwners";
import SiteOwnersList from "./SiteOwnersList";

const SiteOwners = ({
	handleSubmitAddEditStationOwners,
	isLoading,
	siteOwners,
	siteOwnerIsLoading,
	pageTotal,
	handlePageChange,
}: {
	handleSubmitAddEditStationOwners: (
		props: SiteOwnerParams
	) => Promise<boolean>;
	isLoading: boolean;
	pageTotal: number;
	siteOwners: SiteOwnerResponse[];
	siteOwnerIsLoading: boolean;
	handlePageChange: (page: number, pageSize: number) => void;
}) => {
	const { t } = useTranslation(["sites", "navigation"]);
	const [showAddEditModal, setShowAddEditModal] = useState(false);
	const { selectedSiteOwner, handleSelectedSiteOwner } =
		useContext(SiteContext);

	const newSiteOwnerList = siteOwners.map(
		({ uuid: key, name, address, createdBy }) => ({
			key,
			name,
			address: getFulladdress(address),
			createdBy,
		})
	);

	const routes: BreadCrumbsProps[] = [
		{
			path: "/dashboard",
			breadcrumbName: t("home", { ns: "navigation" }),
		},
		{
			path: "/site-owners",
			breadcrumbName: t("siteOwners", { ns: "navigation" }),
		},
	];

	const handleToggleShowAddEditModal = () => {
		setShowAddEditModal(!showAddEditModal);
		handleSelectedSiteOwner(null);
	};

	const handleSubmit = async (props: SiteOwnerParams) => {
		const res = await handleSubmitAddEditStationOwners(props);
		if (res) {
			setShowAddEditModal(false);
		}
	};

	const handleEditSiteOwner = (data: SiteOwnerDataSource) => {
		setShowAddEditModal(true);

		const result = siteOwners.find((siteOwner) => {
			if (siteOwner.name === data.name) {
				return true;
			}

			return false;
		});

		if (!result) return;

		handleSelectedSiteOwner(result);
	};

	return (
		<>
			<Breadcrumbs breadCrumb={routes} />
			<SiteOwnersList
				siteOwnerIsLoading={siteOwnerIsLoading}
				siteOwners={newSiteOwnerList}
				handleEditSiteOwner={handleEditSiteOwner}
				handlePageChange={handlePageChange}
				pageTotal={pageTotal}
				handleToggleShowAddEditModal={handleToggleShowAddEditModal}
			/>
			<AddEditStationOwners
				isLoading={isLoading}
				showModal={showAddEditModal}
				selectedSiteOwner={selectedSiteOwner}
				handleShowModal={handleToggleShowAddEditModal}
				onSubmitForm={handleSubmit}
			/>
		</>
	);
};

export default SiteOwners;
