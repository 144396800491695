import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import MoreOptionsMenu from "components/common/MoreOptionsMenu";
import { OrganizationDataSource } from "types/UserManagement";
import OrganizationToolbar from "./OrganizationToolbar";

const OrganizationsList = ({
	organizations,
	handleToggleShowAddEditModal,
	organizationIsLoading,
	handlePageChange,
	handleEditOrganization,
	pageTotal,
}: {
	organizations: OrganizationDataSource[];
	handleToggleShowAddEditModal: () => void;
	organizationIsLoading: boolean;
	pageTotal: number;
	handleEditOrganization: (organization: OrganizationDataSource) => void;
	handlePageChange: (page: number, size: number) => void;
}) => {
	const { t } = useTranslation(["common"]);
	const [tableData, setTableData] = useState<OrganizationDataSource[]>([]);

	/* Show the search result in the table */
	const handleSearch = (searchTerm: string) => {
		const result = tableData.filter(
			(data: OrganizationDataSource) =>
				data.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0 ||
				data.address.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
		);
		setTableData(result);
		if (!searchTerm) {
			setTableData(organizations);
		}
	};

	useEffect(() => {
		setTableData(organizations);
	}, [organizations]);

	const columns: ColumnsType<OrganizationDataSource> = [
		{
			dataIndex: "name",
			title: t("name"),
			key: "name",
			sorter: (a, b) => a.name.length - b.name.length,
		},
		{
			dataIndex: "address",
			title: t("locations.address"),
			key: "address",
		},
		{
			title: t("action"),
			key: "operation",
			fixed: "right",
			align: "center",
			width: 100,
			render: (item) => <MoreOptionsMenu menuList={List} data={item} />,
		},
	];

	const List = [
		{
			label: t("edit"),
			onClick: (data: OrganizationDataSource) => handleEditOrganization(data),
		},
	];

	return (
		<div>
			<OrganizationToolbar
				handleShowAddStationOwnerModal={handleToggleShowAddEditModal}
				handleSubmit={handleSearch}
			/>

			<Table
				loading={organizationIsLoading}
				dataSource={tableData || []}
				columns={columns}
				scroll={{ y: 450, x: 900 }}
				pagination={{
					total: pageTotal,
					pageSizeOptions: ["10", "20", "50", "100"],
					showSizeChanger: true,
					onChange: (page, pageSize) => handlePageChange(page, pageSize),
				}}
			/>
		</div>
	);
};

export default OrganizationsList;
