import {
	CreateOrganization,
	GetAllOrganization,
	UpdateOrganization,
} from "api/users-management";
import { AlertContext } from "context/AlertContext";
import DashboardLayout from "Layouts/DashboardLayout";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import _ from "lodash";
import { ErrorResponse } from "types";
import { SiteContext } from "context/SitesContext";
import { OrganizationParams, OrganizationResponse } from "types/UserManagement";
import Organizations from "components/usersManagement/organizations";
import { OrganizationTypeResponse } from "types/ApplicationManagement";
import { fetchOrganizationType } from "api/application-management";

const OrganizationsPage = () => {
	const { t } = useTranslation(["navigation", "constants", "usersmanagement"]);
	const [pagination, setPaginationState] = useState({ page: 0, pageSize: 10 });

	const { handleShowAlert } = useContext(AlertContext);
	const { selectedOrganization } = useContext(SiteContext);
	const { uuid } = selectedOrganization || {};

	const queryClient = useQueryClient();

	const { mutateAsync, isLoading } = useMutation<
		OrganizationResponse,
		ErrorResponse,
		OrganizationParams
	>(CreateOrganization, {
		onSuccess: () => {
			queryClient.invalidateQueries("organizations");
		},
		onMutate: (data) => {
			queryClient.setQueryData("organizations", data);
		},
	});

	const { mutateAsync: updateMuteAsync, isLoading: updateOrganizationLoading } =
		useMutation<OrganizationResponse, ErrorResponse, OrganizationParams>(
			UpdateOrganization,
			{
				onSuccess: () => {
					queryClient.invalidateQueries("organizations");
				},
				onMutate: (data) => {
					queryClient.setQueryData("organizations", data);
				},
			}
		);

	const { page, pageSize } = pagination;

	const {
		isLoading: organizationIsLoading,
		data,
		refetch,
	} = useQuery("organizations", () => GetAllOrganization(page, pageSize));

	const { isLoading: organisationTypeLoading, data: organisationTypeData } =
		useQuery<OrganizationTypeResponse[]>(
			"fetchOrganizationType",
			fetchOrganizationType
		);

	useEffect(() => {
		refetch();
	}, [pagination]);

	const organizations: OrganizationResponse[] = _.get(data, "content") || [];
	const totalPages: number = _.get(data, "totalPages");

	const handleSubmitAddEditStationOwners = async (
		props: OrganizationParams
	): Promise<boolean> => {
		const mutate = !selectedOrganization ? mutateAsync : updateMuteAsync;

		const { message } = await mutate({ ...props, uuid });

		if (!message) return false;

		if (
			message ===
				t("The organization was created successfully", {
					lng: "en",
					ns: "constants",
				}) ||
			message ===
				t("The organization was updated successfully", {
					lng: "en",
					ns: "constants",
				})
		) {
			handleShowAlert({ message, severity: "success" });
			return true;
		}

		handleShowAlert({ message, severity: "error" });

		return false;
	};

	const hanlePageChange = (pageNumber: number, size: number) => {
		setPaginationState({ page: pageNumber, pageSize: size });
	};

	return (
		<DashboardLayout>
			<div className="flex flex-col">
				<span className="text-2xl font-medium pb-10">
					{t("organizations", { ns: "usersmanagement" })}
				</span>

				<Organizations
					pageTotal={totalPages}
					handleSubmitAddEditStationOwners={handleSubmitAddEditStationOwners}
					isLoading={
						isLoading || updateOrganizationLoading || organisationTypeLoading
					}
					organizations={organizations}
					organizationIsLoading={organizationIsLoading}
					handlePageChange={hanlePageChange}
					organisationType={organisationTypeData || []}
				/>
			</div>
		</DashboardLayout>
	);
};

export default OrganizationsPage;
