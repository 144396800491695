import StationsHeader from "components/navbars/StationsHeader";

import { ReactNode } from "react";

const TopHeaderLayout = ({ children }: { children: ReactNode }) => (
	<>
		<StationsHeader />
		<div>{children}</div>
	</>
);

export default TopHeaderLayout;
