import SlidePage from "components/common/SlidePage";

const SiteDetails = ({
	open,
	toggleOpen,
}: {
	open: boolean;
	toggleOpen: () => void;
}) => (
	<SlidePage title="Charging Station" open={open} toggleOpen={toggleOpen}>
		<p>Charging station information</p>
	</SlidePage>
);

export default SiteDetails;
