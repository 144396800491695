import {
	PasswordField,
	PhoneField,
	SubmitButton,
	TextField,
	Select,
} from "components/form/InputField";
import { Switch } from "@headlessui/react";
import Modal from "components/modals/Modal";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { roleTypes } from "constants/index";
import { useTranslation } from "react-i18next";
import { UserParams, UsersResponse } from "types/UsersManagement";
import { RolesResponse } from "types/ApplicationManagement";
import { AutoComplete } from "antd";
import { OrganizationResponse } from "types/UserManagement";

const AddEditUser = ({
	handleShowUserModal,
	showModal,
	selectedUser,
	rolesOptions,
	onSubmitForm,
	isLoading,
	organizations,
	handleSearchOrganization,
}: {
	handleShowUserModal: () => void;
	showModal: boolean;
	selectedUser?: UsersResponse | null;
	rolesOptions: RolesResponse[];
	isLoading: boolean;
	organizations: OrganizationResponse[];
	onSubmitForm: (data: UserParams) => void;
	handleSearchOrganization: (value: string) => void;
}) => {
	const {
		firstName,
		lastName,
		email,
		enabled,
		userType,
		phoneNumber,
		organization,
		roleNames,
	} = selectedUser || {};
	const { t } = useTranslation(["accounts", "settings", "common"]);
	const [isEnabled, setIsEnabled] = useState<boolean | undefined>(enabled);

	const organizationName = organization?.name;

	const form = useForm<UserParams>({
		defaultValues: {
			firstName: firstName || "",
			lastName: lastName || "",
			email: email || "",
			enabled: enabled || false,
			password: "",
			organizationName: organizationName || "",
			phoneNumber: phoneNumber || "",
			userType: userType || "",
			roleNames: roleNames && roleNames[0],
		},
	});

	const { handleSubmit, register, setValue, reset } = form;

	const optionsAutoComplete = organizations.map((org) => ({
		value: org.name,
		label: org.name,
	}));

	useEffect(() => {
		reset({
			firstName: firstName || "",
			lastName: lastName || "",
			email: email || "",
			enabled: enabled || false,
			userType: userType || "",
			phoneNumber: phoneNumber || "",
			password: "",
			organizationName: organizationName || "",
			roleNames: roleNames && roleNames[0],
		});
	}, [selectedUser]);

	useEffect(() => {
		setIsEnabled(enabled);
	}, [enabled]);

	const onSubmit = (props: UserParams) => {
		const params: UserParams = {
			...props,
			callingCode: "+1",
			enabled: isEnabled || true,
			roleNames: [props.roleNames as unknown as string],
			organizationUuid:
				organizations.find((org) => org.name === props.organizationName)
					?.uuid || "",
		};
		onSubmitForm(params);
	};

	const generatePassword = () => {
		const password = Math.random().toString(36).slice(-8);

		setValue("password", password);
	};

	return (
		<Modal
			title={!selectedUser ? t("createUser") : t("updateUser")}
			show={showModal}
			onClose={() => handleShowUserModal()}
		>
			<FormProvider {...form}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="mb-2">
						<TextField
							data-testid="first-name"
							className="mb-2"
							name="firstName"
							label={t("firstName")}
							placeholder={t("firstName")}
							required
							ref={register({ required: true })}
							autoComplete="firstName"
						/>
					</div>
					<div className="mb-2">
						<TextField
							className="mb-2"
							name="lastName"
							label={t("lastName")}
							placeholder={t("lastName")}
							required
							ref={register({ required: true })}
							autoComplete="lastName"
						/>
					</div>
					<div className="mb-2">
						<PhoneField
							name="phoneNumber"
							label={t("phoneNumber")}
							placeholder={t("phoneNumber")}
							required
							autoComplete="tel-national"
							rules={{ required: true }}
						/>
					</div>
					<div className="mb-2 mt-2">
						<TextField
							className="mb-2"
							name="email"
							label={t("emailAddress")}
							placeholder="example@example.com"
							required
							autoComplete="username"
							ref={register({ required: true })}
						/>
					</div>
					<PasswordField
						className="mb-2"
						name="password"
						label={t("password")}
						required={!selectedUser}
						placeholder={t("password")}
						autoComplete="current-password"
						ref={register({
							required: !selectedUser,
						})}
					/>
					<div className="mb-2">
						<button type="button" onClick={generatePassword}>
							<span className="text-primary-color">Generate password</span>
						</button>
					</div>
					<div className="mb-3">
						<Select
							rootClass="col-lg-6 mb-2"
							name="roleNames"
							label={t("roles.role", { ns: "settings" })}
							required
							ref={register({ required: true })}
							placeholder={t("roles.rolePlaceholder", { ns: "settings" })}
							options={rolesOptions}
						/>
					</div>
					<div className="mb-3">
						<Select
							rootClass="col-lg-6 mb-2"
							name="userType"
							label={t("type", { ns: "common" })}
							placeholder={t("roles.rolePlaceholder", { ns: "common" })}
							required
							ref={register({ required: true })}
							options={roleTypes}
						/>
					</div>

					<div className="mb-4 w-full">
						<AutoComplete
							defaultValue={organizationName}
							onSearch={(term) => handleSearchOrganization(term)}
							className="w-full"
							options={optionsAutoComplete}
							allowClear
						>
							<TextField
								data-testid="organizationName"
								className="mb-2"
								name="organizationName"
								label={t("organization", { ns: "usersmanagement" })}
								placeholder={t("seachOrganization", { ns: "usersmanagement" })}
								required
								autoComplete="organizationName"
								ref={register({ required: true })}
							/>
						</AutoComplete>
					</div>

					{selectedUser && (
						<Switch.Group>
							<div className="mt-4  flex items-center">
								<Switch.Label
									passive
									className="mr-10 font-medium text-gray-700"
								>
									{t("roles.enableRole", { ns: "settings" })}
								</Switch.Label>
								<Switch
									checked={isEnabled || false}
									onChange={() => setIsEnabled(!isEnabled)}
									className={`${
										isEnabled ? "bg-primary-color" : "bg-gray-200"
									} relative inline-flex h-6 w-12 items-center rounded-full`}
								>
									<span
										className={`${
											!isEnabled ? "translate-x-6" : "translate-x-1"
										} inline-block h-4 w-4 transform rounded-full bg-white`}
									/>
								</Switch>
							</div>
						</Switch.Group>
					)}

					<SubmitButton
						type="submit"
						loading={isLoading}
						label={!selectedUser ? t("createUser") : t("updateUser")}
						className="mt-8 flex m-auto my-5 text-center"
					/>
				</form>
			</FormProvider>
		</Modal>
	);
};
export default AddEditUser;
