import { useTranslation } from "react-i18next";
import usePlacesAutocomplete, {
	getGeocode,
	getLatLng,
} from "use-places-autocomplete";
import { BiCurrentLocation } from "react-icons/bi";
import {
	Combobox,
	ComboboxInput,
	ComboboxPopover,
	ComboboxList,
	ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import StationFilter from "../stations/StationFilter";

type PlacesProps = {
	setOffice: (position: google.maps.LatLngLiteral) => void;
	getLocation: () => void;
};

const Places = ({ setOffice, getLocation }: PlacesProps) => {
	const { t } = useTranslation(["common"]);

	const {
		ready,
		value,
		setValue,
		suggestions: { status, data },
		clearSuggestions,
	} = usePlacesAutocomplete();

	const handleSelect = async (val: string) => {
		setValue(val, false);
		clearSuggestions();

		const results = await getGeocode({ address: val });
		const { lat, lng } = getLatLng(results[0]);
		setOffice({ lat, lng });
	};

	return (
		<div className="lg:w-1/2 w-11/12 flex m-auto translate-y-36 flex-wrap sm:flex-nowrap">
			<button
				className="z-20 bg-white justify-between rounded-lg p-5 mr-2 border"
				onClick={getLocation}
			>
				<BiCurrentLocation size={24} />
			</button>
			<StationFilter className="p-5">
				<p>Hi</p>
			</StationFilter>
			<div className="w-full flex bg-white justify-between rounded-lg z-20 border mt-2 sm:mt-0">
				<Combobox className="w-full rounded-lg" onSelect={handleSelect}>
					<ComboboxInput
						value={value}
						onChange={(e) => setValue(e.target.value)}
						disabled={!ready}
						width="100%"
						className="combobox-input w-full p-5 rounded-lg"
						placeholder={t("search.placesSearchText")}
					/>
					<ComboboxPopover>
						<ComboboxList>
							{status === "OK" &&
								data.map(({ place_id: placeId, description }) => (
									<ComboboxOption key={placeId} value={description} />
								))}
						</ComboboxList>
					</ComboboxPopover>
				</Combobox>
			</div>
		</div>
	);
};

export default Places;
