import {
	OrganizationTypeParams,
	PermissionsParams,
	RolesParams,
} from "types/ApplicationManagement";
import apiRequestHandler from "utils/apiRequestHandler";

const addRoles = (params: RolesParams): any => {
	const res = apiRequestHandler({
		url: "/roles",
		data: params,
		method: "post",
	});
	return res;
};

const fetchRoles = (): any => {
	const res = apiRequestHandler({
		url: "roles",
		data: {},
		method: "get",
	});
	return res;
};

const updateRoles = ({ uuid, ...rest }: RolesParams): any => {
	const res = apiRequestHandler({
		url: `/roles/${uuid}`,
		data: rest,
		method: "put",
	});
	return res;
};

const addPermissions = (params: PermissionsParams): any => {
	const res = apiRequestHandler({
		url: "/permissions",
		data: params,
		method: "post",
	});
	return res;
};

const fetchPermissions = (): any => {
	const res = apiRequestHandler({
		url: "permissions",
		data: {},
		method: "get",
	});
	return res;
};

const updatePermissions = ({ uuid, ...rest }: PermissionsParams): any => {
	const res = apiRequestHandler({
		url: `/permissions/${uuid}`,
		data: rest,
		method: "put",
	});
	return res;
};

const fetchSingleRole = (uuid: string): any => {
	const res = apiRequestHandler({
		url: `/roles/${uuid}`,
		data: {},
		method: "get",
	});
	return res;
};

const updateRolePermissions = ({
	uuid,
	selectedPermissions,
}: {
	uuid: string;
	selectedPermissions: string[];
}): any => {
	const res = apiRequestHandler({
		url: `/roles/${uuid}/permissions`,
		data: { selectedPermissions },
		method: "put",
	});
	return res;
};

const fetchOrganizationType = (): any => {
	const res = apiRequestHandler({
		url: `/organization-types`,
		data: {},
		method: "get",
	});
	return res;
};

const fetchOrganizationTypeByUUID = (uuid: string): any => {
	const res = apiRequestHandler({
		url: `/organization-types/${uuid}`,
		data: {},
		method: "get",
	});
	return res;
};

const createOrganizationType = (params: OrganizationTypeParams): any => {
	const res = apiRequestHandler({
		url: "/organization-types",
		data: params,
		method: "post",
	});
	return res;
};

const updateOrganizationType = ({
	uuid,
	...rest
}: OrganizationTypeParams): any => {
	const res = apiRequestHandler({
		url: `/organization-types/${uuid}`,
		data: rest,
		method: "put",
	});
	return res;
};

export {
	fetchRoles,
	updateRoles,
	addRoles,
	fetchPermissions,
	updatePermissions,
	addPermissions,
	fetchSingleRole,
	updateRolePermissions,
	fetchOrganizationType,
	fetchOrganizationTypeByUUID,
	createOrganizationType,
	updateOrganizationType,
};
