import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const AlertContext = React.createContext<{
	showAlert: boolean;
	handleShowAlert: (params: {
		message: string;
		severity: "success" | "error" | "warning" | "info";
	}) => void;
	message: string;
	severity: "success" | "error" | "warning" | "info";
}>({
	showAlert: false,
	message: "",
	severity: "info",
	handleShowAlert: () => {},
});

const AlertContextProvider = (props: {
	children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}) => {
	const [showAlert, setShowAlert] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");
	const [alertSeverity, setAlertSeverity] = useState<
		"success" | "error" | "warning" | "info"
	>("info");
	const { t } = useTranslation(["constants"]);

	const handleShowAlert = (data: {
		message: string;
		severity: "success" | "error" | "warning" | "info";
	}) => {
		if (showAlert) return;

		const { message, severity } = data;
		setShowAlert(true);
		setAlertMessage(t(message));
		setAlertSeverity(severity);

		setTimeout(() => {
			setShowAlert(false);
		}, 6000);
	};

	const value = React.useMemo(
		() => ({
			showAlert,
			handleShowAlert,
			message: alertMessage,
			severity: alertSeverity,
		}),
		[showAlert, handleShowAlert]
	);

	return (
		<AlertContext.Provider value={value}>
			{props.children}
		</AlertContext.Provider>
	);
};

export { AlertContext, AlertContextProvider };
