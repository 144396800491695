import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { SiteContext } from "context/SitesContext";
import Breadcrumbs from "components/breadcrumbs";
import {
	ChargingStationParams,
	ChargingStationResponse,
	ChargingStationDataSource,
	SiteResponse,
} from "types/Stations";
import MoreOptionsMenu from "components/common/MoreOptionsMenu";
import TabMenu from "components/common/TabMenu";
import Chart from "react-apexcharts";
import { BreadCrumbsProps } from "types";
import ChargersList from "./ChargersList";
import Loading from "../../../assets/svg/assets/Loading";
import CreateEditChargingStations from "../siteOwner/CreateEditChargingStations";

const ChargerStats = ({
	chargers,
}: {
	chargers: ChargingStationResponse[];
}) => {
	const { t } = useTranslation(["sites"]);

	// Todo: Confirm how to determine online status.
	const online: number = chargers.reduce(
		(acc: number, c: ChargingStationResponse) =>
			acc + +(c.availabilityStatus === "online"),
		0
	);

	return (
		<div className="p-5 bg-white grow-0 rounded-md lg:1/3 lg:grow">
			<div className="">
				<p className="mb-0 text-gray-500 font-medium">
					{t("chargers.totalChargers")}:
				</p>
				<span className="text-2xl font-medium">{chargers.length}</span>
			</div>
			<div className="">
				<p className="mb-0 text-gray-500 font-medium">
					{t("chargers.onlineChargers")}:
				</p>
				<span className="text-2xl font-medium">{online}</span>
			</div>
			<div className="">
				<p className="mb-0 text-gray-500 font-medium">
					{t("chargers.offlineChargers")}:
				</p>
				<span className="text-2xl font-medium">{chargers.length - online}</span>
			</div>
		</div>
	);
};

const DonoughtChart = ({ data }: any) => (
	<div className="bg-white lg:1/3 rounded-md flex items-center grow justify-center">
		<Chart
			options={data.options}
			series={data.series}
			type="donut"
			width="100%"
		/>
	</div>
);

const PeriodicStats = () => {
	const { t } = useTranslation(["sites", "common"]);

	return (
		<div className="p-5 bg-white grow-0 rounded-md lg:1/3 lg:grow">
			<div className="flex justify-end">
				<select
					onChange={(_) => {}}
					defaultValue=""
					name="actionItem"
					id="actionItem"
					className="border-none w-36 bg-gray-100 rounded-md p-2 px-4"
				>
					<option value="" disabled>
						{t("monthToDate", { ns: "common" })}
					</option>
				</select>
			</div>
			<div className="mt-5">
				<p className="mb-0 text-gray-500 font-medium">
					{t("chargers.onlineChargers")}:
				</p>
				<span className="text-2xl font-medium">7</span>
			</div>
			<div className="">
				<p className="mb-0 text-gray-500 font-medium">
					{t("chargers.offlineChargers")}:
				</p>
				<span className="text-2xl font-medium">0.0000 kWhs</span>
			</div>
		</div>
	);
};

const Site = ({
	site,
	isLoading,
	chargers,
	handlePageChange,
	pageTotal,
	handleSubmitAddEditChargingStation,
}: {
	site: SiteResponse;
	isLoading: boolean;
	chargers: ChargingStationResponse[];
	handlePageChange: (page: number, pageSize: number) => void;
	pageTotal: number;
	handleSubmitAddEditChargingStation: (
		props: ChargingStationParams
	) => Promise<boolean>;
}) => {
	const { t } = useTranslation(["sites", "navigation", "common"]);
	const params: { siteUUID: string; siteOwnerUUID: string } = useParams();
	const { siteUUID, siteOwnerUUID } = params || {
		siteUUID: "",
		siteOwnerUUID: "",
	};

	const { handleSelectedChargingStation, selectedChargingStation } =
		useContext(SiteContext);

	const [showAddEditChargingStationModal, setShowAddEditChargingStationModal] =
		useState<boolean>(false);

	const routes: BreadCrumbsProps[] = [
		{
			path: "/dashboards",
			breadcrumbName: t("home", { ns: "navigation" }),
		},
		{
			path: "/site-owners",
			breadcrumbName: t("siteOwners", { ns: "navigation" }),
		},
		{
			path: `/site-owners/site-owner/${siteOwnerUUID}`,
			breadcrumbName: t("siteOwner", { ns: "navigation" }),
		},
		{
			path: `/site-owners/site-owner/${siteOwnerUUID}/site/${siteUUID}`,
			breadcrumbName: t("site", { ns: "navigation" }),
		},
	];

	const handleToggleShowAddEditChargingStationModal = (
		data?: ChargingStationDataSource
	) => {
		setShowAddEditChargingStationModal(!showAddEditChargingStationModal);

		if (data) {
			const station = chargers.find(
				(chargingStation) => chargingStation.uuid === data.key
			);

			station && handleSelectedChargingStation(station);
		} else {
			handleSelectedChargingStation(null);
		}
	};

	const handleSubmitChargingStation = async (data: ChargingStationParams) => {
		const res = await handleSubmitAddEditChargingStation(data);
		if (res) {
			handleToggleShowAddEditChargingStationModal();
		}
	};

	const dataChart = {
		options: {
			stroke: {
				show: false,
			},
			dataLabels: {
				enabled: false,
			},
		},
		series: [44, 55, 41, 17, 15],
		labels: ["A", "B", "C", "D", "E"],
	};

	const siteOptionList = [
		{
			label: t("editSiteOwner"),
			onClick: () => {},
		},
	];

	const ComponentB = () => <div>B</div>;

	const data = [
		{
			title: t("chargers.title"),
			content: (
				<ChargersList
					chargingStations={chargers}
					pageTotal={pageTotal}
					handlePageChange={handlePageChange}
					handleToggleShowAddEditChargingStationModal={
						handleToggleShowAddEditChargingStationModal
					}
				/>
			),
		},
		{
			title: t("equipment"),
			content: <ComponentB />,
		},
		{
			title: t("issues"),
			content: <ComponentB />,
		},
		{
			title: t("contacts"),
			content: <ComponentB />,
		},
	];

	return (
		<>
			<Breadcrumbs breadCrumb={routes} />
			<div className="flex w-full justify-end items-center mt-5">
				<MoreOptionsMenu
					buttonClassName="p-2"
					label={t("options", { ns: "common" })}
					size="md"
					menuList={siteOptionList}
				/>
			</div>

			<section className="md:flex justify-between gap-5 rounded-md my-5">
				<ChargerStats chargers={chargers} />
				<DonoughtChart data={dataChart} />
				<PeriodicStats />
			</section>

			{!isLoading ? <TabMenu tabs={data} /> : <Loading />}

			<CreateEditChargingStations
				isLoading={isLoading}
				showModal={showAddEditChargingStationModal}
				selectedChargingStation={selectedChargingStation}
				handleShowModal={handleToggleShowAddEditChargingStationModal}
				onSubmitForm={handleSubmitChargingStation}
				siteUuid={site.uuid}
			/>
		</>
	);
};
export default Site;
