import Breadcrumbs from "components/breadcrumbs";
import { EVCardContext } from "context/EVCardContext";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	BreadCrumbsProps,
	DropDownOption,
	EVCardDataType,
} from "types/EVCards";
import AddEditEVCard from "./AddEditEVCard";
import EVCardList from "./EVCardList";

const EVCards = ({
	evCardList,
	isLoading,
	userOptions,
	userVehicleOptions,
	handleUserUuidChange,
	handleSubmitAddEditCard,
	clearVehicleList,
	pageTotal,
	handlePageChange,
}: {
	evCardList: any[];
	isLoading: boolean;
	userOptions: DropDownOption[];
	userVehicleOptions: DropDownOption[];
	handleUserUuidChange: (userUuid: string) => void;
	handleSubmitAddEditCard: (data: any) => void;
	clearVehicleList: () => void;
	pageTotal: number;
	handlePageChange: (page: number, pageSize: number) => void;
}) => {
	const { t } = useTranslation(["settings", "common", "navigation"]);

	const [showModal, setShowModal] = useState(false);
	const parentCardOptions = [
		{
			name: "No Parent Card",
			value: null,
		},
		...evCardList.map((card) => ({
			name: card.cardNumber,
			value: card.tagId,
		})),
	];
	const { handleSelectedCard, selectedCard } = useContext(EVCardContext);

	const routes: BreadCrumbsProps[] = [
		{
			path: "/dashboard",
			breadcrumbName: t("home", { ns: "navigation" }),
		},
		{
			path: "/ev-cards",
			breadcrumbName: t("evCardsTitle", { ns: "evCards" }),
		},
	];

	const handleShowEVCardModal = (data?: EVCardDataType) => {
		if (data) {
			const evCardRes = evCardList.find((evCard) => evCard.uuid === data.uuid);
			evCardRes && handleSelectedCard(evCardRes);
		} else {
			handleSelectedCard(null);
		}
		clearVehicleList();
		setShowModal(!showModal);
	};

	const handleSubmit = async (data: EVCardDataType) => {
		handleSubmitAddEditCard(data);
		handleShowEVCardModal();
		handleSelectedCard(null);
	};

	return (
		<>
			<Breadcrumbs breadCrumb={routes} />
			<EVCardList
				evCardList={evCardList}
				handlePageChange={handlePageChange}
				pageTotal={pageTotal}
				handleShowEVCardModal={handleShowEVCardModal}
			/>
			<AddEditEVCard
				showModal={showModal}
				selectedCard={selectedCard}
				onSubmitForm={handleSubmit}
				isLoading={isLoading}
				handleShowEVCardModal={handleShowEVCardModal}
				parentCardOptions={parentCardOptions}
				userOptions={userOptions}
				vehicleOptions={userVehicleOptions}
				handleUserUuidChange={handleUserUuidChange}
			/>
		</>
	);
};

export default EVCards;
