import { GetAllSites, GetChargingStationsBySiteUUID } from "api/admin";
import MapView from "components/common/Map";
import _ from "lodash";
import TopHeaderLayout from "Layouts/TopHeaderLayout";
import { useQuery } from "react-query";
import { ChargingStationResponse, SiteResponse } from "types/Stations";
import SiteDetails from "components/stations/SiteDetails";
import { useState } from "react";

const Stations = () => {
	const [showMoreDetails, setShowMoreDetails] = useState<boolean>(false);
	const [chargers, setChargers] = useState<ChargingStationResponse[]>([]);
	const [inViewLoading, setinViewLoading] = useState<boolean>(false);
	const { isLoading: siteIsLoading, data } = useQuery("sites", GetAllSites);

	const sites: SiteResponse[] = _.get(data, "content") || [];

	const handleToggleSiteDetail = () => {
		setShowMoreDetails(!showMoreDetails);
	};

	const handleFetchChargers = async (uuid: string) => {
		setinViewLoading(true);
		const res = await GetChargingStationsBySiteUUID(uuid);

		const result: ChargingStationResponse[] = _.get(res, "content") || [];

		setinViewLoading(false);

		setChargers(result);
	};

	return (
		<TopHeaderLayout>
			<MapView
				sites={sites}
				inViewLoading={inViewLoading}
				isLoading={siteIsLoading}
				chargers={chargers}
				handleFetchChargers={handleFetchChargers}
				handleToggleSiteDetail={handleToggleSiteDetail}
			/>
			<SiteDetails open={showMoreDetails} toggleOpen={handleToggleSiteDetail} />
		</TopHeaderLayout>
	);
};

export default Stations;
