import axios from "axios";
import { ErrorResponse } from "types";
import { getSessionCookie } from "./cookies";
import config from "../config";

const client = axios.create({
	baseURL: config.baseUrl,
});

const apiRequestHandler = async (options: {
	data: any;
	method: any;
	url: string;
	[key: string]: any;
}) => {
	const token = getSessionCookie();

	client.defaults.headers.common.Authorization = `Bearer ${token}`;

	if (config.withoutGateway) {
		client.defaults.baseURL = getBaseUrl(options.url.split("/")[1]);
	}

	const onSucess = (res: any) => res.data;
	const onError = (error: ErrorResponse) => {
		if (axios.isAxiosError(error)) {
			if (error.response?.data) return error.response?.data;

			return options.method === "get"
				? undefined
				: {
						message: error.response?.data,
						status: error.response?.status,
				  };
		}

		return { message: error.message, status: 500 };
	};

	return client(options).then(onSucess).catch(onError);
};

const getBaseUrl = (urlModelName: string) => {
	const chargingStationManagementModels = [
		"charging-stations",
		"ev-cards",
		"sites",
		"charger-operations",
	];

	const isChargingStation =
		chargingStationManagementModels.includes(urlModelName);
	const baseUrl = isChargingStation
		? config.chargingStationManagementBaseUrl
		: config.userManagementBaseUrl;

	return baseUrl;
};
export default apiRequestHandler;
