import SupportList from "components/support";
import DashboardLayout from "Layouts/DashboardLayout";
import { useTranslation } from "react-i18next";
import {
	issues,
	statusOptions,
	priorityOptions,
} from "../../assets/mock/support";

const Support = () => {
	const { t } = useTranslation("navigation");

	return (
		<DashboardLayout>
			<div className="flex flex-col">
				<div className="mb-5">
					<span className="text-2xl font-medium pb-10">{t("support")}</span>
				</div>
			</div>
			<SupportList
				issues={issues}
				statusOptions={statusOptions}
				priorityOptions={priorityOptions}
			/>
		</DashboardLayout>
	);
};
export default Support;
