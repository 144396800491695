import SearchInput from "components/common/SearchInput";
import { SubmitButton } from "components/form/InputField";
import { useTranslation } from "react-i18next";

const UserToolbar = ({
	handleSubmit,
	handleShowUserModal,
}: {
	handleSubmit: (data: string) => void;
	handleShowUserModal: () => void;
}) => {
	const { t } = useTranslation(["accounts", "common"]);
	return (
		<div className="py-4 justify-between items-end md:items-center flex flex-col md:flex-row">
			<SearchInput
				handleSubmit={handleSubmit}
				placeholder={t("userManagement.searchUser", { ns: "common" })}
			/>
			{/* <SearchInput handleSubmit={handleSubmit} /> */}
			<SubmitButton
				className="my-5 rounded-full"
				type="button"
				onClick={handleShowUserModal}
				label={t("createUser")}
			/>
		</div>
	);
};

export default UserToolbar;
