import Breadcrumbs from "components/breadcrumbs";
import { SiteContext } from "context/SitesContext";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { OrganizationTypeResponse } from "types/ApplicationManagement";
import { BreadCrumbsProps } from "types/index";
import {
	OrganizationDataSource,
	OrganizationParams,
	OrganizationResponse,
} from "types/UserManagement";
import { getFulladdress } from "utils";
import AddEditOrganization from "./AddEditOrganization";
import OrganizationsList from "./OrganizationsList";

const Organizations = ({
	handleSubmitAddEditStationOwners,
	isLoading,
	organizations,
	organizationIsLoading,
	pageTotal,
	handlePageChange,
	organisationType,
}: {
	handleSubmitAddEditStationOwners: (
		props: OrganizationParams
	) => Promise<boolean>;
	isLoading: boolean;
	pageTotal: number;
	organisationType: OrganizationTypeResponse[];
	organizations: OrganizationResponse[];
	organizationIsLoading: boolean;
	handlePageChange: (page: number, pageSize: number) => void;
}) => {
	const { t } = useTranslation(["sites", "navigation"]);
	const [showAddEditModal, setShowAddEditModal] = useState(false);
	const { selectedOrganization, handleSelectedOrganization } =
		useContext(SiteContext);

	const organizationList: OrganizationDataSource[] = organizations.map(
		({ uuid: key, name, address }) => ({
			key,
			name,
			address: getFulladdress(address),
		})
	);

	const routes: BreadCrumbsProps[] = [
		{
			path: "/dashboard",
			breadcrumbName: t("home", { ns: "navigation" }),
		},
		{
			path: "/organizations",
			breadcrumbName: t("organizations", { ns: "navigation" }),
		},
	];

	const handleToggleShowAddEditModal = () => {
		setShowAddEditModal(!showAddEditModal);
		handleSelectedOrganization(null);
	};

	const handleSubmit = async (props: OrganizationParams) => {
		const res = await handleSubmitAddEditStationOwners(props);
		if (res) {
			setShowAddEditModal(false);
		}
	};

	const handleEditOrganization = (data: OrganizationDataSource) => {
		setShowAddEditModal(true);

		const result = organizations.find((organization) => {
			if (organization.name === data.name) {
				return true;
			}

			return false;
		});

		if (!result) return;

		handleSelectedOrganization(result);
	};

	return (
		<>
			<Breadcrumbs breadCrumb={routes} />
			<OrganizationsList
				organizationIsLoading={organizationIsLoading}
				organizations={organizationList}
				handleEditOrganization={handleEditOrganization}
				handlePageChange={handlePageChange}
				pageTotal={pageTotal}
				handleToggleShowAddEditModal={handleToggleShowAddEditModal}
			/>
			<AddEditOrganization
				isLoading={isLoading}
				showModal={showAddEditModal}
				selectedOrganization={selectedOrganization}
				handleShowModal={handleToggleShowAddEditModal}
				onSubmitForm={handleSubmit}
				organisationType={organisationType}
			/>
		</>
	);
};

export default Organizations;
