import DashboardLayout from "Layouts/DashboardLayout";
import { ReactNode } from "react";
import SettingsNavigation from "components/navbars/SettingsNavigation";
import { useTranslation } from "react-i18next";

const SettingsLayout = ({ children }: { children: ReactNode }) => {
	const { t } = useTranslation(["navigation"]);
	return (
		<DashboardLayout>
			<div className="flex flex-col">
				<span className="text-2xl font-medium pb-10">{t("settings")}</span>
				<div className="flex flex-col mb-5">
					<SettingsNavigation />

					{children}
				</div>
			</div>
		</DashboardLayout>
	);
};

export default SettingsLayout;
