import { PasswordField, SubmitButton } from "components/form/InputField";
import { ChangePasswordParams } from "types/Auth";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PasswordStrengthMeter from "components/auth/PasswordStrengthMeter";

const UserPasswordForm = ({
	isLoading,
	handleUpdatePassword,
}: {
	isLoading: boolean;
	handleUpdatePassword: (props: ChangePasswordParams) => Promise<boolean>;
}) => {
	const { t } = useTranslation(["settings", "common"]);
	const form = useForm<ChangePasswordParams>({
		defaultValues: {
			currentPassword: "",
			newPassword: "",
			confirmNewPassword: "",
		},
	});

	const { handleSubmit, register, watch } = form;
	const currentPassword = watch("newPassword");

	const onSubmit = async (props: ChangePasswordParams) => {
		await handleUpdatePassword(props);
	};

	return (
		<div className="lg:w-1/2">
			<FormProvider {...form}>
				<form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
					<div className="my-6">
						<h1 className="text-xl font-medium">
							{t("personal.changePassword")}
						</h1>
					</div>
					<PasswordField
						data-testid="signin-password"
						className="mb-4"
						name="currentPassword"
						label={t("personal.currentPassword")}
						required
						placeholder={t("personal.currentPassword")}
						autoComplete="current-password"
						ref={register}
					/>
					<PasswordField
						data-testid="signin-password"
						className="mb-4"
						name="newPassword"
						label={t("personal.newPassword")}
						required
						placeholder={t("personal.newPassword")}
						autoComplete="new-password"
						ref={register}
					/>
					<PasswordField
						data-testid="signin-password-confirm"
						className="mb-2"
						name="confirmNewPassword"
						label={t("personal.confirmPassword")}
						required
						placeholder={t("personal.confirmPassword")}
						autoComplete="confirm-new-password"
						ref={register}
					/>

					<PasswordStrengthMeter password={currentPassword} />

					<SubmitButton
						type="submit"
						loading={isLoading}
						className="mt-8 text-white flex justify-start my-5 text-center"
						disabled={false}
						label={t("update", { ns: "common" })}
					/>
				</form>
			</FormProvider>
		</div>
	);
};

export default UserPasswordForm;
