import { ColumnsType } from "antd/lib/table";
import classnames from "classnames";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import {
	OrganizationTypeDataType,
	OrganizationTypeParams,
	OrganizationTypeResponse,
} from "types/ApplicationManagement";
import MoreOptionsMenu from "components/common/MoreOptionsMenu";

import Breadcrumbs from "components/breadcrumbs";
import { BreadCrumbsProps } from "types/index";
import { ApplicationManagementContext } from "context/ApplicationManagementContext";
import { localeCompareForTable } from "utils";
import OrganizationTypeToolbar from "./OrganizationTypeToolbar";
import AddEditOrganizationType from "./AddEditOrganizationType";

const OrganizationType = ({
	organizationType,
	isLoading,
	handleSubmitAddEditOrganizationType,
}: {
	organizationType: OrganizationTypeResponse[];
	isLoading: boolean;
	handleSubmitAddEditOrganizationType: (
		props: OrganizationTypeParams
	) => Promise<boolean>;
}) => {
	const [tableData, setTableData] = useState<OrganizationTypeDataType[]>([]);
	const [showModal, setShowModal] = useState<boolean>(false);

	const { t } = useTranslation(["settings", "navigation", "application"]);
	const { handleSelectedOrganisationType, selectedOrganisationType } =
		useContext(ApplicationManagementContext);

	useEffect(() => {
		setTableData(dataSource);
	}, [organizationType]);

	const dataSource: OrganizationTypeDataType[] = organizationType.map(
		({ uuid, name, description, enabled }) => ({
			key: uuid,
			name,
			description,
			status: enabled,
		})
	);

	/* Show the search result in the table */
	const handleSearch = (searchTerm: string) => {
		const result = dataSource.filter(
			(data: OrganizationTypeDataType) =>
				data.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0 ||
				data.description.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
		);
		setTableData(result);
		if (!searchTerm) {
			setTableData(dataSource);
		}
	};

	const columns: ColumnsType<OrganizationTypeDataType> = [
		{
			dataIndex: "name",
			title: t("Organization Types", { ns: "application" }),
			key: "name",
			sorter: (a, b) => localeCompareForTable(a.name, b.name),
		},
		{
			dataIndex: "description",
			title: t("description", { ns: "common" }),
			key: "description",
			responsive: ["md"],
		},
		{
			dataIndex: "status",
			title: t("status", { ns: "common" }),
			key: "status",
			width: 130,
			render: (isEnabled: boolean) => (
				<p
					className={classnames(
						isEnabled ? "bg-primary-color" : "bg-gray-300",
						"rounded-full",
						"w-24",
						"py-2",
						"text-xs",
						"text-center",
						"text-white"
					)}
				>
					{t(isEnabled ? "enabled" : "disabled", { ns: "common" })}
				</p>
			),
		},
		{
			title: "Action",
			key: "operation",
			fixed: "right",
			align: "center",
			width: 100,
			render: (item) => (
				<MoreOptionsMenu size="sm" menuList={List} data={item} />
			),
		},
	];

	const List = [
		{
			label: t("edit", { ns: "common" }),
			onClick: (data: OrganizationTypeDataType) =>
				handleShowCreateEditModal(data),
		},
	];

	const routes: BreadCrumbsProps[] = [
		{
			path: "/dashboard",
			breadcrumbName: t("home", { ns: "navigation" }),
		},
		{
			path: "/app-settings/organization-type",
			breadcrumbName: t("Organization Types", { ns: "navigation" }),
		},
	];

	const handleShowCreateEditModal = (data?: OrganizationTypeDataType) => {
		if (data) {
			const orgType = organizationType.find(
				(organisationType) => organisationType.uuid === data.key
			);

			orgType && handleSelectedOrganisationType(orgType);
		} else {
			handleSelectedOrganisationType(null);
		}

		setShowModal(!showModal);
	};

	const handleSubmit = async (data: OrganizationTypeParams) => {
		const res = await handleSubmitAddEditOrganizationType(data);

		if (res) {
			handleShowCreateEditModal();
			handleSelectedOrganisationType(null);
		}
	};

	return (
		<>
			<Breadcrumbs breadCrumb={routes} />

			<OrganizationTypeToolbar
				handleShowCreateEditModal={handleShowCreateEditModal}
				handleSubmit={handleSearch}
			/>
			<Table
				dataSource={tableData}
				columns={columns}
				scroll={{ y: "52vh", x: 600 }}
				pagination={{ defaultPageSize: 10, showSizeChanger: true }}
			/>
			<AddEditOrganizationType
				showModal={showModal}
				selectedOrganisationType={selectedOrganisationType}
				onSubmitForm={handleSubmit}
				isLoading={isLoading}
				handleShowCreateEditModal={handleShowCreateEditModal}
			/>
		</>
	);
};

export default OrganizationType;
