import React, { useState } from "react";
import { UsersResponse } from "../types/UsersManagement";

const UserContext = React.createContext<{
	selectedUser: UsersResponse | null;
	handleSelectedUser: (data: UsersResponse | null) => void;
}>({
	selectedUser: null,
	handleSelectedUser: () => {},
});

const UserContextProvider = (props: {
	children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}) => {
	const [selectedUser, setSelectedUser] = useState<UsersResponse | null>(null);

	const handleSelectedUser = (data: UsersResponse | null) => {
		setSelectedUser(data);
	};

	const value = React.useMemo(
		() => ({
			selectedUser,
			handleSelectedUser,
		}),
		[selectedUser]
	);

	return (
		<UserContext.Provider value={value}>{props.children}</UserContext.Provider>
	);
};

export { UserContext, UserContextProvider };
