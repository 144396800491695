import { useTranslation } from "react-i18next";
import Search from "assets/svg/assets/Search";

import { SubmitButton } from "components/form/InputField";
import { useState } from "react";

const SearchInput = ({
	handleSubmit,
	placeholder,
}: {
	handleSubmit: (data: string) => void;
	placeholder: string;
}) => {
	const { t } = useTranslation();
	const [searchTerm, setSearchTerm] = useState("");

	return (
		<form
			className="w-full md:w-80"
			onSubmit={(e) => {
				e.preventDefault();
			}}
		>
			<div className="relative">
				<div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
					<Search className="w-5 h-5 text-gray-400 dark:text-gray-400" />
				</div>
				<input
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					type="search"
					id="default-search"
					className="block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-green-300 focus:border-green-400 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
					placeholder={placeholder}
				/>
				<SubmitButton
					className="rounded-[5px] text-white absolute right-2.5 bottom-2.5 font-medium text-sm"
					type="submit"
					label={t("search.label")}
					onClick={() => {
						handleSubmit(searchTerm);
					}}
				/>
			</div>
		</form>
	);
};

export default SearchInput;
