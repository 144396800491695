/* Temporary static data for support pagaes */
export const contactOptions = [
	{
		key: "1",
		name: "Michael Smith",
		email: "msmith@gamil.com",
	},
	{
		key: "2",
		name: "Jordan Green",
		email: "j.green@outlook.com",
	},
	{
		key: "3",
		name: "Jo Chen",
		email: "chenchen@gmail.com",
	},
	{
		key: "4",
		name: "Tom Gladstone",
		email: "tg-stone@gmail.com",
	},
	{
		key: "5",
		name: "Jesse Drake",
		email: "jessedrake@hotmail.com",
	},
];

export const issueTypeOptions = [
	{ key: "1", name: "question" },
	{ key: "2", name: "incident" },
	{ key: "3", name: "problem" },
	{ key: "4", name: "featureReq" },
	{ key: "5", name: "refund" },
];

export const sourceOptions = [
	{ key: "1", name: "phone" },
	{ key: "2", name: "email" },
	{ key: "3", name: "chat" },
];

export const statusOptions = [
	{ key: "1", name: "open" },
	{ key: "2", name: "pending" },
	{ key: "3", name: "resolved" },
	{ key: "4", name: "closed" },
	{ key: "5", name: "waitingCustomer" },
	{ key: "6", name: "waiting3rdParty" },
];

export const priorityOptions = [
	{ key: "1", name: "low" },
	{ key: "2", name: "medium" },
	{ key: "3", name: "high" },
	{ key: "4", name: "urgent" },
];

export const departmentOptions = [
	{ key: "1", name: "Billing" },
	{ key: "2", name: "Customer Support" },
	{ key: "3", name: "Escalations" },
];

export const defaultAgentOptions = [
	{
		key: "1",
		name: "Michael Smith",
		department: "Billing",
	},
	{
		key: "2",
		name: "Jordan Green",
		department: "Customer Support",
	},
	{
		key: "3",
		name: "Jo Chen",
		department: "Billing",
	},
	{
		key: "4",
		name: "Tom Gladstone",
		department: "Customer Support",
	},
	{
		key: "5",
		name: "Jesse Drake",
		department: "Escalations",
	},
	{
		key: "6",
		name: "Paul Walker",
		department: "Escalations",
	},
];

export const companyOptions = [
	{ key: "1", name: "Google" },
	{ key: "2", name: "Apple" },
	{ key: "3", name: "Microsoft" },
	{ key: "4", name: "Meta" },
	{ key: "5", name: "Amazon" },
];

export const issues = [
	{
		key: "1",
		subject: "Email Address Change",
		source: "email",
		priority: "medium",
		status: "open",
		overdue: false,
		agent: "Emily Garcia", // this should be uuid which can refer to each user
		department: "Customer Support",
		createdBy: "Michael Smith",
		createdAt: "2022-10-12 12:00:00",
		respondedAt: "2022-10-12 17:00:00",
		description:
			"Hi,<br><br>I need to update my email address. I want to know if I'll lose access to my account if I change it. I've added quite a few products to my cart and don't want to look for them again.<br><br> It would be great to continue using this account with my new email address. Please let me know if that's possible.<br><br>Regards,<br>Emily Garcia",
	},
	{
		key: "2",
		subject: "Received a broken product",
		source: "phone",
		priority: "high",
		status: "open",
		overdue: false,
		agent: "Sarah James",
		department: "Escalations",
		createdBy: "Michael Smith",
		createdAt: "2022-10-11 13:00:00",
		respondedAt: "2022-10-12 11:00:00",
		description:
			"Hi,<br><br>The television I ordered from your site was delivered with a cracked screen. I need some help with a refund or a replacement.<br><br>Here is the order number <b>FD07062010</b><br><br>Thanks,<br>Sarah",
	},
	{
		key: "3",
		subject: "Payment failed",
		source: "email",
		priority: "urgent",
		status: "open",
		overdue: true,
		agent: "Matt Rogers",
		department: "Billing",
		createdBy: "Michael Smith",
		createdAt: "2022-10-10 10:00:00",
		respondedAt: "2022-10-11 12:00:00",
		description:
			'I was trying to make a payment on your site and got a "Your payment failed" error. However, my card was charged.<br>Can you let me know if the order is processed or what I need to do?<br>Please see the attached screenshot.<br><br>Thanks,<br>Matt',
	},
	{
		key: "4",
		subject: "Physical Address Change",
		source: "chat",
		priority: "low",
		status: "closed",
		overdue: false,
		agent: "Samantha James",
		department: "Customer Support",
		createdBy: "Michael Smith",
		createdAt: "2022-10-12 9:00:00",
		respondedAt: "2022-10-12 18:00:00",
		description:
			"Hi,<br><br>I need to update my physical address. I want to know if I'll lose access to my account if I change it. I've added quite a few products to my cart and don't want to look for them again.<br><br> It would be great to continue using this account with my new physical address. Please let me know if that's possible.<br><br>Regards,<br>Samantha James",
	},
];
