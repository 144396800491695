import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import classnames from "classnames";
import PopoverMenu from "components/common/PopoverMenu";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoSquare } from "react-icons/io5";
import { MdKeyboardArrowDown } from "react-icons/md";
import { issueDataType } from "types/Support";
import { localeCompareForTable } from "utils";
import IssuePopOverMenu from "../IssuePopOverMenu";
import OverdueIndicator from "../OverdueIndicator";

const SupportTableLayout = ({
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	issues,
	statusOptions,
	priorityOptions,
}: {
	issues: any[];
	statusOptions: any[];
	priorityOptions: any[];
}) => {
	const { t } = useTranslation(["support"]);

	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};

	const columns: ColumnsType<issueDataType> = [
		{
			title: "Agent",
			dataIndex: "agent",
			key: "agent",
			sorter: (a, b) => localeCompareForTable(a.agent, b.agent),
		},
		{
			title: "Overdue",
			dataIndex: "overdue",
			key: "overdue",
			// compare boolean values turning the into 0 or 1
			sorter: (a, b) => Number(a.overdue) - Number(b.overdue),
			render: (overdue: boolean) => (
				<div>{overdue && <OverdueIndicator />}</div>
			),
		},
		{
			title: "Subject",
			dataIndex: "subject",
			key: "subject",
			sorter: (a, b) => localeCompareForTable(a.subject, b.subject),
			render: (item, { key }) => (
				<a href={`/support/ticket/${key}`} className="text-primary-color">
					{item}
				</a>
			),
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			sorter: (a, b) => localeCompareForTable(a.status, b.status),
			render: (item) => (
				<PopoverMenu
					buttonClass="flex items-center justify-between"
					placement="bottomLeft"
					showArrow={false}
					content={
						<IssuePopOverMenu
							menuItems={statusOptions}
							selected={item}
							selectMenuItem={() => {}}
						/>
					}
					icon={<MdKeyboardArrowDown className="ml-2" />}
					label={t(item)}
					noDecoration
				/>
			),
		},
		{
			title: "Priority",
			dataIndex: "priority",
			key: "priority",
			sorter: (a, b) => localeCompareForTable(a.priority, b.priority),
			render: (item) => (
				<div className="flex items-center">
					<IoSquare
						className={classnames(
							{ "text-blue-400": item === "low" },
							{ "text-green-400": item === "medium" },
							{ "text-yellow-400": item === "high" },
							{ "text-red-500": item === "urgent" },
							"mr-2 text-[0.60rem]"
						)}
					/>
					<PopoverMenu
						buttonClass={classnames(
							item === "urgent" ? "font-bold" : "",
							"flex items-center justify-between"
						)}
						placement="bottomLeft"
						showArrow={false}
						content={
							<IssuePopOverMenu
								menuItems={priorityOptions}
								selected={item}
								selectMenuItem={() => {}}
							/>
						}
						icon={<MdKeyboardArrowDown className="ml-2" />}
						label={t(item)}
						noDecoration
					/>
				</div>
			),
		},
		{
			title: "Department",
			dataIndex: "department",
			key: "department",
			sorter: (a, b) => localeCompareForTable(a.department, b.department),
		},
	];

	return (
		<div className="mt-6">
			<Table
				rowSelection={rowSelection}
				pagination={false}
				columns={columns}
				dataSource={issues}
			/>
		</div>
	);
};

export default SupportTableLayout;
