import React, { useMemo, useState } from "react";
import { EVCardParams } from "types/EVCards";

const EVCardContext = React.createContext<{
	selectedCard: EVCardParams | null;
	handleSelectedCard: (data: EVCardParams | null) => void;
}>({
	selectedCard: null,
	handleSelectedCard: () => {},
});

const EVCardContextProvider = (props: {
	children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}) => {
	const [selectedCard, setSelectedCard] = useState<EVCardParams | null>(null);

	const handleSelectedCard = (data: EVCardParams | null) => {
		setSelectedCard(data);
	};

	const value = useMemo(
		() => ({
			selectedCard,
			handleSelectedCard,
		}),
		[selectedCard]
	);

	return (
		<EVCardContext.Provider value={value}>
			{props.children}
		</EVCardContext.Provider>
	);
};

export { EVCardContext, EVCardContextProvider };
