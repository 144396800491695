import { FaLocationArrow, FaPhone } from "react-icons/fa";
import { GoIssueOpened } from "react-icons/go";
import { InfoWindow } from "@react-google-maps/api";
import { ChargingStationResponse, SiteResponse } from "types/Stations";
import { getFulladdress } from "utils";
import { useTranslation } from "react-i18next";
import Loading from "../../assets/svg/assets/Loading";

const InfoCard = ({
	handleShowMore,
	selectedSite,
	onCloseClick,
	isLoading,
	chargers,
}: {
	handleShowMore: () => void;
	onCloseClick: () => void;
	chargers: ChargingStationResponse[];
	selectedSite: SiteResponse;
	isLoading: boolean;
}) => {
	const { name, address, locationLattitude, locationLongitude } = selectedSite;
	const { t } = useTranslation(["sites"]);

	const numberOfTotalConnectors = chargers
		.map((data) => data.connectors.length)
		.reduce((partialSum, a) => partialSum + a, 0);

	return (
		<InfoWindow
			position={{
				lat: Number(selectedSite.locationLattitude),
				lng: Number(selectedSite.locationLongitude),
			}}
			onCloseClick={onCloseClick}
		>
			{isLoading ? (
				<div className="w-64 flex justify-center items-center">
					<Loading />
				</div>
			) : (
				<div className="w-64">
					<div className="text-lg font-semibold">{name}</div>
					<div>
						<p className="text-base">{getFulladdress(address)}</p>
						<a
							className="text-base flex items-center text-primary-color"
							target="_blank"
							href={`http://maps.google.com/maps?daddr=${locationLattitude}+${locationLongitude}`}
							rel="noreferrer"
						>
							<FaLocationArrow className="mr-2 text-primary-color" />{" "}
							{t("directions")}
						</a>

						<a
							className="text-base flex items-center text-primary-color"
							href="/"
						>
							<FaPhone className="mr-2 text-primary-color" />
						</a>
						<p className="text-base mb-0 mt-4">
							{t("numOfChargers")}: {chargers.length}
						</p>
						<p className="text-base mb-0">
							{t("numOfCOnnectors")}: {numberOfTotalConnectors}
						</p>
						<p className="text-base">{t("numOfChargersAvailable")}: 2</p>
					</div>
					<div className="flex justify-between">
						<a
							className="text-base flex items-center text-primary-color"
							href="/"
						>
							<GoIssueOpened className="mr-2 text-primary-color" size={18} />{" "}
							{t("reportIssue")}
						</a>
						<div
							tabIndex={0}
							onKeyDown={handleShowMore}
							role="button"
							className="text-base mb-0 text-primary-color"
							onClick={handleShowMore}
						>
							{t("more")}
						</div>
					</div>
				</div>
			)}
		</InfoWindow>
	);
};
export default InfoCard;
