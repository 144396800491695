import { fetchLoggedInUser } from "api/auth";
import classNames from "classnames";
import Alerts from "components/common/Alerts";
import DashboardHeader from "components/navbars/DashboardHeader";
import SideNav from "components/navbars/SideNav";
import { AlertContext } from "context/AlertContext";
import { AuthContext } from "context/AuthContext";
import { memo, ReactNode, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import Loading from "../assets/svg/assets/Loading";
import { Footer } from "./AuthLayout";

const DashboardLayout = ({ children }: { children: ReactNode }) => {
	const [toggle, setToggle] = useState<boolean>(false);
	const { message, severity, showAlert } = useContext(AlertContext);
	const { handleUser, user, handleLoading } = useContext(AuthContext);
	const { t } = useTranslation(["constants"]);
	const { data, isSuccess, isLoading } = useQuery(
		"loggedInUsers",
		fetchLoggedInUser
	);

	useEffect(() => {
		if (user) return;

		if (isSuccess) {
			handleUser(data);
		}
	}, [data]);

	useEffect(() => {
		handleLoading(isLoading);
	}, [isLoading]);

	const handleToggle = () => setToggle(!toggle);

	if (data && data.status === 500)
		return (
			<h1>
				Opps something went wrong please try again, if the problem continues
				please contact customer care
			</h1>
		);

	if (data && data.message === t("Network Error", { lng: "en" }))
		return (
			<h1>
				We are not able to connect to the server at this moment please check
				your network and try again
			</h1>
		);

	if (isLoading) {
		return (
			<div className="h-[100vh] flex justify-center items-center">
				<Loading />
			</div>
		);
	}
	return (
		<div className="">
			<DashboardHeader handleToggle={handleToggle} showMenu={toggle} />
			<div
				className={classNames(
					"flex h-[90vh] md:h-[93vh] relative overflow-hidden",
					toggle && "overflow-scroll"
				)}
			>
				<SideNav showMenu={toggle} />
				<div className="border-solid border-t-[1px] w-full border-t-gray-200 bg-gray-100 z-5 overflow-hidden overflow-y-scroll">
					<div className="md:p-10 p-5 md:pb-0 flex flex-col justify-between h-full z-5 ">
						<div className="">{children}</div>
						<Footer />
					</div>
				</div>
			</div>
			<Alerts message={message} severity={severity} open={showAlert} />
		</div>
	);
};
export default memo(DashboardLayout);
