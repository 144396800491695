import { UserParams } from "types/UsersManagement";
import apiRequestHandler from "utils/apiRequestHandler";

export const getAllUsers = (page = 1, size = 10) => {
	const res = apiRequestHandler({
		url: `/users?page=${page}&size=${size}`,
		data: {},
		method: "get",
	});
	return res;
};

export const updateBackOfficeUser = ({ uuid, ...rest }: UserParams): any => {
	const res = apiRequestHandler({
		url: `/users/${uuid}`,
		data: rest,
		method: "put",
	});
	return res;
};

export const createBackOfficeUser = (params: UserParams): any => {
	const res = apiRequestHandler({
		url: `/users`,
		data: params,
		method: "post",
	});
	return res;
};

export const getUser = (userUuid: string) => {
	const res = apiRequestHandler({
		url: `/users/${userUuid}`,
		data: {},
		method: "get",
	});
	return res;
};

export const getUserVehicles = (userUuid: string) => {
	const res = apiRequestHandler({
		url: `/vehicles/user/${userUuid}`,
		data: {},
		method: "get",
	});
	return res;
};

export const getUserEVCards = (userUuid: string) => {
	const res = apiRequestHandler({
		url: `/ev-cards/user/${userUuid}`,
		data: {},
		method: "get",
	});
	return res;
};
