const Search = ({ size = 19, color = "none", ...other }) => (
	<svg
		width={size}
		height={size}
		viewBox={`0 0 ${size} ${size}`}
		fill={color}
		stroke="currentColor"
		xmlns="http://www.w3.org/2000/svg"
		{...other}
	>
		<path
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
		/>
	</svg>
);

export default Search;
