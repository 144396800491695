import classNames from "classnames";
import { IoMdMore } from "react-icons/io";
import PopoverMenu from "./PopoverMenu";

const MoreOptionsMenu = ({
	menuList,
	data,
	size = "sm",
	label,
	buttonClassName,
}: {
	menuList: { onClick: (item?: any) => void; label: string }[];
	data?: any;
	buttonClassName?: string;
	size?: "sm" | "md" | "lg";
	label?: string | React.ReactNode;
}): JSX.Element => {
	const sizes = {
		sm: "w-20",
		md: "w-44",
		lg: "w-60",
	};
	const content = (
		<div className={classNames(sizes[size])}>
			{menuList.map(({ label: title, onClick }, index) => (
				<button
					key={index}
					onClick={() => onClick(data)}
					className="p-2 text-left cursor-pointer w-full hover:text-white hover:bg-primary-color"
				>
					<span className="p-2 text-left">{title}</span>
				</button>
			))}
		</div>
	);

	return (
		<PopoverMenu
			buttonClass={classNames(
				"text-primary-color border-primary-color hover:bg-primary-color hover:text-white focus:ring-green-300 rounded-lg",
				buttonClassName
			)}
			content={content}
		>
			{label || <IoMdMore />}
		</PopoverMenu>
	);
};

export default MoreOptionsMenu;
