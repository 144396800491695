import Loading from "../../assets/svg/assets/Loading";

const emptyFn = () => {};

const Button = (props: {
	disabled?: boolean;
	isFetching?: boolean;
	loading?: boolean | false;
	handleClick?: () => void;
	className?: string;
	type: "button" | "submit" | "reset";
	id?: string;
	label: string;
	icon?: React.ReactNode;
}) => {
	const {
		disabled,
		isFetching,
		handleClick = emptyFn,
		label,
		className,
		loading = false,
		icon,
		...otherProps
	} = props;

	const _onClick = () => {
		try {
			handleClick();
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error);
		}
	};

	return (
		<button
			className={`flex items-center ${className}`}
			disabled={isFetching || disabled}
			onClick={_onClick}
			{...otherProps}
		>
			{icon}
			{loading && <Loading />}
			{label}
		</button>
	);
};

export default Button;
