import TicketCardItem from "../ticketCardItem";

const SupportCardLayout = ({
	issues,
	statusOptions,
	priorityOptions,
}: {
	issues: any[];
	statusOptions: any[];
	priorityOptions: any[];
}) => (
	<div className="flex flex-col w-full">
		{issues.map((issue) => (
			<TicketCardItem
				key={issue.key}
				issue={issue}
				statusOptions={statusOptions}
				priorityOptions={priorityOptions}
			/>
		))}
	</div>
);

export default SupportCardLayout;
