interface IInitialsCirclesProps {
	firstName?: string;
	lastName?: string;
}

const XLarge = ({ firstName = "", lastName = "" }: IInitialsCirclesProps) => {
	const initials = `${firstName[0]}${lastName[0]}`;

	return (
		<div className="bg-primary-color rounded-full flex justify-center items-center h-48 w-48">
			<small className="text-white text-5xl">{initials}</small>
		</div>
	);
};

const Large = ({ firstName = "", lastName = "" }: IInitialsCirclesProps) => {
	const initials = `${firstName[0]}${lastName[0]}`;

	return (
		<div className="bg-primary-color rounded-full flex justify-center items-center h-16 w-16">
			<small className="text-white text-2xl">{initials}</small>
		</div>
	);
};

const Regular = ({ firstName = "", lastName = "" }: IInitialsCirclesProps) => {
	const initials = `${firstName[0]}${lastName[0]}`;

	return (
		<div className="bg-primary-color rounded-full flex justify-center items-center h-8 w-8">
			<small className="text-white">{initials}</small>
		</div>
	);
};

const Small = ({ firstName = "", lastName = "" }: IInitialsCirclesProps) => {
	const initials = `${firstName[0]}${lastName[0]}`;

	return (
		<div className="bg-primary-color rounded-full flex justify-center items-center h-6 w-6">
			<span className="text-xs text-white">{initials}</span>
		</div>
	);
};

const InitialsCircles = {
	Regular,
	Small,
	Large,
	XLarge,
};

export default InitialsCircles;
