import { useMutation } from "react-query";
import AuthLayout from "Layouts/AuthLayout";
import { Signup } from "api/auth";
import PasswordStrengthMeter from "components/auth/PasswordStrengthMeter";
import {
	Checkbox,
	PasswordField,
	PhoneField,
	SubmitButton,
	TextField,
} from "components/form/InputField";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { RegisterResponse, SignupFormData, SignupParams } from "types/Auth";
import { ErrorResponse } from "types";

const Form = () => {
	const { t } = useTranslation(["accounts"]);
	const [error, setError] = useState<string>("");

	const { mutate, isLoading } = useMutation<
		RegisterResponse,
		ErrorResponse,
		SignupParams
	>(Signup);

	const form = useForm<SignupFormData>({
		defaultValues: {
			email: "",
			firstName: "",
			lastName: "",
			phoneNumber: "",
			password: "",
		},
	});

	const { handleSubmit, watch, register } = form;
	const currentPassword = watch("password");

	const onSubmit = async (formValue: SignupFormData) => {
		setError("");

		const params: SignupParams = {
			...formValue,
			callingCode: "+1",
			roleNames: [],
			enabled: true,
			userType: "NON_BACK_OFFICE",
		};

		mutate(params, {
			onSuccess: (res) => {
				if (res.message !== t("accountCreated")) {
					setError(res.message!);
					return;
				}

				goToVerification();
			},
		});
	};

	const goToVerification = () => (window.location.href = "/verification");

	return (
		<div>
			<h1 className="mb-3 text-3xl font-medium">{t("createAccount")}</h1>
			<div className="flex">
				<p>{t("accountExists")}</p>
				<a href="/login">
					<p className="ml-1 text-primary-color">{t("signin")}</p>
				</a>
			</div>
			<FormProvider {...form}>
				<form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
					<div className="mb-2">
						<TextField
							data-testid="first-name"
							className="mb-2"
							name="firstName"
							label={t("firstName")}
							placeholder={t("firstName")}
							required
							ref={register({ required: true, maxLength: 20 })}
							autoComplete="firstName"
						/>
					</div>
					<div className="mb-2">
						<TextField
							data-testid="last-name"
							className="mb-2"
							name="lastName"
							label={t("lastName")}
							placeholder={t("lastName")}
							required
							ref={register({ required: true, maxLength: 20 })}
							autoComplete="lastName"
						/>
					</div>
					<div className="mb-2">
						<PhoneField
							data-testid="phoneNumber"
							name="phoneNumber"
							label={t("phoneNumber")}
							placeholder={t("phoneNumber")}
							required
							autoComplete="tel-national"
							rules={{ required: true }}
						/>
					</div>
					<div className="mb-2 mt-2">
						<TextField
							data-testid="signin-email"
							className="mb-2"
							name="email"
							label={t("emailAddress")}
							placeholder="example@example.com"
							required
							autoComplete="username"
							ref={register({ required: true })}
						/>
					</div>
					<PasswordField
						data-testid="signin-password"
						className="mb-2"
						name="password"
						label={t("password")}
						required
						placeholder={t("password")}
						autoComplete="current-password"
						ref={register({
							required: true,
							min: {
								value: 8,
								message: t("passwordLengthError"),
							},
							pattern: {
								value: /^(?=.*\d).{8,}$/g,
								message: t("passwordStrengthError"),
							},
						})}
					/>

					<PasswordStrengthMeter password={currentPassword} />

					{error && (
						<div>
							<span className="text-red-500">{error}</span>
						</div>
					)}
					<div>
						<Checkbox
							data-testid="agree-policy"
							name="agreePolicy"
							label={t("agreePolicyMsg")}
							required
						/>
					</div>
					<SubmitButton
						type="submit"
						className="mt-8 text-white flex m-auto my-5 text-center"
						disabled={isLoading}
						data-testid="signup"
						label="Register"
						loading={isLoading}
					/>
				</form>
			</FormProvider>
		</div>
	);
};

const Registration = () => (
	<AuthLayout>
		<Form />
	</AuthLayout>
);

export default Registration;
