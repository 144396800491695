import { ChangePassword } from "api/auth";
import PersonalSettings from "components/Settings/personalSettings";
import { AlertContext } from "context/AlertContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { ErrorResponse } from "types";
import { ChangePasswordParams, ChangePasswordResponse } from "types/Auth";

const Personal = () => {
	const { handleShowAlert } = useContext(AlertContext);
	const { t } = useTranslation(["constants"]);

	const { mutateAsync, isLoading } = useMutation<
		ChangePasswordResponse,
		ErrorResponse,
		ChangePasswordParams
	>(ChangePassword);

	const handleUpdatePassword = async (
		props: ChangePasswordParams
	): Promise<boolean> => {
		const { message } = await mutateAsync(props);

		if (!message) return false;

		if (message !== t("updatePasswordError", { lng: "en" })) {
			handleShowAlert({ message, severity: "success" });
			return true;
		}

		handleShowAlert({ message, severity: "error" });

		return false;
	};

	return (
		<PersonalSettings
			isLoading={isLoading}
			handleUpdatePassword={handleUpdatePassword}
		/>
	);
};

export default Personal;
