import { ColumnsType } from "antd/lib/table";
import AlertModal from "components/modals/AlertModal";
import classnames from "classnames";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import {
	RoleDataType,
	RolesParams,
	RolesResponse,
} from "types/ApplicationManagement";
import MoreOptionsMenu from "components/common/MoreOptionsMenu";
import { ApplicationManagementContext } from "context/ApplicationManagementContext";
import Breadcrumbs from "components/breadcrumbs";
import { BreadCrumbsProps } from "types/index";
import { convertUTCToLocalTimeString, localeCompareForTable } from "utils";
import RolesToolbar from "./RolesToolbar";
import AddRoles from "./AddRoles";
import { roleTypes } from "../../../constants/index";

const RoleSettings = ({
	roles,
	isLoading,
	handleSubmitAddEditRoles,
}: {
	roles: RolesResponse[];
	isLoading: boolean;
	handleSubmitAddEditRoles: (props: RolesParams) => Promise<boolean>;
}) => {
	const [tableData, setTableData] = useState<RoleDataType[]>([]);
	const [showModal, setShowModal] = useState(false);
	const [showAlert, setShowAlert] = useState(false);

	const [filterByRoleType, setFilterByRoleType] = useState<string[]>(
		roleTypes.map((role: { name: string; value: string }) => role.value)
	);

	const { t, i18n } = useTranslation(["settings", "common"]);
	const { handleSelectedRole, selectedRole } = useContext(
		ApplicationManagementContext
	);

	useEffect(() => {
		setTableData(dataSource);
	}, [roles]);

	const dataSource: RoleDataType[] = roles.map(
		({ uuid, name, description, roleType, enabled, updatedAt }) => ({
			key: uuid,
			name,
			description,
			type: roleType,
			lastModified: convertUTCToLocalTimeString(
				updatedAt,
				"medium",
				"short",
				i18n.language
			),
			status: enabled,
		})
	);

	/* Show the search result in the table */
	const handleSearch = (searchTerm: string) => {
		const result = dataSource.filter(
			(data: RoleDataType) =>
				data.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0 ||
				data.description.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0 ||
				data.type.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
		);
		setTableData(result);
		if (!searchTerm) {
			setTableData(dataSource);
		}
	};

	const handleFilter = (selectedRoleType: string[]) => {
		const result = dataSource.filter((data: RoleDataType) =>
			selectedRoleType.includes(data.type)
		);
		setTableData(result);
	};

	useEffect(() => handleFilter(filterByRoleType), [filterByRoleType]);

	const columns: ColumnsType<RoleDataType> = [
		{
			dataIndex: "name",
			title: t("roles.role"),
			key: "name",
			sorter: (a, b) => localeCompareForTable(a.name, b.name),
		},
		{
			dataIndex: "description",
			title: t("description", { ns: "common" }),
			key: "description",
			responsive: ["md"],
		},
		{
			dataIndex: "type",
			title: t("type", { ns: "common" }),
			key: "type",
			sorter: (a, b) => localeCompareForTable(a.type, b.type),
		},
		{
			dataIndex: "lastModified",
			title: t("lastUpdated", { ns: "common" }),
			key: "last_updated",
			responsive: ["md"],
			sorter: (a, b) => localeCompareForTable(a.lastModified, b.lastModified),
		},
		{
			dataIndex: "status",
			title: t("status", { ns: "common" }),
			key: "status",
			width: 130,
			render: (isEnabled: boolean) => (
				<p
					className={classnames(
						isEnabled ? "bg-primary-color" : "bg-gray-300",
						"rounded-full",
						"w-24",
						"py-2",
						"text-xs",
						"text-center",
						"text-white"
					)}
				>
					{t(isEnabled ? "enabled" : "disabled", { ns: "common" })}
				</p>
			),
		},
		{
			title: "Action",
			key: "operation",
			fixed: "right",
			align: "center",
			width: 100,
			render: (item) => (
				<MoreOptionsMenu size="md" menuList={List} data={item} />
			),
		},
	];

	const List = [
		{
			label: t("edit", { ns: "common" }),
			onClick: (data: RoleDataType) => handleShowRoleModal(data),
		},
		{
			label: t("permissions.changePermissions"),
			onClick: (data: RoleDataType) => handleAssignPermissions(data),
		},
	];

	const routes: BreadCrumbsProps[] = [
		{
			path: "/dashboard",
			breadcrumbName: t("home", { ns: "navigation" }),
		},
		{
			path: "/app-settings/roles",
			breadcrumbName: t("roles", { ns: "navigation" }),
		},
	];

	const handleAssignPermissions = (data: RoleDataType) => {
		const roleRes = roles.find((role) => role.uuid === data.key);
		roleRes && handleSelectedRole(roleRes);

		if (roleRes) {
			window.location.href = `/app-settings/roles/${roleRes.uuid}`;
		}
	};

	const handleShowRoleModal = (data?: RoleDataType) => {
		if (data) {
			const roleRes = roles.find((role) => role.uuid === data.key);
			roleRes && handleSelectedRole(roleRes);
		} else {
			handleSelectedRole(null);
		}
		setShowModal(!showModal);
	};

	const handleShowAlertModal = () => {
		setShowAlert(!showAlert);
	};

	const handleSubmit = async (data: RolesParams) => {
		const res = await handleSubmitAddEditRoles(data);

		if (res) {
			handleShowRoleModal();
			handleSelectedRole(null);
		}
	};

	return (
		<>
			<Breadcrumbs breadCrumb={routes} />

			<RolesToolbar
				handleShowRoleModal={handleShowRoleModal}
				handleSubmit={handleSearch}
				setFilterByRoleType={setFilterByRoleType}
				filterByRoleType={filterByRoleType}
				roleTypes={roleTypes}
			/>
			<Table
				dataSource={tableData}
				columns={columns}
				scroll={{ y: "52vh", x: 600 }}
				pagination={{ defaultPageSize: 10, showSizeChanger: true }}
			/>
			<AddRoles
				showModal={showModal}
				selectedRole={selectedRole}
				onSubmitForm={handleSubmit}
				isLoading={isLoading}
				handleShowRoleModal={handleShowRoleModal}
			/>
			<AlertModal
				message="Are you sure you want to delete this product?"
				show={showAlert}
				type="warning"
				deny={handleShowAlertModal}
				onClose={handleShowAlertModal}
				confirm={handleShowAlertModal}
			/>
		</>
	);
};

export default RoleSettings;
