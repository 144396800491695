import AlertModal from "components/modals/AlertModal";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import {
	PermissionsDataType,
	PermissionsParams,
	PermissionsResponse,
} from "types/ApplicationManagement";
import MoreOptionsMenu from "components/common/MoreOptionsMenu";
import { ApplicationManagementContext } from "context/ApplicationManagementContext";
import classnames from "classnames";
import { BreadCrumbsProps } from "types/index";
import Breadcrumbs from "components/breadcrumbs";
import { localeCompareForTable } from "utils";
import PermissionToolbar from "./PermissionToolbar";
import AddPermissions from "./AddPermissions";

const PermissionsList = ({
	permissions,
	isLoading,
	handleSubmitAddEditPermissions,
}: {
	permissions: PermissionsResponse[];
	isLoading: boolean;
	handleSubmitAddEditPermissions: (
		props: PermissionsParams
	) => Promise<boolean>;
}) => {
	const [tableData, setTableData] = useState<PermissionsDataType[]>([]);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [showAlert, setShowAlert] = useState<boolean>(false);

	const { handleSelectedPermission, selectedPermission } = useContext(
		ApplicationManagementContext
	);

	const { t } = useTranslation(["settings", "common", "navigation"]);

	useEffect(() => {
		setTableData(dataSource);
	}, [permissions]);

	const handleShowPermissionModal = (data?: PermissionsDataType) => {
		if (data) {
			const permRes = permissions.find((perm) => perm.uuid === data.key);
			permRes && handleSelectedPermission(permRes);
		} else {
			handleSelectedPermission(null);
		}
		setShowModal(!showModal);
	};

	const handleShowAlertModal = () => {
		setShowAlert(!showAlert);
	};

	const handleSubmit = async (data: PermissionsParams) => {
		const res = await handleSubmitAddEditPermissions(data);

		if (res) {
			handleShowPermissionModal();
			handleSelectedPermission(null);
		}
	};

	const dataSource: PermissionsDataType[] = permissions.map(
		({ uuid, name, description, enabled, updatedAt }) => ({
			key: uuid,
			name,
			description,
			lastModified: updatedAt,
			enabled,
		})
	);

	/* Show the search result in the table */
	const handleSearch = (searchTerm: string) => {
		const result = dataSource.filter(
			(data: PermissionsDataType) =>
				data.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0 ||
				data.description.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
		);
		setTableData(result);
		if (!searchTerm) {
			setTableData(dataSource);
		}
	};

	const columns: ColumnsType<PermissionsDataType> = [
		{
			title: t("name", { ns: "common" }),
			dataIndex: "name",
			key: "name",
			sorter: (a, b) => localeCompareForTable(a.name, b.name),
		},
		{
			title: t("description", { ns: "common" }),
			dataIndex: "description",
			key: "description",
			responsive: ["md"],
		},
		{
			dataIndex: "enabled",
			title: "Status",
			key: "enabled",
			width: 150,
			render: (isEnabled: boolean) => (
				<p
					className={classnames(
						isEnabled ? "bg-primary-color" : "bg-gray-300",
						"rounded-full w-24 py-2 text-xs text-center text-white"
					)}
				>
					{isEnabled
						? t("enabled", { ns: "common" })
						: t("disabled", { ns: "common" })}
				</p>
			),
		},
		{
			title: t("action", { ns: "common" }),
			key: "operation",
			fixed: "right",
			align: "center",
			width: 100,
			render: (item) => <MoreOptionsMenu menuList={List} data={item} />,
		},
	];

	const List = [
		{
			label: t("edit", { ns: "common" }),
			onClick: (data: PermissionsDataType) => handleShowPermissionModal(data),
		},
	];

	const routes: BreadCrumbsProps[] = [
		{
			path: "/dashboard",
			breadcrumbName: t("home", { ns: "navigation" }),
		},
		{
			path: "/app-settings/permissions",
			breadcrumbName: t("menu.permissions", { ns: "settings" }),
		},
	];

	return (
		<>
			<Breadcrumbs breadCrumb={routes} />

			<PermissionToolbar
				handleShowPermissionModal={handleShowPermissionModal}
				handleSubmit={handleSearch}
			/>
			<Table
				dataSource={tableData}
				columns={columns}
				scroll={{ y: "60vh", x: 600 }}
				pagination={{ defaultPageSize: 10, showSizeChanger: true }}
			/>
			<AddPermissions
				showModal={showModal}
				selectedPermission={selectedPermission}
				onSubmitForm={handleSubmit}
				isLoading={isLoading}
				handleShowPermissionModal={handleShowPermissionModal}
			/>
			<AlertModal
				message={t("alerts.messages.confirmDeletePermission", { ns: "common" })}
				show={showAlert}
				type="warning"
				deny={handleShowAlertModal}
				onClose={handleShowAlertModal}
				confirm={handleShowAlertModal}
				confirmText={t("alerts.confirm", { ns: "common" })}
				denyText={t("alerts.deny", { ns: "common" })}
			/>
		</>
	);
};

export default PermissionsList;
