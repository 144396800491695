import { SubmitButton, TextField } from "components/form/InputField";
import { Switch } from "@headlessui/react";
import Modal from "components/modals/Modal";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
	PermissionsParams,
	PermissionsResponse,
} from "types/ApplicationManagement";

const AddPermissions = ({
	handleShowPermissionModal,
	showModal,
	selectedPermission,
	isLoading,
	onSubmitForm,
}: {
	handleShowPermissionModal: () => void;
	showModal: boolean;
	selectedPermission?: PermissionsResponse | null;
	isLoading: boolean;
	onSubmitForm: (data: PermissionsParams) => Promise<void>;
}) => {
	const { t } = useTranslation(["settings", "common"]);
	const { name, description, enabled } = selectedPermission || {};
	const [isEnabled, setIsEnabled] = useState<boolean | undefined>(enabled);

	const form = useForm<PermissionsParams>({
		defaultValues: {
			name: name || "",
			description: description || "",
			enabled: enabled || false,
		},
	});

	const { handleSubmit, register, reset } = form;

	useEffect(() => {
		setIsEnabled(enabled);
	}, [enabled]);

	useEffect(() => {
		reset({
			name: name || "",
			enabled: enabled || false,
			description: description || "",
		});
	}, [selectedPermission]);

	const onSubmit = async (props: any) => {
		const params = {
			...props,
			enabled: isEnabled,
		};

		onSubmitForm(params);
	};
	return (
		<Modal
			title={
				!selectedPermission
					? t("permissions.addPermission")
					: t("permissions.updatePermission")
			}
			show={showModal}
			onClose={() => handleShowPermissionModal()}
		>
			<FormProvider {...form}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="mb-2">
						<TextField
							data-testid="permission-input"
							className="mb-2"
							name="name"
							label={t("permissions.permission")}
							required
							ref={register({ required: true })}
							placeholder="ROLE_"
						/>
					</div>

					<div className="mb-2">
						<TextField
							data-testid="description-input"
							className="mb-2"
							name="description"
							label={t("description", { ns: "common" })}
							required
							ref={register({ required: true })}
						/>
					</div>

					{selectedPermission && (
						<Switch.Group>
							<div className="mt-4  flex items-center">
								<Switch.Label
									passive
									className="mr-10 font-medium text-gray-700"
								>
									{t("permissions.enablePermission")}
								</Switch.Label>
								<Switch
									checked={isEnabled || false}
									onChange={() => setIsEnabled(!isEnabled)}
									className={`${
										isEnabled ? "bg-primary-color" : "bg-gray-200"
									} relative inline-flex h-6 w-12 items-center rounded-full`}
								>
									<span
										className={`${
											!isEnabled ? "translate-x-6" : "translate-x-1"
										} inline-block h-4 w-4 transform rounded-full bg-white`}
									/>
								</Switch>
							</div>
						</Switch.Group>
					)}

					<SubmitButton
						type="submit"
						loading={isLoading}
						label={
							!selectedPermission
								? t("permissions.addPermission")
								: t("permissions.updatePermission")
						}
						className="mt-8 flex m-auto my-5 text-center"
					/>
				</form>
			</FormProvider>
		</Modal>
	);
};
export default AddPermissions;
