import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import Breadcrumbs from "components/breadcrumbs";
import MoreOptionsMenu from "components/common/MoreOptionsMenu";
import { useTranslation } from "react-i18next";
import { BiSticker } from "react-icons/bi";
import { BsCreditCard } from "react-icons/bs";
import { GiSmartphone } from "react-icons/gi";
import {
	BreadCrumbsProps,
	EVCardDataType,
	EVCardTransactionDataType,
} from "types/EVCards";
import EVCardTransactionToolbar from "./EVCardTransactionToolbar";

export const EVCardTransactions = ({
	transactions,
	evCard,
}: {
	transactions: any[];
	evCard: EVCardDataType;
}) => {
	const { t } = useTranslation(["evCards"]);
	const { evCardType, cardNumber } = evCard;

	const CardTypeHeaderIcon = () => {
		let typeIcon;
		if (evCardType === "CARD") {
			typeIcon = <BsCreditCard size={23} />;
		} else if (evCardType === "STICKER") {
			typeIcon = <BiSticker size={24} />;
		} else if (evCardType === "PHONE") {
			typeIcon = <GiSmartphone size={28} />;
		} else {
			typeIcon = null;
		}
		return <div className="flex justify-center">{typeIcon}</div>;
	};

	const routes: BreadCrumbsProps[] = [
		{
			path: "/dashboard",
			breadcrumbName: t("home", { ns: "navigation" }),
		},
		{
			path: "/app-settings/ev-cards",
			breadcrumbName: t("evCardsTitle", { ns: "evCards" }),
		},
		{
			path: "/ev-cards:cardUuid",
			breadcrumbName: cardNumber,
		},
	];

	const columns: ColumnsType<EVCardTransactionDataType> = [
		{
			dataIndex: "transactionId",
			title: t("transactionId"),
			key: "transactionId",
			width: 200,
		},
		{
			dataIndex: "paidAmount",
			title: t("paidAmount"),
			key: "paidAmount",
			render: (paidAmount: number, { currency }) => (
				/* Right align the numbers */
				<span className="flex justify-between px-4">
					<span>{currency}</span>
					<span>{paidAmount}</span>
				</span>
			),
			sorter: (a, b) => a.paidAmount - b.paidAmount,
		},
		{
			dataIndex: "date",
			title: t("date"),
			key: "date",
			render: (date: string) => (
				<span>
					{new Date(`${date} UTC`).toLocaleString("en-US", {
						dateStyle: "medium",
						timeStyle: "short",
					})}
				</span>
			),
			sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
		},
		{
			dataIndex: "location",
			title: t("location"),
			key: "location",
		},
		{
			dataIndex: "duration",
			title: t("duration"),
			key: "duration",
			render: (duration: number) => {
				let durationStr;
				const seconds = Math.floor(duration / 1000);
				const minutes = seconds / 60;
				const hours = minutes / 60;

				if (duration > 3600000) {
					durationStr = `${Math.floor(hours)}h ${Math.floor(minutes % 60)}m`;
				} else {
					durationStr = `${Math.floor(minutes)}m`;
				}

				return <span>{durationStr}</span>;
			},
			sorter: (a, b) => a.duration - b.duration,
		},
		{
			dataIndex: "paymentMethod",
			title: t("paymentMethod"),
			key: "paymentMethod",
			sorter: (a, b) => a.paymentMethod.localeCompare(b.paymentMethod),
		},
		{
			dataIndex: "status",
			title: t("status"),
			key: "status",
			sorter: (a, b) => a.status.localeCompare(b.status),
		},
		{
			title: "Action",
			key: "operation",
			fixed: "right",
			align: "center",
			width: 100,
			render: (item) => (
				<MoreOptionsMenu size="sm" menuList={List} data={item} />
			),
		},
	];

	const List = [
		{
			label: t("details"),
			onClick: () => () => {},
		},
	];

	return (
		<>
			<Breadcrumbs breadCrumb={routes} />
			<EVCardTransactionToolbar handleSubmit={() => {}} />
			{transactions && (
				<>
					<div className="flex mt-4 mb-5 items-center">
						<span className="font-bold mr-5">Card type:</span>
						<CardTypeHeaderIcon />
						<span className="ml-3">({evCardType})</span>
					</div>
					<Table
						columns={columns}
						dataSource={transactions}
						pagination={{ defaultPageSize: 10, showSizeChanger: true }}
					/>
				</>
			)}
		</>
	);
};

export default EVCardTransactions;
