import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import Accordion from "components/common/Accordion";
import SearchInput from "components/common/SearchInput";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiSticker } from "react-icons/bi";
import { BsCreditCard } from "react-icons/bs";
import { GiSmartphone } from "react-icons/gi";

const UserPermissionsTable = ({
	userEvCardList,
	isLoading,
}: {
	// TODO: change data type to EVCardResponse when it is merged with staging
	userEvCardList: any[];
	isLoading: boolean;
}) => {
	const { t } = useTranslation(["common"]);
	const [tableData, setTableData] = useState<any[]>([]);

	const getTableData = (list: any[]): any[] =>
		list.map((evCard) => ({
			...evCard,
			key: evCard.uuid,
		}));

	useEffect(() => {
		setTableData(getTableData(userEvCardList));
	}, [userEvCardList]);

	const handleSearch = (searchTerm: string) => {
		const result = userEvCardList.filter(
			(data: string) =>
				data.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
		);
		setTableData(getTableData(result));
		if (!searchTerm) {
			setTableData(getTableData(userEvCardList));
		}
	};

	const columns: ColumnsType<any> = [
		{
			dataIndex: "evCardType",
			title: t("userDetail.evCardType"),
			key: "evCardType",
			width: 120,
			sorter: (a, b) => a.evCardType.localeCompare(b.evCardType),
			render: (evCardType: string) => {
				let typeIcon;
				if (evCardType === "CARD") {
					typeIcon = <BsCreditCard size={23} />;
				} else if (evCardType === "STICKER") {
					typeIcon = <BiSticker size={24} />;
				} else {
					typeIcon = <GiSmartphone size={28} />;
				}
				return <div className="flex justify-center">{typeIcon}</div>;
			},
		},
		{
			dataIndex: "cardNumber",
			title: t("userDetail.cardNumber"),
			key: "cardNumber",
			sorter: (a, b) => a.cardNumber.localeCompare(b.cardNumber),
			render: (cardNumber: string, { evCardType }) =>
				evCardType !== "STICKER" ? (
					<a
						className="text-primary-color uppercase"
						href={`/app-settings/ev-cards/transactions/${cardNumber}`}
					>
						{cardNumber}
					</a>
				) : (
					<span className="uppercase">{cardNumber}</span>
				),
		},
		{
			dataIndex: "tagId",
			title: t("userDetail.tagId"),
			key: "tagId",
			sorter: (a, b) => a.tagId.localeCompare(b.tagId),
		},
		{
			dataIndex: "parentTagId",
			title: t("userDetail.parentTagId"),
			key: "parentTagId",
			sorter: (a, b) => a.parentTagId.localeCompare(b.parentTagId),
		},
		{
			dataIndex: "vehicleUuid",
			title: t("userDetail.vehicleUuid"),
			key: "vehicleUuid",
			sorter: (a, b) => a.vehicleUuid.localeCompare(b.vehicleUuid),
		},
		{
			dataIndex: "currency",
			title: t("userDetail.currency"),
			key: "currency",
			sorter: (a, b) => a.currency.localeCompare(b.currency),
		},
		{
			dataIndex: "balance",
			title: t("userDetail.balance"),
			key: "balance",
			sorter: (a, b) => a.balance.localeCompare(b.balance),
		},
	];

	return (
		<Accordion title={t("userDetail.evCards")}>
			<div className="mb-4">
				<SearchInput
					handleSubmit={handleSearch}
					placeholder={t("userDetail.searchEvCards", { ns: "common" })}
				/>
			</div>
			<Table
				loading={isLoading}
				columns={columns}
				dataSource={tableData}
				scroll={{ y: 250 }}
				pagination={{
					pageSizeOptions: ["10", "20", "50", "100"],
					showSizeChanger: true,
				}}
			/>
		</Accordion>
	);
};

export default UserPermissionsTable;
