import { Dialog, Transition } from "@headlessui/react";
import { IoCloseSharp } from "react-icons/io5";
import { RiErrorWarningLine } from "react-icons/ri";
import { BiError } from "react-icons/bi";

const AlertModal = ({
	show,
	onClose,
	message,
	type,
	confirm,
	deny,
	confirmText = "Confirm",
	denyText = "Deny",
}: {
	show: boolean;
	onClose: () => void;
	confirm?: () => void;
	deny?: () => void;
	message: string;
	confirmText?: string;
	denyText?: string;

	type: "warning" | "error" | "success";
}) => (
	<Transition.Root show={show}>
		<Dialog
			className="fixed z-10 overflow-y-scroll inset-0 tailwind-container"
			onClose={onClose}
		>
			<div className="flex items-center justify-center">
				<Transition.Child
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-20 transition-opacity" />
				</Transition.Child>
				<Transition.Child
					className="flex flex-col min-h-screen w-full items-center justify-center lg:py-12"
					enter="ease-out duration-300"
					enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					enterTo="opacity-100 translate-y-0 sm:scale-100"
					leave="ease-in duration-800"
					leaveFrom="opacity-100 translate-y-0 sm:scale-100"
					leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
				>
					<div className="relative bg-white rounded-lg shadow m-5">
						<button
							type="button"
							onClick={onClose}
							className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
						>
							<IoCloseSharp size={24} />
						</button>

						<div className="p-6 text-center">
							{type === "warning" && (
								<RiErrorWarningLine className="mx-auto mb-4 w-14 h-14 text-yellow-300" />
							)}

							{type === "error" && (
								<BiError className="mx-auto mb-4 w-14 h-14 text-red-600" />
							)}

							{type === "success" && (
								<RiErrorWarningLine className="mx-auto mb-4 w-14 h-14 text-primary-color" />
							)}

							<h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
								{message}
							</h3>
							{confirm && (
								<button
									data-modal-toggle="popup-modal"
									type="button"
									onClick={confirm}
									className="focus:ring-4 bg-primary-color focus:ring-green-200 hover:bg-green-800 focus:outline-none text-white font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
								>
									{confirmText}
								</button>
							)}
							{deny && (
								<button
									data-modal-toggle="popup-modal"
									type="button"
									onClick={confirm}
									className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10"
								>
									{denyText}
								</button>
							)}
						</div>
					</div>
				</Transition.Child>
			</div>
		</Dialog>
	</Transition.Root>
);

export default AlertModal;
