import MultiSelection from "components/common/MultiSelection";
import SearchInput from "components/common/SearchInput";
import { SubmitButton } from "components/form/InputField";
import { useTranslation } from "react-i18next";
import { MdKeyboardArrowDown } from "react-icons/md";

const FilterButton = ({ label }: { label: string }) => (
	<span className="flex items-center text-bold">
		<MdKeyboardArrowDown className="mr-2" /> {label}
	</span>
);

export const EVCardToolbar = ({
	handleSubmit,
	handleShowEVCardModal,
	filterByCardStatus,
	filterByCardType,
	setFilterByCardStatus,
	setFilterByCardType,
	cardTypeFilterOptions,
	cardStatusFilterOptions,
}: {
	handleSubmit: (data: string) => void;
	handleShowEVCardModal: () => void;
	filterByCardStatus: boolean[];
	filterByCardType: string[];
	setFilterByCardStatus: (data: boolean[]) => void;
	setFilterByCardType: (data: string[]) => void;
	cardTypeFilterOptions: { label: string; value: string }[];
	cardStatusFilterOptions: { label: string; value: boolean }[];
}) => {
	const { t } = useTranslation(["evCards"]);

	const onChangeFilterByCardType = (checkedValues: string[]) => {
		setFilterByCardType(checkedValues);
	};
	const onChangeFilterByCardStatus = (checkedValues: boolean[]) => {
		setFilterByCardStatus(checkedValues);
	};

	return (
		<div className="py-4 justify-between items-end md:items-center flex flex-col md:flex-row">
			<div className="flex items-center">
				<SearchInput
					handleSubmit={handleSubmit}
					placeholder={t("searchEVCardPlaceholder")}
				/>
				<span className="ml-10">
					<MultiSelection
						btnIcon={<FilterButton label="Filter by card types" />}
						options={cardTypeFilterOptions}
						pnlClassName="p-4"
						onChange={(checkedValues) =>
							onChangeFilterByCardType(checkedValues)
						}
						defaultValue={filterByCardType}
					/>
				</span>
				<span className="ml-10">
					<MultiSelection
						btnIcon={<FilterButton label="Filter by Status" />}
						options={cardStatusFilterOptions}
						pnlClassName="p-4"
						onChange={(checkedValues) =>
							onChangeFilterByCardStatus(checkedValues)
						}
						defaultValue={filterByCardStatus}
					/>
				</span>
			</div>
			<SubmitButton
				className="my-5 rounded-[5px]"
				type="button"
				onClick={handleShowEVCardModal}
				label={t("addEVCard")}
			/>
		</div>
	);
};

export default EVCardToolbar;
