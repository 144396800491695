import { TbLayoutCards } from "react-icons/tb";
import { FiList } from "react-icons/fi";
import { AiOutlineUserAdd, AiOutlineClose } from "react-icons/ai";
import { RiDeleteBinLine } from "react-icons/ri";
import {
	MdKeyboardArrowDown,
	MdKeyboardArrowLeft,
	MdKeyboardArrowRight,
} from "react-icons/md";
import PopoverMenu from "components/common/PopoverMenu";
import { Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import IssuePopOverMenu from "./IssuePopOverMenu";

const SupportActionMenu = ({
	// onAssign,
	onDelete,
	handleChangeLayout,
	onSelectAll,
	sortBy,
	setSortBy,
	itemOrder,
	setItemOrder,
	sortByOptions,
	orderByOptions,
	layoutMode,
}: {
	// onAssign?: () => void;
	onDelete: () => void;
	handleChangeLayout: (layout: "card" | "table") => void;
	onSelectAll: (e: any) => void;
	sortBy: string;
	setSortBy: (sort: string) => void;
	itemOrder: string;
	setItemOrder: (order: string) => void;
	sortByOptions: any[];
	orderByOptions: any[];
	layoutMode: "card" | "table";
}) => {
	const { t } = useTranslation(["support"]);

	const content = (
		<div className="w-44 p-2">
			<button className="hover:bg-gray-300 focus:bg-green-200 w-full p-1 rounded-sm text-left flex items-center justify-between">
				<span>Low</span>
			</button>
			<button className="hover:bg-gray-300 focus:bg-green-200 w-full p-1 rounded-sm text-left flex items-center justify-between">
				<span>Medium</span>
			</button>
			<button className="hover:bg-gray-300 focus:bg-green-200 w-full p-1 rounded-sm text-left flex items-center justify-between">
				<span>High</span>
			</button>
			<button className="hover:bg-gray-300 focus:bg-green-200 w-full p-1 rounded-sm text-left flex items-center justify-between">
				<span>Urgent</span>
			</button>
		</div>
	);

	return (
		<div className="flex  mb-5 items-center justify-between">
			<div className="flex">
				{layoutMode === "card" && (
					<div className="flex items-center ml-5 mr-5">
						<div className="mr-5">
							<Checkbox onChange={onSelectAll} />
						</div>
						<span className="flex">
							{t("sortBy")}:
							<PopoverMenu
								buttonClass="mx-2 mr-4 flex items-center justify-between font-bold"
								placement="bottomLeft"
								showArrow={false}
								content={
									<IssuePopOverMenu
										menuItems={sortByOptions}
										subMenuItems={orderByOptions}
										selectMenuItem={setSortBy}
										selectSubMenuItem={setItemOrder}
										selected={sortBy}
										subSelected={itemOrder}
									/>
								}
								icon={<MdKeyboardArrowDown className="ml-2" />}
								label={t(sortBy)}
								noDecoration
							/>
						</span>
					</div>
				)}
				<PopoverMenu
					buttonClass="flex items-center justify-between border rounded-md py-1 px-2 bg-white"
					placement="bottomLeft"
					showArrow={false}
					content={content}
					icon={<AiOutlineUserAdd className="ml-2" />}
					label={t("assign")}
					noDecoration
				/>
				<button
					onClick={onDelete}
					className="flex ml-4 items-center justify-between border rounded-md py-1 px-2 bg-white mx-2"
				>
					<p className="m-0 p-0">{t("cancel")}</p>
					<AiOutlineClose className="ml-2" />
				</button>

				<button
					onClick={onDelete}
					className="flex items-center justify-between border rounded-md py-1 px-2 bg-white mx-2"
				>
					<p className="m-0 p-0">{t("delete")}</p>
					<RiDeleteBinLine className="ml-2" />
				</button>
			</div>

			<div className="flex items-center">
				<button
					onClick={() => handleChangeLayout("card")}
					className="mr-4 bg-white p-2 border rounded-md"
				>
					<TbLayoutCards className="text-xl text-gray-400 hover:text-primary-color " />
				</button>

				<button
					onClick={() => handleChangeLayout("table")}
					className="bg-white p-2 border rounded-md"
				>
					<FiList className="text-xl text-gray-400 hover:text-primary-color " />
				</button>

				<span className="mx-3">1 - 3 of 3</span>
				<button className="bg-white p-2 border rounded-l-md">
					<MdKeyboardArrowLeft className="text-2xl text-gray-400 hover:text-primary-color " />
				</button>
				<button className="bg-white p-2 border rounded-r-md">
					<MdKeyboardArrowRight className="text-2xl text-gray-400 hover:text-primary-color " />
				</button>
			</div>
		</div>
	);
};

export default SupportActionMenu;
