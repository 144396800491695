import {
	CreateSiteOwners,
	GetAllSiteOwners,
	UpdateASiteOwner,
} from "api/admin";
import SiteOwners from "components/usersManagement/siteOwners";
import { AlertContext } from "context/AlertContext";
import DashboardLayout from "Layouts/DashboardLayout";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import _ from "lodash";
import { ErrorResponse } from "types";
import { SiteOwnerParams, SiteOwnerResponse } from "types/Stations";
import { SiteContext } from "context/SitesContext";

const SiteOwnersPage = () => {
	const { t } = useTranslation(["navigation", "constants"]);
	const [pagination, setPaginationState] = useState({ page: 0, pageSize: 10 });

	const { handleShowAlert } = useContext(AlertContext);
	const { selectedSiteOwner } = useContext(SiteContext);
	const { uuid } = selectedSiteOwner || {};

	const queryClient = useQueryClient();

	const { mutateAsync, isLoading } = useMutation<
		SiteOwnerResponse,
		ErrorResponse,
		SiteOwnerParams
	>(CreateSiteOwners, {
		onSuccess: () => {
			queryClient.invalidateQueries("siteowners");
		},
		onMutate: (data) => {
			queryClient.setQueryData("siteowners", data);
		},
	});

	const { mutateAsync: updateMuteAsync, isLoading: updateSiteOwnerLoading } =
		useMutation<SiteOwnerResponse, ErrorResponse, SiteOwnerParams>(
			UpdateASiteOwner,
			{
				onSuccess: () => {
					queryClient.invalidateQueries("siteowners");
				},
				onMutate: (data) => {
					queryClient.setQueryData("siteowners", data);
				},
			}
		);

	const { page, pageSize } = pagination;

	const {
		isLoading: siteOwnerIsLoading,
		data,
		refetch,
	} = useQuery("siteowners", () => GetAllSiteOwners(page, pageSize));

	useEffect(() => {
		refetch();
	}, [pagination]);

	const siteOwners: SiteOwnerResponse[] = _.get(data, "content") || [];
	const totalPages: number = _.get(data, "totalPages");

	const handleSubmitAddEditStationOwners = async (
		props: SiteOwnerParams
	): Promise<boolean> => {
		const mutate = !selectedSiteOwner ? mutateAsync : updateMuteAsync;

		const { message } = await mutate({ ...props, uuid });

		if (!message) return false;

		if (
			message ===
				t("The site owner was created successfully", {
					lng: "en",
					ns: "constants",
				}) ||
			message ===
				t("The site owner was updated successfully", {
					lng: "en",
					ns: "constants",
				})
		) {
			handleShowAlert({ message, severity: "success" });
			return true;
		}

		handleShowAlert({ message, severity: "error" });

		return false;
	};

	const hanlePageChange = (pageNumber: number, size: number) => {
		setPaginationState({ page: pageNumber, pageSize: size });
	};

	return (
		<DashboardLayout>
			<div className="flex flex-col">
				<span className="text-2xl font-medium pb-10">{t("siteOwners")}</span>

				<SiteOwners
					pageTotal={totalPages}
					handleSubmitAddEditStationOwners={handleSubmitAddEditStationOwners}
					isLoading={isLoading || updateSiteOwnerLoading}
					siteOwners={siteOwners}
					siteOwnerIsLoading={siteOwnerIsLoading}
					handlePageChange={hanlePageChange}
				/>
			</div>
		</DashboardLayout>
	);
};

export default SiteOwnersPage;
