import Breadcrumbs from "components/breadcrumbs";
import { useTranslation } from "react-i18next";
import { GrUpdate } from "react-icons/gr";
import { useEffect, useState } from "react";
import { BreadCrumbsProps } from "types";
import SupportToolbar from "./SupportToolbar";
import SupportActionMenu from "./SupportActionMenu";
import SupportFilter from "./SupportFilter";
import SupportCardLayout from "./layout/SupportCardLayout";
import SupportTableLayout from "./layout/SupportTableLayout";

const SupportList = ({
	issues,
	statusOptions,
	priorityOptions,
}: {
	issues: any[];
	statusOptions: any[];
	priorityOptions: any[];
}) => {
	const { t } = useTranslation(["common", "support"]);
	const [layout, setLayout] = useState<"card" | "table">("card");
	const [sortedIssues, setSortedIssues] = useState<any[]>(issues);
	const [sortBy, setSortBy] = useState("dateCreated");
	const [itemOrder, setItemOrder] = useState("ascending");

	const sortByOptions = [
		{
			key: "1",
			name: "dateCreated",
		},
		{
			key: "2",
			name: "dueByTime",
		},
		{
			key: "3",
			name: "lastModified",
		},
		{
			key: "4",
			name: "priority",
		},
		{
			key: "5",
			name: "status",
		},
	];

	const orderByOptions = [
		{
			key: "1",
			name: "ascending",
		},
		{
			key: "2",
			name: "descending",
		},
	];

	/* Show the search result */
	const handleSearch = (searchTerm: string) => {
		const result = issues.filter(
			(issue: any) =>
				issue.subject.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0 ||
				issue.agent.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0 ||
				issue.department.toString().indexOf(searchTerm.toLowerCase()) >= 0
		);
		setSortedIssues(result);
		if (!searchTerm) {
			setSortedIssues(issues);
		}
	};

	/* Functions to sort issues */
	const sortIssuesByDate = (propName: string, isAscending: boolean) =>
		issues.sort((a, b) => {
			const compareRes =
				new Date(a[propName]).getTime() - new Date(b[propName]).getTime();
			return isAscending ? compareRes : compareRes * -1;
		});

	const handleSortBy = () => {
		const isAscending = itemOrder === "ascending";
		const priorityArr = ["low", "medium", "high", "urgent"];
		switch (sortBy) {
			case "dateCreated":
				setSortedIssues(sortIssuesByDate("createdAt", isAscending));
				break;
			case "dueByTime":
				setSortedIssues(sortIssuesByDate("dueByTime", isAscending));
				break;
			case "priority":
				setSortedIssues(
					issues.sort((a, b) => {
						const compareRes =
							priorityArr.indexOf(a.priority) - priorityArr.indexOf(b.priority);
						return isAscending ? compareRes : compareRes * -1;
					})
				);
				break;
			case "status":
				setSortedIssues(
					issues.sort((a, b) =>
						isAscending
							? a.status.localeCompare(b.status)
							: b.status.localeCompare(a.status)
					)
				);
				break;
			default:
				break;
		}
	};

	useEffect(() => handleSortBy(), [sortBy, itemOrder]);

	const routes: BreadCrumbsProps[] = [
		{
			path: "/dashboards",
			breadcrumbName: t("home", { ns: "navigation" }),
		},
		{
			path: "/support",
			breadcrumbName: t("support", { ns: "navigation" }),
		},
	];

	const handleChangeLayout = (data: "card" | "table") => {
		if (data === "card") {
			setLayout("card");
			return;
		}

		setLayout("table");
	};

	return (
		<>
			<Breadcrumbs breadCrumb={routes} />
			<SupportToolbar handleSubmit={handleSearch} />
			<div className="flex space-x-4">
				<div className="flex flex-col w-[80%]">
					<SupportActionMenu
						handleChangeLayout={handleChangeLayout}
						onDelete={() => {}}
						onSelectAll={() => {}}
						sortBy={sortBy}
						setSortBy={setSortBy}
						itemOrder={itemOrder}
						setItemOrder={setItemOrder}
						sortByOptions={sortByOptions}
						orderByOptions={orderByOptions}
						layoutMode={layout}
					/>
					<div className="w-full">
						<div className="bg-white w-10 border h-10  flex items-center m-auto rounded-full">
							<button className="rounded-full p-1 m-auto">
								<GrUpdate className="text-white text-,d" />
							</button>
						</div>
					</div>
					{layout === "card" ? (
						<SupportCardLayout
							issues={sortedIssues}
							statusOptions={statusOptions}
							priorityOptions={priorityOptions}
						/>
					) : (
						<SupportTableLayout
							issues={sortedIssues}
							statusOptions={statusOptions}
							priorityOptions={priorityOptions}
						/>
					)}
				</div>
				<div className="w-[20%] bg-white h-full  p-5">
					<SupportFilter />
				</div>
			</div>
		</>
	);
};

export default SupportList;
