import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import Accordion from "components/common/Accordion";
import SearchInput from "components/common/SearchInput";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { VehicleResponse } from "types/Settings";

interface VehicleDataSource extends VehicleResponse {
	key: string;
}

const UserVehiclesTable = ({
	userVehicleList,
	isLoading,
}: {
	userVehicleList: VehicleResponse[];
	isLoading: boolean;
}) => {
	const { t } = useTranslation(["common"]);
	const [tableData, setTableData] = useState<VehicleDataSource[]>([]);

	const getTableData = (list: VehicleResponse[]): VehicleDataSource[] =>
		list.map((vehicle) => ({
			...vehicle,
			key: vehicle.uuid,
		}));

	useEffect(() => {
		setTableData(getTableData(userVehicleList));
	}, [userVehicleList]);

	const handleSearch = (searchTerm: string) => {
		const result = userVehicleList.filter(
			(data: VehicleResponse) =>
				data.vehicleMake.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0 ||
				data.vehicleModel.toLowerCase().indexOf(searchTerm.toLowerCase()) >=
					0 ||
				data.registrationNumber
					.toLowerCase()
					.indexOf(searchTerm.toLowerCase()) >= 0 ||
				data.year.toString().indexOf(searchTerm.toLowerCase()) >= 0
		);
		setTableData(getTableData(result));
		if (!searchTerm) {
			setTableData(getTableData(userVehicleList));
		}
	};

	const columns: ColumnsType<VehicleDataSource> = [
		{
			dataIndex: "vehicleMake",
			title: t("userDetail.vehicleMake"),
			key: "vehicleMake",
			width: "25%",
			sorter: (a, b) => a.vehicleMake.localeCompare(b.vehicleMake),
		},
		{
			dataIndex: "vehicleModel",
			title: t("userDetail.vehicleModel"),
			key: "vehicleModel",
			width: "25%",
			sorter: (a, b) => a.vehicleModel.localeCompare(b.vehicleModel),
		},
		{
			dataIndex: "registrationNumber",
			title: t("userDetail.registrationNumber"),
			key: "registrationNumber",
			width: "25%",
			sorter: (a, b) =>
				a.registrationNumber.localeCompare(b.registrationNumber),
		},
		{
			dataIndex: "year",
			title: t("userDetail.year"),
			key: "year",
			width: "25%",
			sorter: (a, b) => (a.year > b.year ? 1 : -1),
		},
	];

	return (
		<Accordion title={t("userDetail.vehicles")}>
			<div className="mb-4">
				<SearchInput
					handleSubmit={handleSearch}
					placeholder={t("userDetail.searchVehicles", { ns: "common" })}
				/>
			</div>
			<Table
				loading={isLoading}
				columns={columns}
				dataSource={tableData}
				scroll={{ y: 250 }}
				pagination={{
					pageSizeOptions: ["10", "20", "50", "100"],
					showSizeChanger: true,
				}}
			/>
		</Accordion>
	);
};

export default UserVehiclesTable;
