import Breadcrumbs from "components/breadcrumbs";
import { useTranslation } from "react-i18next";
import { BreadCrumbsProps } from "types";
import { UserInfo } from "types/Auth";
import { VehicleResponse } from "types/Settings";
import UserDataDisplay from "./UserDataDisplay";
import UserPermissionsTable from "./UserPermissionsTable";
import UserVehiclesTable from "./UserVehiclesTable";
import UserEvCardsTable from "./UserEvCardsTable";

const UserDetail = ({
	user,
	isLoading,
	userVehicleList,
	userEvCardList,
}: {
	user: UserInfo | null | undefined;
	isLoading: boolean;
	userVehicleList: VehicleResponse[] | undefined;
	// TODO: change data type to EVCardResponse when it is merged with staging
	userEvCardList: any[];
}) => {
	const { t } = useTranslation(["navigation", "common"]);
	const { permissionNames } = user || {};

	const routes: BreadCrumbsProps[] = [
		{
			path: "/dashboard",
			breadcrumbName: t("home"),
		},
		{
			path: "/users",
			breadcrumbName: t("users"),
		},
		{
			path: `/users/${user?.uuid || ""}`,
			breadcrumbName: user?.uuid || "",
		},
	];

	return (
		<div>
			<Breadcrumbs breadCrumb={routes} />
			<UserDataDisplay user={user} isLoading={isLoading} />
			<UserPermissionsTable
				permissionNames={permissionNames || []}
				isLoading={isLoading}
			/>
			<UserVehiclesTable
				userVehicleList={userVehicleList || []}
				isLoading={isLoading}
			/>
			<UserEvCardsTable
				userEvCardList={userEvCardList || []}
				isLoading={isLoading}
			/>
		</div>
	);
};

export default UserDetail;
