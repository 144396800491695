import React, { useEffect, useState } from "react";
import { UserInfo } from "types/Auth";
import {
	clearSessionCookie,
	getSessionCookie,
	setSessionCookie,
} from "utils/cookies";
import LogRocket from "logrocket";

const AuthContext = React.createContext<{
	isSignedIn: boolean;
	login: (data: UserInfo) => void;
	logout: () => void;
	loading: boolean;
	token: string | null;
	user: UserInfo | null;
	handleToken: (data: string) => void;
	handleUser: (data: UserInfo) => void;
	handleLoading: (data: boolean) => void;
}>({
	isSignedIn: true,
	login: () => {},
	loading: false,
	user: null,
	token: null,
	logout: () => {},
	handleToken: () => {},
	handleUser: () => {},
	handleLoading: () => {},
});

const AuthContextProvider = (props: {
	children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}) => {
	const [user, setUser] = useState<UserInfo | null>(null);
	const [isSignedIn, setIsSignedIn] = useState<boolean>(true);
	const [loading, setLoading] = useState<boolean>(false);
	const [token, setToken] = useState<string | null>(null);

	useEffect(() => {
		const sessionCookie = getSessionCookie();

		if (sessionCookie) {
			setIsSignedIn(true);
			setToken(sessionCookie);
		} else {
			setIsSignedIn(false);
		}
	}, []);

	// call this function when you want to authenticate the user
	const login = async (data: UserInfo) => {
		if (!data.token) return;
		setSessionCookie(data.token);
		setToken(data.token);
		setIsSignedIn(true);
		setUser({ ...data, permissionNames: data.permissions || [] });
	};

	// call this function to sign out logged in user
	const logout = () => {
		setUser(null);
		setLoading(false);
		setIsSignedIn(false);
		clearSessionCookie();
	};

	const handleToken = (data: string) => {
		setToken(data);
	};

	const handleUser = (data: UserInfo) => {
		setUser(data);
	};

	const handleLoading = (data: boolean) => {
		setLoading(data);
	};

	useEffect(() => {
		if (user) {
			LogRocket.identify(user.uuid, {
				name: user.names,
				email: user.email,
			});
		}
	}, [user]);

	const value = React.useMemo(
		() => ({
			user,
			isSignedIn,
			setUser,
			login,
			logout,
			loading,
			setToken,

			setIsSignedIn,
			token,
			handleToken,
			handleUser,
			handleLoading,
		}),
		[user, token, loading, isSignedIn]
	);

	return (
		<AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
	);
};

export { AuthContext, AuthContextProvider };
