import React, { useRef, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import _ from "lodash";
import { useLoadScript, Autocomplete } from "@react-google-maps/api";
import { BsCheckCircle, BsCalendar4 } from "react-icons/bs";
import { FiCircle } from "react-icons/fi";
import NumberFormat from "react-number-format";
import ReactDatePicker from "react-datepicker";
import { AiOutlineInfoCircle } from "react-icons/ai";
import ReactTooltip from "react-tooltip";
import Button from "components/common/Button";
import config from "config";
import { useTranslation } from "react-i18next";
import FlagCARound from "../../assets/svg/assets/FlagCARound";
import "react-datepicker/dist/react-datepicker.css";
import { formatDateTime, dateToDateTime } from "../../utils";
import EyeOpen from "../../assets/svg/assets/EyeOpen";
import EyeClosed from "../../assets/svg/assets/EyeClosed";

const TextInputComponent = (props: any, ref: any) => {
	const { hasError, disabled, className, ...inputProps } = props;

	return (
		<input
			id={inputProps.name}
			data-testid={inputProps.name}
			className={`${
				disabled ? "border-gray-200" : "border-gray-300"
			} block px-3 py-2 rounded-md w-full bg-gray-100 border ${
				hasError ? "border-red-600" : "border-gray-300"
			} text-gray-900 ${
				hasError ? "focus:border-red-600" : "focus:border-primary-color"
			} focus:outline-none focus:ring-0 focus:shadow-input ${className}`}
			disabled={disabled}
			{...inputProps}
			ref={ref}
		/>
	);
};

const TextInput = React.forwardRef(TextInputComponent);

const ErrorLabel = (props: any) => {
	const { error, className, ...otherProps } = props;
	const { t } = useTranslation(["common"]);

	return (
		<small className={`${className} text-red-500`} {...otherProps}>
			{error &&
				(error.type === "required" && !error.message
					? t("thisFieldIsRequired")
					: error.message)}
		</small>
	);
};

const InfoTip = ({ text }: { text: string }) => {
	if (!text) return null;

	return (
		<span className="float-right pt-2 pr-2">
			<AiOutlineInfoCircle className="text-neutral-grey-2" data-tip={text} />{" "}
			<ReactTooltip
				className="w-1/2 lg:w-1/4"
				textColor="var(--colors-natural-dark)"
				backgroundColor="var(--colors-natural-grey-4)"
			/>
		</span>
	);
};

const TextFieldComponent = (props: any, ref: any) => {
	const { label, rootClass, required, infoText, ...inputProps } = props;
	const { errors } = useFormContext();
	const error = _.get(errors, inputProps.name);

	return (
		<div className={`flex flex-col ${rootClass}`}>
			<label htmlFor={inputProps.name} className="font-medium text-gray-700">
				{label}
				{required && <span className="text-red-600">{" *"}</span>}
				<InfoTip text={infoText} />
			</label>
			<TextInput {...inputProps} hasError={!!error} ref={ref} />
			{error && <ErrorLabel error={error} />}
		</div>
	);
};

const PasswordFieldComponent = (props: any, ref: any) => {
	const [show, setShow] = useState(false);
	const { errors } = useFormContext();
	const { label, rootClass, required, ...inputProps } = props;
	const error = _.get(errors, inputProps.name);
	const toggleShow = () => setShow(!show);

	return (
		<div className={`flex flex-col ${rootClass}`}>
			<label htmlFor={inputProps.name} className="text-neutral-grey-1">
				{label}
				{required && <span className="text-red-600">{" *"}</span>}
			</label>
			<div className="relative">
				<TextInput
					{...inputProps}
					hasError={!!error}
					type={show ? "text" : "password"}
					ref={ref}
				/>
				<div
					onKeyDown={toggleShow}
					tabIndex={0}
					role="button"
					onClick={toggleShow}
					className="cursor-pointer absolute inset-y-0 right-0 pb-1 pr-3 flex items-center text-sm"
				>
					{show ? <EyeClosed /> : <EyeOpen />}
				</div>
			</div>
			{error && <ErrorLabel error={error} />}
		</div>
	);
};

const PhoneField = (props: any) => {
	const { label, rootClass, required, name, rules, ...inputProps } = props;
	const { errors, control } = useFormContext();

	const error = _.get(errors, name);

	let className =
		"block mb-2 px-3 py-2 pr-2 pl-16 rounded-md w-full bg-gray-100 border border-gray-200 text-gray-900 focus:border-primary-color focus:outline-none focus:ring-0 focus:shadow-input";
	className += error ? " border-red-600" : " border-gray-300";

	return (
		<div className={`flex flex-col ${rootClass}`}>
			<label htmlFor={name} className="font-medium text-neutral-grey-1">
				{label}
				{required && <span className="text-red-600">{" *"}</span>}
			</label>

			<div className="relative">
				<div className="absolute py-2.5 pl-3 flex items-center">
					<FlagCARound size={22} className="mr-2" /> +1
				</div>

				<Controller
					name={name}
					control={control}
					rules={rules}
					render={({ onChange, value }) => (
						<NumberFormat
							id={name}
							name={name}
							className={className}
							mask="_"
							onValueChange={(v: { value: any }) => onChange(v.value)}
							format="(###) ###-####"
							placeholder="(604) 000-0000"
							value={value}
							{...inputProps}
						/>
					)}
				/>
			</div>
			{error && <ErrorLabel error={error} />}
		</div>
	);
};
const libraries: "places"[] = ["places"];
const AutoAddressFieldComponent = (props: any, ref: any) => {
	const { label, rootClass, required, getLocationData, ...inputProps } = props;

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: config.googleClientId,
		libraries,
	});

	const { errors } = useFormContext();
	const error = _.get(errors, inputProps.name);

	let autocomplete: any;

	const onLoad = (data: any) => {
		autocomplete = data;
	};

	const onPlaceChanged = () => {
		if (autocomplete !== null) {
			getLocationData(autocomplete.getPlace());
		} else {
			// eslint-disable-next-line no-console
			console.log("Autocomplete is not loaded yet!");
		}
	};

	return (
		<div className={`${rootClass} tw-relative`}>
			<label htmlFor={inputProps.name} className="text-neutral-grey-1">
				{label}
				{required && <span className="text-red-600">{" *"}</span>}
			</label>
			<div className="relative">
				{isLoaded && (
					<Autocomplete
						options={{
							componentRestrictions: { country: "ca" },
							fields: ["formatted_address", "geometry.location"],
						}}
						onPlaceChanged={onPlaceChanged}
						onLoad={onLoad}
					>
						<TextInput {...inputProps} hasError={!!error} ref={ref} />
					</Autocomplete>
				)}
				{errors && <ErrorLabel error={error} />}
			</div>
		</div>
	);
};

export const SubmitButton = (props: {
	[x: string]: any;
	disabled?: any;
	className?: any;
	loading?: boolean;
	label: string;
	type: "button" | "submit" | "reset";
}) => {
	const { disabled, type, className, loading, label, ...otherProps } = props;

	return (
		<Button
			type={type}
			id={otherProps.type}
			loading={loading}
			className={`${
				disabled ? "bg-gray-400" : "bg-primary-color"
			} focus:ring-4 focus:ring-green-200 text-white py-2 px-6 rounded-full text-center hover:bg-green-800 focus:outline-none ${className}`}
			disabled={disabled}
			{...otherProps}
			label={label}
		/>
	);
};

const SelectComponent = (
	props: {
		[x: string]: any;
	},
	ref: React.LegacyRef<HTMLSelectElement> | undefined
) => {
	const {
		label,
		options,
		required,
		className,
		rootClass,
		infoText,
		disabled,
		placeholder,
		...inputProps
	} = props;
	const { errors, watch } = useFormContext();
	const selectValue = watch(inputProps.name);
	const error = _.get(errors, inputProps.name);

	return (
		<div className={`flex flex-col ${rootClass}`}>
			<label htmlFor={inputProps.name} className="text-neutral-grey-1">
				{label}
				{required && <span className="text-red-600">{" *"}</span>}
				<InfoTip text={infoText} />
			</label>
			<select
				data-testid={inputProps.name}
				id={inputProps.name}
				disabled={disabled}
				multiple={false}
				defaultValue={selectValue || ""}
				className={`block px-3 py-2 rounded-md w-full bg-gray-100 border-1 border-gray-300 ${
					error ? "border-red-600" : "border-gray-300"
				} ${selectValue ? "text-black" : "text-gray-300"}
					${disabled ? "border-gray-400" : ""}
				placeholder-gray-300 focus:placeholder-gray-400 focus:border-primary-color focus:ring-0 focus:outline-none focus:shadow-input ${className}`}
				{...inputProps}
				ref={ref}
			>
				{placeholder ? (
					<option disabled key="default" value="">
						{placeholder}
					</option>
				) : null}

				{options.map(
					(
						option: {
							value: string | number;
							name: string | number;
						},
						i: React.Key | null | undefined
					) => (
						<option key={i} value={option.value}>
							{option.name}
						</option>
					)
				)}
			</select>
			{error && <ErrorLabel error={error} />}
		</div>
	);
};

const CheckboxComponent = (
	props: { [x: string]: any; label: any; name: any; rootClass: any },
	ref: React.LegacyRef<HTMLInputElement> | undefined
) => {
	const { label, name, rootClass, ...inputProps } = props;

	return (
		<div className={`flex justify-start items-center ${rootClass}`}>
			<input
				id={name}
				className="align-top"
				type="checkbox"
				name={name}
				ref={ref}
				{...inputProps}
			/>
			<label className="ml-4 mb-0 mt-5" htmlFor={name}>
				{label}
			</label>
		</div>
	);
};

const RadioFieldComponent = (
	props: {
		[x: string]: any;
		name: any;
		options?: never[] | undefined;
		rootClass: any;
	},
	ref: React.LegacyRef<HTMLInputElement> | undefined
) => {
	const { name, options = [], rootClass, ...other } = props;

	return (
		<div className={`${rootClass} flex`}>
			{options.map(
				(o: {
					label: any;
					value: string | number | readonly string[] | undefined;
					defaultChecked: any;
				}) => (
					<div className="w-28" key={o.label}>
						<input
							id={`radio-${name}-${o.label}`}
							type="radio"
							name={name}
							value={o.value}
							defaultChecked={!!o.defaultChecked}
							ref={ref}
							{...other}
						/>
						<label key={o.label} htmlFor={`radio-${name}-${o.label}`}>
							<BsCheckCircle size="20" />
							<FiCircle size="20" color="#d3d7dc" />
							<span>{o.label}</span>
						</label>
					</div>
				)
			)}
		</div>
	);
};

const DatePickerComponent = (
	props: {
		label: any;
		name: any;
		rootClass: any;
		className: any;
		required: any;
		maxDate: any;
		valueFormat?: "yyyy-MM-dd" | undefined;
		inputFormat?: "dd/MM/yyyy" | undefined;
		placeholder: any;
	},
	ref: React.LegacyRef<HTMLInputElement> | undefined
) => {
	const {
		label,
		name,
		rootClass,
		className,
		required,
		maxDate,
		valueFormat = "yyyy-MM-dd",
		inputFormat = "dd/MM/yyyy",
		placeholder,
	} = props;
	const { errors, setValue, watch } = useFormContext();
	const calendarRef: any = useRef();
	const currentValue = watch(name);
	const selectedDate = currentValue && dateToDateTime(currentValue);
	const error = _.get(errors, name);

	const handleDateChange = (date: any) => {
		setValue(name, formatDateTime(date, valueFormat));
	};

	const onShowCalendar = () => {
		if (calendarRef.current) {
			calendarRef.current.setOpen(true);
		}
	};

	return (
		<div className={`${rootClass}`}>
			<label htmlFor={name} className="text-neutral-grey-1">
				{label}
				{required && <span className="text-red-600">{" *"}</span>}
			</label>

			<div className="relative">
				<ReactDatePicker
					id="datepicker-input"
					ref={calendarRef}
					selected={selectedDate}
					dateFormat={inputFormat}
					showMonthDropdown
					showYearDropdown
					dropdownMode="select"
					maxDate={maxDate || new Date()}
					onChange={handleDateChange}
					className={`block px-3 py-2 rounded-md w-full bg-neutral-light border-2 ${
						error ? "border-red-600" : "border-neutral-grey-3"
					} placeholder-neutral-grey-2 focus:placeholder-neutral-grey-2 focus:border-primary-dark-green focus:outline-none focus:ring-0 focus:shadow-input ${className}`}
					showPopperArrow={false}
					placeholderText={placeholder}
				/>
				<div
					onKeyDown={onShowCalendar}
					tabIndex={0}
					role="button"
					onClick={onShowCalendar}
					className="cursor-pointer absolute inset-y-0 right-0 pb-1 pr-3 flex items-center text-sm"
				>
					<BsCalendar4 />
				</div>
			</div>
			<input
				type="hidden"
				ref={ref}
				name={name}
				id={name}
				value={currentValue}
			/>

			{error && <ErrorLabel error={error} />}
		</div>
	);
};

const TextField = React.forwardRef(TextFieldComponent);
const PasswordField = React.forwardRef(PasswordFieldComponent);
const Select = React.forwardRef(SelectComponent);
const Checkbox = React.forwardRef(CheckboxComponent);
const RadioField = React.forwardRef(RadioFieldComponent);
const DatePicker = React.forwardRef(DatePickerComponent);
const AddressField = React.forwardRef(AutoAddressFieldComponent);

export {
	TextField,
	PasswordField,
	AddressField,
	Select,
	Checkbox,
	RadioField,
	DatePicker,
	PhoneField,
	ErrorLabel,
};
