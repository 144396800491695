import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
	const { i18n } = useTranslation();

	const languageOptions = [
		{
			name: "English",
			value: "en",
		},
		{
			name: "Français",
			value: "fr",
		},
	];

	return (
		<div>
			<select
				value={i18n.language}
				onChange={(e) => i18n.changeLanguage(e.target.value)}
				className={`px-3 pr-7 py-2 rounded-md w-full bg-gray-100 border-1 border-gray-300
          focus:border-primary-color focus:ring-0 focus:outline-none focus:shadow-input`}
			>
				{languageOptions.map(({ name, value }) => (
					<option key={value} value={value}>
						{name}
					</option>
				))}
			</select>
		</div>
	);
};

export default LanguageSwitcher;
