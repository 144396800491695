import { useContext, useState } from "react";
import {
	MakeParams,
	MakeResponse,
	ModelResponse,
	VehicleModelParams,
} from "types/Settings";
import { ApplicationManagementContext } from "context/ApplicationManagementContext";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "components/breadcrumbs";
import { BreadCrumbsProps } from "types";
import MakesToolbar from "./MakesAndModelsToolbar";
import MakesAccordion from "./MakesAccordion";
import ModelsAccordion from "./ModelsAccordion";
import AddMake from "./AddMake";
import AddModel from "./AddModel";

const MakeAndModelsSettings = ({
	isLoading,
	makeData,
	modelData,
	handleAddUpdateMake,
	handleAddUpdateModel,
}: {
	isLoading: boolean;
	makeData: MakeResponse[];
	modelData: ModelResponse[];
	handleAddUpdateModel: (data: VehicleModelParams) => Promise<boolean>;
	handleAddUpdateMake: (data: MakeParams) => Promise<boolean>;
}) => {
	const { t } = useTranslation(["navigation"]);

	const [showMakeModal, setShowMakeModal] = useState(false);
	const [showModelModal, setShowModelModal] = useState(false);

	const {
		handleSelectedMake,
		selectedMake,
		selectedModel,
		handleSelectedModel,
	} = useContext(ApplicationManagementContext);

	const handleShowMakeModal = (data?: MakeParams) => {
		if (data) {
			handleSelectedMake(data);
		} else {
			handleSelectedMake(null);
		}

		setShowMakeModal(!showMakeModal);
	};

	const handleShowModelModal = (data?: VehicleModelParams) => {
		if (data) {
			handleSelectedModel(data);
		} else {
			handleSelectedModel(null);
		}

		setShowModelModal(!showModelModal);
	};

	const handleVehicleMakeSubmit = async (props: MakeParams) => {
		const res = await handleAddUpdateMake(props);
		if (res) {
			setShowMakeModal(false);
			handleSelectedMake(null);
		}
	};

	const handleVehicleModelSubmit = async (props: VehicleModelParams) => {
		const res = await handleAddUpdateModel(props);
		if (res) {
			setShowModelModal(false);
			handleSelectedModel(null);
		}
	};

	const routes: BreadCrumbsProps[] = [
		{
			path: "/dashboard",
			breadcrumbName: t("home"),
		},
		{
			path: "/app-settings/roles",
			breadcrumbName: t("make and models"),
		},
	];

	return (
		<>
			<Breadcrumbs breadCrumb={routes} />

			<MakesToolbar
				handleAddMakeModal={handleShowMakeModal}
				handleAddModelModal={handleShowModelModal}
			/>

			<MakesAccordion
				handleEditMake={handleShowMakeModal}
				isLoading={isLoading}
				makeData={makeData}
			/>

			<ModelsAccordion
				handleEditModel={handleShowModelModal}
				isLoading={isLoading}
				modelData={modelData}
			/>

			<AddMake
				selectedMake={selectedMake}
				showModal={showMakeModal}
				handleShowMakeModal={handleShowMakeModal}
				onSubmitForm={handleVehicleMakeSubmit}
				isLoading={isLoading}
				handleSelectedMake={handleSelectedMake}
			/>

			<AddModel
				vehicleMakes={makeData}
				selectedModel={selectedModel}
				onSubmitForm={handleVehicleModelSubmit}
				handleShowModelModal={handleShowModelModal}
				showModal={showModelModal}
				isLoading={isLoading}
				handleSelectedModel={handleSelectedModel}
			/>
		</>
	);
};

export default MakeAndModelsSettings;
