import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import classNames from "classnames";
import MoreOptionsMenu from "components/common/MoreOptionsMenu";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiSticker } from "react-icons/bi";
import { BsCreditCard } from "react-icons/bs";
import { GiSmartphone } from "react-icons/gi";
import { EVCardDataType } from "types/EVCards";
import { searchInString } from "utils";
import EVCardToolbar from "./EVCardToolbar";

const EVCardList = ({
	evCardList,
	handleShowEVCardModal,
	pageTotal,
	handlePageChange,
}: {
	evCardList: EVCardDataType[];
	pageTotal: number;
	handlePageChange: (page: number, pageSize: number) => void;
	handleShowEVCardModal: (data?: EVCardDataType) => void;
}) => {
	const [tableData, setTableData] = useState<EVCardDataType[]>([]);

	const cardTypeFilterOptions = [
		{
			label: "Card",
			value: "CARD",
		},
		{
			label: "Phone",
			value: "PHONE",
		},
		{
			label: "Sticker",
			value: "STICKER",
		},
	];

	const cardStatusFilterOptions = [
		{
			label: "Enabled",
			value: true,
		},
		{
			label: "Disabled",
			value: false,
		},
	];

	const [filterByCardType, setFilterByCardType] = useState<string[]>(
		cardTypeFilterOptions.map((option) => option.value)
	);
	const [filterByCardStatus, setFilterByCardStatus] = useState<boolean[]>(
		cardStatusFilterOptions.map((option) => option.value)
	);

	const { t } = useTranslation(["evCards"]);

	const columns: ColumnsType<EVCardDataType> = [
		{
			title: t("evCardColumns.evCardType"),
			dataIndex: "evCardType",
			key: "evCardType",
			width: 120,
			sorter: (a, b) => a.evCardType.localeCompare(b.evCardType),
			render: (evCardType: string) => {
				let typeIcon;
				if (evCardType === "CARD") {
					typeIcon = <BsCreditCard size={23} />;
				} else if (evCardType === "STICKER") {
					typeIcon = <BiSticker size={24} />;
				} else {
					typeIcon = <GiSmartphone size={28} />;
				}
				return <div className="flex justify-center">{typeIcon}</div>;
			},
		},
		{
			title: t("evCardColumns.cardNumber"),
			dataIndex: "cardNumber",
			key: "cardNumber",
			sorter: (a, b) => a.cardNumber.localeCompare(b.cardNumber),
			render: (cardNumber: string, { evCardType, uuid }) =>
				evCardType !== "STICKER" ? (
					<a
						className="text-primary-color uppercase"
						href={`/app-settings/ev-cards/transactions/${uuid}`}
					>
						{cardNumber}
					</a>
				) : (
					<span className="uppercase">{cardNumber}</span>
				),
		},
		{
			title: t("evCardColumns.vehicleUuid"),
			dataIndex: "vehicleUuid",
			key: "vehicleUuid",
		},
		{
			title: t("evCardColumns.balance"),
			dataIndex: "balance",
			key: "balance",
			render: (balance, { currency }) => (
				<div className="place-content-center">
					{currency} {balance}
				</div>
			),
			sorter: (a, b) => (a.balance - b.balance > 0 ? 1 : -1),
		},
		{
			title: t("evCardColumns.status"),
			dataIndex: "enabled",
			key: "enabled",
			width: 190,
			render: (isEnabled: boolean) => (
				<p
					className={classNames(
						isEnabled ? "bg-primary-color" : "bg-gray-300",
						"rounded-full",
						"w-24",
						"py-2",
						"text-xs",
						"text-center",
						"text-white",
						"m-0"
					)}
				>
					{t(isEnabled ? "enabled" : "disabled", { ns: "common" })}
				</p>
			),
		},
		{
			title: t("action", { ns: "common" }),
			key: "operation",
			fixed: "right",
			align: "center",
			width: 100,
			render: (item) => (
				<MoreOptionsMenu menuList={List} data={item} size="sm" />
			),
		},
	];

	const List = [
		{
			label: t("edit", { ns: "common" }),
			onClick: (data: EVCardDataType) => {
				handleShowEVCardModal(data);
			},
		},
	];

	useEffect(() => {
		setTableData(evCardList);
	}, [evCardList]);

	const handleSearch = (searchTerm: string) => {
		const result = evCardList.filter(
			(data: EVCardDataType) =>
				searchInString(data.cardNumber, searchTerm) ||
				searchInString(data.vehicleUuid, searchTerm)
		);
		setTableData(result);
		if (!searchTerm) {
			setTableData(evCardList);
		}
	};

	const handleFilter = (
		selectedCardType: string[],
		selectedCardStatus: boolean[]
	) => {
		const result = evCardList.filter(
			(data: EVCardDataType) =>
				selectedCardType.includes(data.evCardType) &&
				selectedCardStatus.includes(data.enabled)
		);
		setTableData(result);
	};

	useEffect(
		() => handleFilter(filterByCardType, filterByCardStatus),
		[filterByCardType, filterByCardStatus]
	);

	return (
		<div>
			<EVCardToolbar
				handleSubmit={handleSearch}
				handleShowEVCardModal={handleShowEVCardModal}
				cardTypeFilterOptions={cardTypeFilterOptions}
				cardStatusFilterOptions={cardStatusFilterOptions}
				filterByCardStatus={filterByCardStatus}
				filterByCardType={filterByCardType}
				setFilterByCardStatus={setFilterByCardStatus}
				setFilterByCardType={setFilterByCardType}
			/>
			<Table
				pagination={{
					total: pageTotal,
					onChange: (page, pageSize) => handlePageChange(page, pageSize),
					defaultPageSize: 10,
					showSizeChanger: true,
				}}
				columns={columns}
				dataSource={tableData}
			/>
		</div>
	);
};

export default EVCardList;
