import React, { useState } from "react";
import {
	ChargingStationResponse,
	SiteOwnerResponse,
	SiteResponse,
} from "types/Stations";
import { OrganizationResponse } from "types/UserManagement";

const SiteContext = React.createContext<{
	selectedSiteOwner: SiteOwnerResponse | null;
	selectedOrganization: OrganizationResponse | null;
	selectedSite: SiteResponse | null;
	selectedChargingStation: ChargingStationResponse | null;
	handleSelectedSiteOwner: (data: SiteOwnerResponse | null) => void;
	handleSelectedSite: (data: SiteResponse | null) => void;
	handleSelectedChargingStation: (data: ChargingStationResponse | null) => void;
	handleSelectedOrganization: (data: OrganizationResponse | null) => void;
}>({
	selectedSiteOwner: null,
	selectedOrganization: null,
	selectedSite: null,
	selectedChargingStation: null,
	handleSelectedSiteOwner: () => {},
	handleSelectedSite: () => {},
	handleSelectedChargingStation: () => {},
	handleSelectedOrganization: () => {},
});

const SiteContextProvider = (props: {
	children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}) => {
	const [selectedSiteOwner, setSelectedSiteOwner] =
		useState<SiteOwnerResponse | null>(null);
	const [selectedOrganization, setSelectedOrganization] =
		useState<OrganizationResponse | null>(null);
	const [selectedSite, setSelectedSite] = useState<SiteResponse | null>(null);
	const [selectedChargingStation, setSelectedChargingStation] =
		useState<ChargingStationResponse | null>(null);

	const handleSelectedSiteOwner = (data: SiteOwnerResponse | null) => {
		setSelectedSiteOwner(data);
	};

	const handleSelectedSite = (data: SiteResponse | null) => {
		setSelectedSite(data);
	};

	const handleSelectedChargingStation = (
		data: ChargingStationResponse | null
	) => {
		setSelectedChargingStation(data);
	};

	const handleSelectedOrganization = (data: OrganizationResponse | null) => {
		setSelectedOrganization(data);
	};

	const value = React.useMemo(
		() => ({
			selectedSiteOwner,
			selectedSite,
			selectedChargingStation,
			selectedOrganization,
			handleSelectedSiteOwner,
			handleSelectedSite,
			handleSelectedChargingStation,
			handleSelectedOrganization,
		}),
		[
			selectedSiteOwner,
			selectedOrganization,
			selectedSite,
			selectedChargingStation,
		]
	);

	return (
		<SiteContext.Provider value={value}>{props.children}</SiteContext.Provider>
	);
};

export { SiteContext, SiteContextProvider };
