import { Switch } from "@headlessui/react";
import {
	AddressField,
	SubmitButton,
	TextField,
} from "components/form/InputField";
import Modal from "components/modals/Modal";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { SiteParams, SiteResponse } from "types/Stations";
import { testCanadianPostalCode } from "utils";

const CreateEditSites = ({
	handleShowModal,
	showModal,
	selectedSite,
	isLoading,
	onSubmitForm,
}: {
	handleShowModal: () => void;
	showModal: boolean;
	isLoading: boolean;
	onSubmitForm: (params: SiteParams) => void;
	selectedSite: SiteResponse | null;
}) => {
	const [lngLat, setLngLat] = useState<{
		locationLattitude: string;
		locationLongitude: string;
	}>({ locationLattitude: "", locationLongitude: "" });

	const {
		address,
		name,
		enabled,
		description,
		locationLattitude,
		locationLongitude,
	} = selectedSite || {};
	const { city, country, postalCode, region, addressLine1 } = address || {};

	const [isEnabled, setIsEnabled] = useState(!enabled);

	const form = useForm<SiteParams>({
		defaultValues: {
			addressLine1: addressLine1 || "",
			name: name || "",
			description: description || "",
			enabled: !enabled,
			region: region || "",
			postalCode: postalCode || "",
			country: country || "",
			city: city || "",
		},
	});

	const {
		handleSubmit,
		register,
		reset,
		setError: setFormError,
		setValue,
	} = form;

	useEffect(() => {
		reset({
			addressLine1: addressLine1 || "",
			name: name || "",
			description: description || "",
			enabled,
			region: region || "",
			postalCode: postalCode || "",
			country: country || "",
			city: city || "",
			locationLattitude: locationLattitude || "",
			locationLongitude: locationLongitude || "",
		});
	}, [selectedSite]);

	const onSubmit = async (props: any) => {
		let params = props;

		params = {
			...props,
			enabled,
			addressLine2: "",
			locationLattitude: lngLat.locationLattitude,
			locationLongitude: lngLat.locationLongitude,
		};

		onSubmitForm(params);
	};

	return (
		<Modal
			title={selectedSite ? "Update Site " : "Create Site"}
			show={showModal}
			onClose={handleShowModal}
		>
			<FormProvider {...form}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="mb-2">
						<TextField
							className="mb-2"
							name="name"
							label="Name"
							required
							ref={register({ required: true })}
						/>
					</div>
					<div className="mb-2">
						<TextField
							className="mb-2"
							name="description"
							label="Description"
							required
							ref={register({ required: true })}
						/>
					</div>

					<div className="mt-2">
						<AddressField
							data-testid="address"
							className="mb-2"
							name="addressLine1"
							label="Address"
							required
							getLocationData={(data: any) => {
								const postcode = data.formatted_address.split(",")[2].trim();
								const isValidPostCode = testCanadianPostalCode(
									postcode.slice(3)
								);

								if (!isValidPostCode) {
									const err = {
										message: "Invalid address selected",
										type: "manual",
									};

									setFormError("addressLine1", {
										type: "manual",
										message: "Select a full address",
									});
									setFormError("city", err);
									setFormError("region", err);
									setFormError("country", err);
									setFormError("postalCode", err);
									return;
								}

								const addressLine = data.formatted_address.split(",")[0].trim();
								const cityData = data.formatted_address.split(",")[1].trim();
								const province = data.formatted_address
									.split(",")[2]
									.trim()
									.split(" ")[0];
								const countryData = data.formatted_address.split(",")[3].trim();
								const lat = data.geometry.location.lat().toString();
								const lng = data.geometry.location.lng().toString();

								setValue("city", cityData);
								setValue("region", province);
								setValue("country", countryData);
								setValue("postalCode", postcode.slice(3));
								setValue("addressLine1", addressLine);
								setLngLat({
									locationLattitude: lat,
									locationLongitude: lng,
								});
							}}
							ref={register({ required: true })}
						/>
					</div>

					<div className="mt-2">
						<TextField
							data-testid="city"
							className="mb-2"
							name="city"
							label="City"
							required
							disabled
							ref={register({ required: true })}
						/>
					</div>
					<div className="mt-2">
						<TextField
							data-testid="signin-email"
							className="mb-2"
							name="region"
							label="Province"
							required
							disabled
							ref={register({ required: true })}
						/>
					</div>
					<div className="mt-2">
						<TextField
							data-testid="postalCode"
							className="mb-2"
							name="postalCode"
							label="Postal Code"
							required
							disabled
							ref={register({ required: true })}
						/>
					</div>

					<div className="mt-2">
						<TextField
							data-testid="country"
							className="mb-2"
							name="country"
							label="Country"
							required
							disabled
							ref={register({ required: true })}
						/>
					</div>

					{selectedSite && (
						<Switch.Group>
							<div className="mt-4  flex items-center">
								<Switch.Label
									passive
									className="mr-10 font-medium text-gray-700"
								>
									Enable Site
								</Switch.Label>
								<Switch
									checked={isEnabled}
									onChange={() => setIsEnabled(!isEnabled)}
									className={`${
										isEnabled ? "bg-primary-color" : "bg-gray-200"
									} relative inline-flex h-6 w-12 items-center rounded-full`}
								>
									<span
										className={`${
											!isEnabled ? "translate-x-6" : "translate-x-1"
										} inline-block h-4 w-4 transform rounded-full bg-white`}
									/>
								</Switch>
							</div>
						</Switch.Group>
					)}

					<SubmitButton
						type="submit"
						loading={isLoading}
						label={selectedSite ? "Update" : "Done"}
						className="mt-8 flex m-auto my-5 text-center"
					/>
				</form>
			</FormProvider>
		</Modal>
	);
};
export default CreateEditSites;
