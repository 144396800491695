import { Tab } from "@headlessui/react";
import { ReactElement } from "react";
import classnames from "classnames";

const TabMenu = ({
	tabs,
	defaultIndex = 0,
	onChange,
	selectedIndex,
}: {
	tabs: {
		title: string;
		content: ReactElement;
	}[];
	defaultIndex?: number;
	selectedIndex?: number;
	onChange?: (index: number) => void;
}) => (
	<Tab.Group
		onChange={onChange}
		selectedIndex={selectedIndex}
		defaultIndex={defaultIndex}
	>
		<Tab.List className="flex space-x-1 bg-white rounded-t-md overflow-y-scroll">
			{tabs.map(({ title }) => (
				<Tab
					key={title}
					className={({ selected }) =>
						classnames(
							selected
								? "inline-block p-4 text-primary-color font-semibold rounded-t-lg border-b-2 border-primary-color active"
								: "inline-block p-4 rounded-t-lg border-b-2 border-transparent text-gray-600 hover:text-primary-color hover:border-primary-color"
						)
					}
				>
					{title}
				</Tab>
			))}
		</Tab.List>

		<hr className="border-gray-300" />

		<Tab.Panels>
			{tabs.map(({ content }, idx) => (
				<Tab.Panel
					key={idx}
					className={classnames("rounded-b-md bg-white p-5")}
				>
					{content}
				</Tab.Panel>
			))}
		</Tab.Panels>
	</Tab.Group>
);

export default TabMenu;
