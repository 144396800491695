import { Suspense, useContext } from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthContext, AuthContextProvider } from "context/AuthContext";
import Dashboard from "pages/Dashboard";
import LoginPage from "pages/Auth/Login";
import Registration from "pages/Auth/Registration";
import ForgotPassword from "pages/Auth/ForgotPassword";
import Stations from "pages/Stations";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import CodeVerification from "pages/Auth/CodeVerification";
import Permissions from "pages/ApplicationManagement/Permissions";
import Roles from "pages/ApplicationManagement/Roles";
import Personal from "pages/Settings/Personal";
import ResetPassword from "pages/Auth/ResetPassword";
import SiteOwnersPage from "pages/UsersManagement/SiteOwners";
import SiteOwnerPage from "pages/UsersManagement/SiteOwner";
import { AlertContextProvider } from "context/AlertContext";
import Vehicles from "pages/Settings/Vehicles";
import MakesAndModels from "pages/ApplicationManagement/MakesAndModels";
import { SiteContextProvider } from "context/SitesContext";
import Loading from "assets/svg/assets/Loading";
import { SettingsContextProvider } from "context/SettingsContext";
import SitePage from "pages/UsersManagement/Site";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import config from "config";
import AssignPermissionsPage from "pages/ApplicationManagement/AssignPermissions";
import useRouteAuth from "hooks/useRouteAuth";
import Support from "pages/Support/Support";
import CreateTicketPage from "pages/Support/CreateTicketPage";
import EVCardsPage from "pages/ApplicationSettings/EVCards/EVCardsPage";
import { EVCardContextProvider } from "context/EVCardContext";
import EVCardsTransactionPage from "pages/ApplicationSettings/EVCards/EVCardsTransactionPage";
import OrganisationTypeManagement from "pages/ApplicationManagement/OrganisationTypeManagement";
import { ApplicationManagementContextProvider } from "context/ApplicationManagementContext";
import TicketDetailPage from "pages/Support/TicketDetailPage";
import OrganizationsPage from "pages/UsersManagement/Organizations";
import UsersPage from "pages/UsersManagement/Users";
import { UserContextProvider } from "context/UserContext";
import UserDetailPage from "pages/UserDetail/UserDetailPage";

LogRocket.getSessionURL((sessionURL: string) => {
	Sentry.configureScope((scope) => {
		scope.setExtra("sessionURL", sessionURL);
	});
});

if (config.env === "production") {
	// Init logRocket right before apollo link
	LogRocket.init(config.logRocketId, {
		network: {
			isEnabled: false,
		},
		release: config.env,
	});

	setupLogRocketReact(LogRocket);

	Sentry.init({
		dsn: config.sentryDsn,
		integrations: [new BrowserTracing()],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
	});
}

// Create a client
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			refetchOnReconnect: false,
		},
	},
});

const Routes = () => (
	<Suspense
		fallback={
			<div className="h-[100vh] flex justify-center items-center">
				<Loading />
			</div>
		}
	>
		<BrowserRouter>
			<AuthContextProvider>
				<AlertContextProvider>
					<SiteContextProvider>
						<SettingsContextProvider>
							<ApplicationManagementContextProvider>
								<UserContextProvider>
									<EVCardContextProvider>
										<QueryClientProvider client={queryClient}>
											<Switch>
												<RoutePublic exact path="/login" render={LoginPage} />
												<RoutePublic
													exact
													path="/signup"
													component={Registration}
												/>
												<RoutePublic
													exact
													path="/forgot_password"
													component={ForgotPassword}
												/>
												<RoutePublic
													exact
													path="/verification"
													component={CodeVerification}
												/>
												<RoutePublic
													exact
													path="/reset_password"
													component={ResetPassword}
												/>
												<PrivateRoute
													exact
													path="/settings/personal"
													component={Personal}
												/>
												<PrivateRoute
													exact
													path="/settings/vehicles"
													component={Vehicles}
												/>
												<PrivateRoute
													exact
													path="/app-settings/permissions"
													component={Permissions}
												/>
												<PrivateRoute
													exact
													path="/app-settings/roles"
													component={Roles}
												/>
												<PrivateRoute
													exact
													path="/app-settings/roles/:uuid"
													component={AssignPermissionsPage}
												/>
												<PrivateRoute
													exact
													path="/app-settings/organization-types"
													component={OrganisationTypeManagement}
												/>
												<PrivateRoute
													exact
													path="/site-owners"
													component={SiteOwnersPage}
												/>
												<PrivateRoute
													exact
													path="/site-owners/site-owner/:id"
													component={SiteOwnerPage}
												/>
												<PrivateRoute
													exact
													path="/organizations"
													component={OrganizationsPage}
												/>
												<PrivateRoute
													exact
													path="/users"
													component={UsersPage}
												/>
												<PrivateRoute
													exact
													path="/site-owners/site-owner/:siteOwnerUUID/site/:siteUUID"
													component={SitePage}
												/>
												<PrivateRoute
													exact
													path="/app-settings/make-and-models"
													component={MakesAndModels}
												/>
												<PrivateRoute
													exact
													path="/dashboard"
													component={Dashboard}
												/>
												<PrivateRoute
													exact
													path="/support"
													component={Support}
												/>
												<PrivateRoute
													exact
													path="/support/ticket/:ticketId"
													component={TicketDetailPage}
												/>
												<PrivateRoute
													exact
													path="/support/create-ticket"
													component={CreateTicketPage}
												/>
												<PrivateRoute
													exact
													path="/settings/roles"
													component={Roles}
												/>
												<PrivateRoute
													exact
													path="/app-settings/ev-cards"
													component={EVCardsPage}
												/>
												<PrivateRoute
													exact
													path="/users/:id"
													component={UserDetailPage}
												/>
												<PrivateRoute
													exact
													path="/app-settings/ev-cards/transactions/:cardUuid"
													component={EVCardsTransactionPage}
												/>
												<Route exact path="/" component={Stations} />
												<Route exact path="/not-authorized">
													<NotAuthorized />
												</Route>
												<Route>
													<NotFoundFallback />
												</Route>
											</Switch>
											<ReactQueryDevtools initialIsOpen={false} />
										</QueryClientProvider>
									</EVCardContextProvider>
								</UserContextProvider>
							</ApplicationManagementContextProvider>
						</SettingsContextProvider>
					</SiteContextProvider>
				</AlertContextProvider>
			</AuthContextProvider>
		</BrowserRouter>
	</Suspense>
);

export default Routes;

const PrivateRoute = ({ component: Component, path, location }: any) => {
	const { isSignedIn, user } = useContext(AuthContext);
	const { permissionNames } = user || {};
	const { hasAccess } = useRouteAuth({ permissionNames, path });

	if (!hasAccess) return <Redirect to="/not-authorized" />;

	return (
		<Route
			render={(routeProps) => {
				if (isSignedIn) return <Component {...routeProps} />;

				return (
					<Redirect
						to={`/login?redirect=${location.pathname}${location.search || ""}`}
					/>
				);
			}}
			path={path}
		/>
	);
};

const RoutePublic = ({
	component: Component,
	location,
	...rest
}: {
	[x: string]: any;
}) => {
	const { isSignedIn, token } = useContext(AuthContext);

	let redirect = "/dashboard";
	if (location!.search.startsWith("?redirect=")) {
		redirect = location!.search.replace("?redirect=", "");
	}

	if (isSignedIn && token) {
		return (
			<Redirect
				to={{
					pathname: redirect,
					state: { from: location },
				}}
			/>
		);
	}

	return <Route render={(props) => <Component {...props} />} {...rest} />;
};

const NotFoundFallback = () => <div>Not Found</div>;
const NotAuthorized = () => <div>Not Authorized</div>;
