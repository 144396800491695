import { MakeParams, VehicleModelParams, VehicleParams } from "types/Settings";
import apiRequestHandler from "utils/apiRequestHandler";

const fetchVehicleMakes = (): any => {
	const res = apiRequestHandler({
		url: "/vehicle-makes",
		data: {},
		method: "get",
	});
	return res;
};

const addVehicleMakes = (params: MakeParams): any => {
	const res = apiRequestHandler({
		url: "/vehicle-makes",
		data: params,
		method: "post",
	});
	return res;
};

const findVehicleMake = (uuid: string): any => {
	const res = apiRequestHandler({
		url: `/vehicle-makes/${uuid}`,
		data: {},
		method: "get",
	});
	return res;
};

const updateVehicleMake = ({ uuid, ...rest }: any): any => {
	const res = apiRequestHandler({
		url: `/vehicle-makes/${uuid}`,
		data: rest,
		method: "put",
	});
	return res;
};

const fetchVehicleModels = (): any => {
	const res = apiRequestHandler({
		url: "/vehicle-models",
		data: {},
		method: "get",
	});
	return res;
};

const addVehicleModels = (params: VehicleModelParams): any => {
	const res = apiRequestHandler({
		url: "/vehicle-models",
		data: params,
		method: "post",
	});
	return res;
};

const findVehicleModel = (uuid: string): any => {
	const res = apiRequestHandler({
		url: `/vehicle-models/${uuid}`,
		data: {},
		method: "get",
	});
	return res;
};

const updateVehicleModel = ({ uuid, ...rest }: any): any => {
	const res = apiRequestHandler({
		url: `/vehicle-models/${uuid}`,
		data: rest,
		method: "put",
	});
	return res;
};

const fetchVehicles = (): any => {
	const res = apiRequestHandler({
		url: "/vehicles",
		data: {},
		method: "get",
	});
	return res;
};

const addVehicle = (params: VehicleParams): any => {
	const res = apiRequestHandler({
		url: "/vehicles",
		data: params,
		method: "post",
	});
	return res;
};

const findVehicle = (uuid: string): any => {
	const res = apiRequestHandler({
		url: `/vehicles/${uuid}`,
		data: {},
		method: "get",
	});
	return res;
};

const updateVehicle = ({ uuid, ...rest }: VehicleParams): any => {
	const res = apiRequestHandler({
		url: `/vehicles/${uuid}`,
		data: rest,
		method: "put",
	});
	return res;
};

export {
	fetchVehicleMakes,
	addVehicleMakes,
	findVehicleMake,
	updateVehicleMake,
	fetchVehicleModels,
	addVehicleModels,
	findVehicleModel,
	updateVehicleModel,
	fetchVehicles,
	addVehicle,
	findVehicle,
	updateVehicle,
};
