import Loading from "assets/svg/assets/Loading";
import { useTranslation } from "react-i18next";
import { UserInfo } from "types/Auth";
import InitialsCircles from "components/navbars/InitialsCircles";

const UserDataDisplay = ({
	user,
	isLoading,
}: {
	user: UserInfo | null | undefined;
	isLoading: boolean;
}) => {
	const { t } = useTranslation("common");
	const {
		createdAt,
		lastName,
		firstName,
		email,
		callingCode,
		phoneNumber,
		lastLoggedIn,
		userType,
		organization,
		roleNames,
	} = user || {};

	return !isLoading ? (
		<div className="flex flex-col">
			<div className="flex flex-col xl:flex-row gap-4">
				<div
					className="flex flex-1 flex-col xl:flex-row items-center rounded-2xl p-6 gap-8 justify-around"
					style={{ backgroundColor: "white", border: "1px solid #E5E7EB" }}
				>
					<InitialsCircles.XLarge firstName={firstName} lastName={lastName} />
					<div className="grid grid-cols-2 content-center gap-6">
						<span className="font-bold">{t("userDetail.firstName")}:</span>
						<span>{firstName}</span>
						<span className="font-bold">{t("userDetail.lastName")}:</span>
						<span>{lastName}</span>
						<span className="font-bold">{t("userDetail.email")}</span>
						<span className="break-words">{email}</span>
						<span className="font-bold">{t("userDetail.phoneNumber")}:</span>
						<span>{`${callingCode || ""} (${phoneNumber?.slice(
							0,
							3
						)}) ${phoneNumber?.slice(3, 6)}-${phoneNumber?.slice(
							6,
							11
						)}`}</span>
					</div>
				</div>
				<div
					className="flex flex-col xl:flex-row items-center rounded-2xl p-6"
					style={{ backgroundColor: "white", border: "1px solid #E5E7EB" }}
				>
					<div className="grid grid-cols-2 content-center gap-6">
						<span className="font-bold">{t("userDetail.roles")}:</span>
						<span>{roleNames?.reduce((prev, curr) => `${prev}, ${curr}`)}</span>
						<span className="font-bold">{t("userDetail.organization")}:</span>
						<span>{organization?.name}</span>
						<span className="font-bold">{t("userDetail.userType")}:</span>
						<span>{userType}</span>
						<span className="font-bold">{t("userDetail.createdAt")}:</span>
						<span>{createdAt}</span>
						<span className="font-bold">{t("userDetail.lastLoggedIn")}:</span>
						<span>{lastLoggedIn}</span>
					</div>
				</div>
			</div>
		</div>
	) : (
		<div className="w-full flex m-auto">
			<Loading />
		</div>
	);
};

export default UserDataDisplay;
