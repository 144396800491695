import { Popover, PopoverProps } from "antd";
import classNames from "classnames";
import { ReactNode } from "react";
import ReactTooltip from "react-tooltip";

const PopoverMenu = ({
	children,
	label,
	icon,
	content,
	buttonClass,
	showArrow,
	toolTipText,
	showToolTip,
	noDecoration,
	...rest
}: {
	children?: ReactNode;
	label?: string;
	icon?: ReactNode;
	showToolTip?: boolean;
	toolTipText?: string;
	buttonClass?: string;
	noDecoration?: boolean;
} & PopoverProps) => (
	<Popover
		showArrow={showArrow}
		placement="bottom"
		content={content}
		trigger="click"
		{...rest}
	>
		<button
			type="button"
			data-tip={toolTipText}
			className={classNames(
				noDecoration
					? ""
					: "focus:ring-4 focus:outline-none border border-gray-200 font-medium text-sm text-center inline-flex items-center",
				buttonClass
			)}
		>
			{label}
			{icon}
			{children}
		</button>

		{showToolTip && <ReactTooltip type="dark" />}
	</Popover>
);

export default PopoverMenu;
