import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import Accordion from "components/common/Accordion";
import SearchInput from "components/common/SearchInput";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PermissionsDataType } from "types/Settings";

const UserPermissionsTable = ({
	permissionNames,
	isLoading,
}: {
	permissionNames: string[];
	isLoading: boolean;
}) => {
	const { t } = useTranslation(["common"]);
	const [tableData, setTableData] = useState<PermissionsDataType[]>([]);

	const getTableData = (list: string[]) =>
		list.map((permissionName) => ({
			key: permissionName,
			name: permissionName,
			description: permissionName,
		}));

	useEffect(() => {
		setTableData(getTableData(permissionNames));
	}, [permissionNames]);

	const handleSearch = (searchTerm: string) => {
		const result = permissionNames.filter(
			(data: string) =>
				data.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
		);
		setTableData(getTableData(result));
		if (!searchTerm) {
			setTableData(getTableData(permissionNames));
		}
	};

	const columns: ColumnsType<PermissionsDataType> = [
		{
			dataIndex: "name",
			title: t("name"),
			key: "name",
			width: "15%",
			sorter: (a, b) => a.name.localeCompare(b.name),
		},
	];

	return (
		<Accordion title={t("userDetail.permissions")}>
			<div className="mb-4">
				<SearchInput
					handleSubmit={handleSearch}
					placeholder={t("userDetail.searchPermission", { ns: "common" })}
				/>
			</div>
			<Table
				loading={isLoading}
				columns={columns}
				dataSource={tableData}
				scroll={{ y: 250 }}
				pagination={{
					pageSizeOptions: ["10", "20", "50", "100"],
					showSizeChanger: true,
				}}
			/>
		</Accordion>
	);
};

export default UserPermissionsTable;
