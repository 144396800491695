export const dates: number[] = Array.from(Array(31), (i, j) => j + 1);
export const months: { [key: string]: number } = {
	Jan: 1,
	Feb: 2,
	Mar: 3,
	Apr: 4,
	May: 5,
	Jun: 6,
	Jul: 7,
	Aug: 8,
	Sep: 9,
	Oct: 10,
	Nov: 11,
	Dec: 12,
};

export const roleTypes = [
	{ name: "BACK OFFICE", value: "BACK_OFFICE" },
	{ name: "SITE OWNER", value: "SITE_OWNER" },
	{ name: "CUSTOMER", value: "CUSTOMER" },
];

export const STATION_MANAGEMENT = ["sites", "charging-stations"];

export const month = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];
export const weekday = [
	"Sunday",
	"Monday",
	"Tuesday",
	"Wednesday",
	"Thursday",
	"Friday",
	"Saturday",
];

export const pathRolePermissions: Map<string, string> = new Map<string, string>(
	[
		["/user-management", "ROLE_BACKOFFICE"],
		["/site-owners", "ROLE_CAN_VIEW_SITE_OWNERS"],
		["/app-settings", "ROLE_BACKOFFICE"],
		["/app-settings/roles", "ROLE_CAN_VIEW_ROLE"],
		["/app-settings/permissions", "ROLE_CAN_VIEW_PERMISSIONS"],
		["/app-settings/make-and-models", "ROLE_CAN_VIEW_MAKE_AND_MODEL"],
		["/app-settings/ev-cards", "ROLE_CAN_VIEW_EV_CARDS"],
		["/app-settings/organization-types", "ROLE_CAN_VIEW_ORGANIZATION"],
		["/organization", "ROLE_CAN_VIEW_ORGANIZATION"],
		["/users", "ROLE_CAN_VIEW_BACKOFFICE_USER"],
		["/settings/vehicles", "ROLE_CAN_VIEW_VEHICLES"],
	]
);

export const evCardTypeOptions = [
	{ name: "CARD", value: "CARD" },
	{ name: "STICKER", value: "STICKER" },
	{ name: "PHONE", value: "PHONE" },
];
