import SettingsLayout from "Layouts/SettingsLayout";
import { useContext } from "react";
import { AuthContext } from "context/AuthContext";
import { useTranslation } from "react-i18next";
import { ChangePasswordParams } from "types/Auth";
import UserNameForm from "./UserNameForm";
import UserPasswordForm from "./UserPasswordForm";

const PersonalSettings = ({
	isLoading,
	handleUpdatePassword,
}: {
	isLoading: boolean;
	handleUpdatePassword: (props: ChangePasswordParams) => Promise<boolean>;
}) => {
	const { user } = useContext(AuthContext);
	const { t } = useTranslation(["settings"]);

	return (
		<SettingsLayout>
			<div>
				<h1 className="mb-3 text-3xl font-medium mt-5">
					{t("personal.title")}
				</h1>
			</div>

			{user && <UserNameForm user={user} />}
			<div className="border-b border-gray-300" />
			<UserPasswordForm
				isLoading={isLoading}
				handleUpdatePassword={handleUpdatePassword}
			/>
		</SettingsLayout>
	);
};

export default PersonalSettings;
