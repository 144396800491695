import {
	addVehicle,
	fetchVehicleMakes,
	fetchVehicleModels,
	fetchVehicles,
	updateVehicle,
} from "api/settings";
import VehicleSettings from "components/Settings/vehicles";
import {
	MakeResponse,
	ModelResponse,
	VehicleParams,
	VehicleResponse,
	VehiclesResponse,
} from "types/Settings";
import { useMutation, useQuery, useQueryClient } from "react-query";
import _ from "lodash";
import { ErrorResponse } from "types";
import { useContext } from "react";
import { SettingsContext } from "context/SettingsContext";
import { AlertContext } from "context/AlertContext";
import { useTranslation } from "react-i18next";

const Vehicles = () => {
	const queryClient = useQueryClient();
	const { t } = useTranslation(["constants"]);
	const { selectedVehicle } = useContext(SettingsContext);
	const { handleShowAlert } = useContext(AlertContext);

	/* ==============================================================================
		Fetch Queries
	============================================================================== */

	/* Fetch Vehicle Makes and sort it in the alphabetical order */
	const { isLoading: makesLoading, data: makesData } = useQuery<MakeResponse[]>(
		"makes",
		() => fetchVehicleMakes()
	);
	const sortedMakesData = makesData?.sort((a, b) =>
		a.name.localeCompare(b.name)
	);

	/* Fetch Vehicle Models and sort it in the alphabetical order */
	const { isLoading: modelsLoading, data: modelsData } = useQuery<
		ModelResponse[]
	>("models", () => fetchVehicleModels());
	const sortedModelsData = modelsData?.sort((a, b) =>
		a.name.localeCompare(b.name)
	);

	/* Fetch Vehicles */
	const { isLoading: vehiclesLoading, data: vehiclesData } =
		useQuery<VehiclesResponse>("vehicles", () => fetchVehicles());
	const vehicles: VehicleResponse[] = _.get(vehiclesData, "content") || [];

	/* ==============================================================================
		Mutation Queries
	============================================================================== */

	/* Mutation function to add vehicles */
	const { mutateAsync: addVehicleMutateAsync, isLoading: addVehicleIsLoading } =
		useMutation<VehicleResponse, ErrorResponse, VehicleParams>(addVehicle, {
			onSuccess: () => {
				queryClient.invalidateQueries("vehicles");
			},
		});

	/* Mutation function to update vehicles */
	const {
		mutateAsync: updateVehicleMutateAsync,
		isLoading: updateVehicleIsLoading,
	} = useMutation<VehicleResponse, ErrorResponse, VehicleParams>(
		updateVehicle,
		{
			onSuccess: () => {
				queryClient.invalidateQueries("vehicles");
			},
		}
	);

	/* ==============================================================================
		A Handler for adding or updating vehicles
	============================================================================== */

	const handleSubmitAddEditVehicle = async (
		props: VehicleParams
	): Promise<boolean> => {
		/* If a vehicle is selected, execute a function to edit a vehicle */
		const mutate = !selectedVehicle
			? addVehicleMutateAsync
			: updateVehicleMutateAsync;

		const { message } = await mutate({ uuid: selectedVehicle?.uuid, ...props });

		if (!message) return true;

		if (
			message === t("The vehicle was created successfully", { lng: "en" }) ||
			message === t("The vehicle was updated successfully", { lng: "en" })
		) {
			handleShowAlert({ message, severity: "success" });
			return true;
		}

		handleShowAlert({ message, severity: "error" });
		return false;
	};

	return (
		<VehicleSettings
			makesOptions={sortedMakesData || []}
			modelsOptions={sortedModelsData || []}
			vehicles={vehicles || []}
			isLoading={
				makesLoading ||
				modelsLoading ||
				vehiclesLoading ||
				addVehicleIsLoading ||
				updateVehicleIsLoading
			}
			handleSubmitAddEditVehicle={handleSubmitAddEditVehicle}
		/>
	);
};

export default Vehicles;
