import { getEVCardByUuid } from "api/chargingstation-management";
import EVCardTransactions from "components/applicationSettings/evCards/transactions/EVCardTransactions";
import DashboardLayout from "Layouts/DashboardLayout";
/* import _ from "lodash"; */
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
/* import { EVCardDataType } from "types/EVCards"; */
import { transactionList } from "../../../assets/mock/EVCards";

export const EVCardsTransactionPage = () => {
	const { t } = useTranslation(["evCards"]);
	const params: { cardUuid: string } = useParams();
	const { cardUuid } = params;
	// eslint-disable-next-line no-console
	console.log("cardUuid", cardUuid);

	/* Fetch a single EV Card */
	const { isLoading: isEVCardLoading, data: evCard } = useQuery("evCard", () =>
		getEVCardByUuid(cardUuid)
	);
	// eslint-disable-next-line no-console
	console.log(evCard);

	/* Make a request to get the transaction list based on the card Number */
	let transactions: any = transactionList;
	evCard
		? (transactions = transactionList.filter(
				(transaction) => transaction.cardNumber === evCard.cardNumber
		  ))
		: (transactions = []);

	return (
		<DashboardLayout>
			<div className="flex flex-col">
				<div className="mb-5">
					<span className="text-2xl font-medium pb-10">
						{t("eVCardsTransaction")}
					</span>
				</div>
			</div>
			{!isEVCardLoading && (
				<EVCardTransactions evCard={evCard} transactions={transactions} />
			)}
		</DashboardLayout>
	);
};

export default EVCardsTransactionPage;
