import {
	AddressField,
	PhoneField,
	SubmitButton,
	TextField,
} from "components/form/InputField";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InitialsCircles from "components/navbars/InitialsCircles";
import { UserInfo } from "../../../types/Auth";

const UserNameForm = ({ user }: { user: UserInfo }) => {
	const { firstName, lastName, names, phoneNumber } = user;
	const { t } = useTranslation(["settings", "common"]);

	const form = useForm<{
		firstName: string;
		lastName: string;
		phoneNumber: string;
		address: string;
		names: string;
	}>({
		defaultValues: {
			firstName,
			lastName,
			phoneNumber,
			names,
			address: "",
		},
	});

	const { handleSubmit, register } = form;

	const onSubmit = async (props: {
		firstName: string;
		lastName: string;
		phoneNumber: string;
		address: string;
	}) => {
		// eslint-disable-next-line no-console
		console.log(props);
	};

	return (
		<div className="lg:w-1/2">
			<div className="my-5">
				<InitialsCircles.Large firstName={firstName} lastName={lastName} />
			</div>

			<FormProvider {...form}>
				<form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
					<div className="mb-2 grid gap-6 grid-cols-2">
						<TextField
							data-testid="first-name"
							className="mb-2"
							name="firstName"
							label={t("personal.firstName")}
							placeholder={t("personal.firstName")}
							autoComplete="firstName"
							ref={register({ required: true })}
						/>
						<TextField
							data-testid="last-name"
							className="mb-2"
							name="lastName"
							label={t("personal.lastName")}
							placeholder={t("personal.lastName")}
							ref={register({ required: true })}
						/>
					</div>

					<div className=" mb-2 ">
						<PhoneField
							data-testid="phoneNumber"
							name="phoneNumber"
							label={t("personal.phoneNumber", { ns: "settings" })}
							placeholder={t("personal.phoneNumber")}
						/>
					</div>

					<AddressField
						data-testid="address"
						className="mb-2"
						name="addressLine"
						label={t("personal.address")}
						getLocationData={(data: any) => {
							// eslint-disable-next-line no-console
							console.log(data);
						}}
						ref={register({ required: true })}
						placeholder={t("locations.addressPlaceholder", { ns: "common" })}
					/>
					<div className="flex justify-start items-center">
						<SubmitButton
							type="submit"
							className="flex justify-start my-6  text-center"
							disabled={false}
							label={t("save", { ns: "common" })}
						/>
					</div>
				</form>
			</FormProvider>
		</div>
	);
};

export default UserNameForm;
