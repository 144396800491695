import Checkbox from "antd/lib/checkbox/Checkbox";
import classnames from "classnames";
import PopoverMenu from "components/common/PopoverMenu";
import { BsDot } from "react-icons/bs";
import { BiPulse } from "react-icons/bi";
import { IoSquare } from "react-icons/io5";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { calcElapsedDayOrTime, switchSupportIcon } from "utils";
import { IconType } from "react-icons/lib";
import IssuePopOverMenu from "./IssuePopOverMenu";
import OverdueIndicator from "./OverdueIndicator";

const TicketCardItem = ({
	issue,
	statusOptions,
	priorityOptions,
}: {
	issue: any;
	statusOptions: any[];
	priorityOptions: any[];
}) => {
	const { t } = useTranslation(["support"]);

	const {
		key,
		subject,
		priority,
		status,
		overdue,
		agent,
		/* department, */
		createdAt,
		respondedAt,
		source,
	} = issue;

	const IssueIcon: IconType = switchSupportIcon(source);

	return (
		<div className="flex border p-5 mt-5  bg-white border-l-2 border-l-primary-color items-center">
			<div className="w-auto flex items-center">
				<Checkbox onChange={() => {}} />
			</div>

			<div className="w-[75%] px-5">
				{overdue && <OverdueIndicator className="mb-2" />}

				<a
					href={`/support/ticket/${key}`}
					className="text-primary-color text-base"
				>
					{subject} <span className="text-gray-500">#{key}</span>
				</a>

				<div className="flex items-center">
					<span className="flex items-center mr-1">
						<IssueIcon className="mr-2" />
						{agent}
					</span>
					<span className="flex items-center mr-2">
						<BsDot /> {t("responded")}: {calcElapsedDayOrTime(respondedAt)}
					</span>
					<span className="flex items-center mr-2">
						<BsDot /> {t("created")}: {calcElapsedDayOrTime(createdAt)}
					</span>
				</div>
			</div>
			<div className="mr-5 w-[20%]">
				<div className="flex items-center  mb-2 ">
					<IoSquare
						className={classnames(
							{ "text-blue-400": priority === "low" },
							{ "text-green-400": priority === "medium" },
							{ "text-yellow-400": priority === "high" },
							{ "text-red-500": priority === "urgent" },
							"mr-2 text-[0.60rem]"
						)}
					/>
					<PopoverMenu
						buttonClass={classnames(
							priority === "urgent" ? "font-bold" : "",
							"flex items-center justify-between"
						)}
						placement="bottomLeft"
						showArrow={false}
						content={
							<IssuePopOverMenu
								menuItems={priorityOptions}
								selected={priority}
								selectMenuItem={() => {}}
							/>
						}
						showToolTip
						toolTipText="Priority"
						icon={<MdKeyboardArrowDown className="ml-2" />}
						label={t(priority)}
						noDecoration
					/>
				</div>

				<div className="flex items-center  mb-2 ">
					<BiPulse className="mr-1 text-sm" />
					<PopoverMenu
						buttonClass="flex items-center justify-between"
						placement="bottomLeft"
						showArrow={false}
						content={
							<IssuePopOverMenu
								menuItems={statusOptions}
								selected={status}
								selectMenuItem={() => {}}
							/>
						}
						showToolTip
						toolTipText="Status"
						icon={<MdKeyboardArrowDown className="ml-2" />}
						label={t(status)}
						noDecoration
					/>
				</div>
			</div>
		</div>
	);
};

export default TicketCardItem;
