import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import classNames from "classnames";
import MoreOptionsMenu from "components/common/MoreOptionsMenu";
import { useTranslation } from "react-i18next";
import { UserDataSource } from "types/UsersManagement";
import { useEffect, useState } from "react";
import { localeCompareForTable } from "utils";
import UserToolbar from "./UserToolbar";

const UserList = ({
	users,
	handlePageChange,
	pageTotal,
	handleShowUserModal,
}: {
	users: UserDataSource[];
	pageTotal: number;
	handleShowUserModal: (user?: UserDataSource) => void;
	handlePageChange: (pageNumber: number, size: number) => void;
}) => {
	const { t } = useTranslation(["accounts", "settings", "common"]);
	const [tableData, setTableData] = useState<UserDataSource[]>(users);

	useEffect(() => {
		setTableData(users);
	}, [users]);

	/* Show the search result in the table */
	const handleSearch = (searchTerm: string) => {
		const result = users.filter(
			(data: UserDataSource) =>
				data.firstName.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0 ||
				data.lastName.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0 ||
				data.email.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
		);
		setTableData(result);
		if (!searchTerm) {
			setTableData(users);
		}
	};

	const columns: ColumnsType<UserDataSource> = [
		{
			dataIndex: "firstName",
			title: t("firstName"),
			key: "firstName",
			width: "15%",
			sorter: (a, b) => localeCompareForTable(a.firstName, b.firstName),
		},
		{
			dataIndex: "lastName",
			title: t("lastName"),
			key: "lastName",
			width: "15%",
			sorter: (a, b) => localeCompareForTable(a.lastName, b.lastName),
		},
		{
			dataIndex: "email",
			title: "Email",
			key: "email",
			responsive: ["md"],
			width: "25%",
		},
		{
			dataIndex: "roleNames",
			title: "Roles",
			key: "roleNames",
			responsive: ["md"],
			width: "25%",
			render: (roleNames: string[]) => <p>{roleNames.join()}</p>,
		},
		{
			dataIndex: "enabled",
			title: t("status", { ns: "common" }),
			key: "enabled",
			responsive: ["md"],
			width: "15%",
			align: "center",
			render: (isEnabled: boolean) => (
				<p
					className={classNames(
						isEnabled ? "bg-primary-color" : "bg-gray-300",
						"rounded-full w-24 py-2 text-xs text-center m-auto text-white"
					)}
				>
					{isEnabled
						? t("enabled", { ns: "common" })
						: t("disabled", { ns: "common" })}
				</p>
			),
		},
		{
			title: t("action", { ns: "common" }),
			key: "operation",
			fixed: "right",
			align: "center",
			width: "15%",
			render: (item) => <MoreOptionsMenu menuList={List} data={item} />,
		},
	];

	const List = [
		{
			label: t("edit", { ns: "common" }),
			onClick: (data: UserDataSource) => handleShowUserModal(data),
		},
		{
			label: t("details", { ns: "common" }),
			onClick: (data: UserDataSource) => {
				const userUuid = data.key;
				window.location.replace(`/users/${userUuid}`);
			},
		},
	];

	return (
		<>
			<UserToolbar
				handleShowUserModal={handleShowUserModal}
				handleSubmit={handleSearch}
			/>
			<Table
				columns={columns}
				dataSource={tableData}
				scroll={{ y: 450, x: 900 }}
				pagination={{
					total: pageTotal,
					pageSizeOptions: ["10", "20", "50", "100"],
					showSizeChanger: true,
					onChange: (page, pageSize) => handlePageChange(page, pageSize),
				}}
			/>
		</>
	);
};

export default UserList;
