import { SubmitButton, TextField } from "components/form/InputField";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const SupportFilter = () => {
	const { t } = useTranslation(["support"]);

	const form = useForm<{}>({
		defaultValues: {},
	});
	// eslint-disable-next-line no-empty-pattern
	const {} = form;
	return (
		<>
			<span className="text-base font-bold">Filter</span>

			<FormProvider {...form}>
				<form>
					<div className="mb-2 mt-5">
						<TextField className="mb-2" name="name" label={t("agent")} />
					</div>
					<div className="mb-2">
						<TextField className="mb-2" name="name" label={t("department")} />
					</div>
					<div className="mb-2">
						<TextField className="mb-2" name="name" label={t("status")} />
					</div>
					<div className="mb-2">
						<TextField className="mb-2" name="name" label={t("priority")} />
					</div>
					<div className="mb-2">
						<TextField className="mb-2" name="name" label={t("type")} />
					</div>

					<SubmitButton
						className="rounded w-full justify-center"
						type="button"
						onClick={() => {}}
						label={t("apply")}
					/>
				</form>
			</FormProvider>
		</>
	);
};

export default SupportFilter;
