import React, { useState } from "react";
import { VehicleResponse } from "types/Settings";

const SettingsContext = React.createContext<{
	selectedVehicle: VehicleResponse | null;
	handleSelectedVehicle: (data: VehicleResponse | null) => void;
}>({
	selectedVehicle: null,
	handleSelectedVehicle: () => {},
});

const SettingsContextProvider = (props: {
	children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}) => {
	const [selectedVehicle, setSelectedVehicle] =
		useState<VehicleResponse | null>(null);

	const handleSelectedVehicle = (data: VehicleResponse | null) => {
		setSelectedVehicle(data);
	};

	const value = React.useMemo(
		() => ({
			selectedVehicle,
			handleSelectedVehicle,
		}),
		[selectedVehicle]
	);

	return (
		<SettingsContext.Provider value={value}>
			{props.children}
		</SettingsContext.Provider>
	);
};

export { SettingsContext, SettingsContextProvider };
