import AuthBackground from "assets/svg/bg-auth.svg";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import Logo from "../assets/images/logo.png";

export const Footer = () => {
	const { t } = useTranslation(["common"]);
	return (
		<div className="flex flex-grow-0 items-center justify-center p-5">
			<small className="text-neutral-grey-2">
				2021 © Tap&GoEv. {t("allRightsReserved")}{" "}
			</small>
			<a className="ml-8" href="/" target="_blank" rel="noreferrer">
				<small className="underline text-neutral-grey-2">{t("legal")}</small>
			</a>
		</div>
	);
};

const LeftSection = () => (
	<section className="hidden lg:flex bg-secondary-pastel-green-100 flex-1 flex-col w-11/12">
		<img
			src={Logo}
			className="absolute top-6 left-6 w-60"
			alt="Tap&GoEV Logo"
		/>
		<div className="flex m-auto ">
			<img width={1000} className="ml-10" src={AuthBackground} alt="bg_img" />
		</div>
	</section>
);

const RightSection = ({ children }: { children: ReactNode }) => (
	<section className="h-full flex flex-col w-full lg:w-2/5 items-center lg:items-start overflow-y-auto">
		<div className="flex flex-grow flex-col  items-center justify-start lg:justify-center">
			<div className="w-[400px] bg-white rounded-md p-10">
				<img src={Logo} className="w-52 lg:hidden mb-10" alt="Tap&GoEV Logo" />
				{children}
			</div>
		</div>
		<Footer />
	</section>
);

const AuthLayout = ({ children }: { children: ReactNode }) => (
	<div className="h-screen w-full flex flex-row bg-white lg:bg-light-green">
		<LeftSection />
		<RightSection>{children}</RightSection>
	</div>
);
export default AuthLayout;
