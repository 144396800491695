import { useState, useMemo, useCallback, useRef } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import config from "config";
import { ChargingStationResponse, SiteResponse } from "types/Stations";
import InfoCard from "./InfoCard";
import Loading from "../../assets/svg/assets/Loading";

type LatLngLiteral = google.maps.LatLngLiteral;
type MapOptions = google.maps.MapOptions;

const containerStyle = {
	width: "100%",
	height: "40vh",
};

const SingleSiteMap = ({
	site,
	chargers,
	handleFetchChargers,
	handleToggleSiteDetail,
	isLoading,
}: {
	site: SiteResponse;
	handleFetchChargers: (uuid: string) => void;
	chargers: ChargingStationResponse[];
	handleToggleSiteDetail: () => void;
	isLoading: boolean;
}) => {
	const [selectedSite, setSelectedSite] = useState<SiteResponse | null>(null);

	const mapRef = useRef<GoogleMap>();
	const center = useMemo<LatLngLiteral>(
		() => ({
			lat: Number(site.locationLattitude),
			lng: Number(site.locationLongitude),
		}),
		[site]
	);
	const options = useMemo<MapOptions>(
		() => ({
			mapId: "87536500f6766fbe",
			disableDefaultUI: true,
			clickableIcons: false,
			gestureHandling: "greedy",
		}),
		[]
	);

	const onLoad = useCallback((map: any) => (mapRef.current = map), []);

	const { isLoaded } = useJsApiLoader({
		id: "google-map-script",
		libraries: ["places"],
		googleMapsApiKey: config.googleClientId,
	});

	if (!isLoaded) {
		return (
			<div className="h-[100vh] flex justify-center items-center">
				<Loading />
			</div>
		);
	}

	return (
		<GoogleMap
			mapContainerStyle={containerStyle}
			center={center}
			zoom={12}
			options={options}
			onLoad={onLoad}
		>
			<Marker
				key={site.uuid}
				onClick={() => {
					setSelectedSite(site);
					handleFetchChargers(site.uuid);
				}}
				position={{
					lat: Number(site.locationLattitude),
					lng: Number(site.locationLongitude),
				}}
			/>

			{selectedSite && (
				<InfoCard
					handleShowMore={handleToggleSiteDetail}
					selectedSite={selectedSite}
					isLoading={isLoading}
					chargers={chargers}
					onCloseClick={() => {
						setSelectedSite(null);
					}}
				/>
			)}
		</GoogleMap>
	);
};

export default SingleSiteMap;
