import { AlertContext } from "context/AlertContext";
import { useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ErrorResponse } from "types";
import MakeAndModelsSettings from "components/applicationSettings/makesAndModels";
import {
	addVehicleMakes,
	addVehicleModels,
	fetchVehicleMakes,
	fetchVehicleModels,
	updateVehicleMake,
	updateVehicleModel,
} from "api/settings";
import {
	VehicleModelParams,
	MakeParams,
	MakeResponse,
	ModelResponse,
} from "types/Settings";
import { useTranslation } from "react-i18next";
import DashboardLayout from "Layouts/DashboardLayout";
import { ApplicationManagementContext } from "context/ApplicationManagementContext";

const MakeAndModels = () => {
	const { t } = useTranslation(["constants", "navigation"]);

	const { handleShowAlert } = useContext(AlertContext);
	const { selectedMake, selectedModel } = useContext(
		ApplicationManagementContext
	);

	const { uuid } = selectedMake || {};
	const { uuid: modeluuid } = selectedModel || {};

	const queryClient = useQueryClient();

	const {
		mutateAsync: createMakeMutateAsync,
		isLoading: createVehicleMakeLoading,
	} = useMutation<MakeResponse, ErrorResponse, MakeParams>(addVehicleMakes, {
		onSuccess: () => {
			queryClient.invalidateQueries("make");
		},
	});

	const {
		mutateAsync: updateMakeMutateAsync,
		isLoading: updateVehicleMakeLoading,
	} = useMutation<ModelResponse, ErrorResponse, MakeParams>(updateVehicleMake, {
		onSuccess: () => {
			queryClient.invalidateQueries("make");
		},
	});

	const {
		mutateAsync: createModelMutateAsync,
		isLoading: createVehicleModelLoading,
	} = useMutation<ModelResponse, ErrorResponse, VehicleModelParams>(
		addVehicleModels,
		{
			onSuccess: () => {
				queryClient.invalidateQueries("models");
			},
		}
	);

	const {
		mutateAsync: updateModelMutateAsync,
		isLoading: updateVehicleModelLoading,
	} = useMutation<ModelResponse, ErrorResponse, VehicleModelParams>(
		updateVehicleModel,
		{
			onSuccess: () => {
				queryClient.invalidateQueries("models");
			},
		}
	);

	/* Fetch Vehicle Models and sort it in the alphabetical order */
	const { isLoading: modelIsLoading, data: modelData } = useQuery<
		ModelResponse[]
	>("models", () => fetchVehicleModels());
	const sortedModelData = modelData?.sort((a, b) =>
		a.createdAt < b.createdAt ? 1 : -1
	);

	/* Fetch Vehicle Makes and sort it in the alphabetical order */
	const { isLoading: makeIsLoading, data: makeData } = useQuery<MakeResponse[]>(
		"make",
		() => fetchVehicleMakes()
	);
	const sortedMakeData = makeData?.sort((a, b) =>
		a.createdAt < b.createdAt ? 1 : -1
	);

	const handleAddUpdateMake = async (props: MakeParams): Promise<boolean> => {
		const mutate = !selectedMake
			? createMakeMutateAsync
			: updateMakeMutateAsync;

		const { message } = await mutate({ ...props, uuid });

		if (!message) return false;

		if (
			message === t("Vehicle Make updated successfully", { lng: "en" }) ||
			message === t("Vehicle Make created successfully", { lng: "en" })
		) {
			handleShowAlert({ message, severity: "success" });
			return true;
		}

		handleShowAlert({ message, severity: "error" });

		return false;
	};

	const handleAddUpdateModel = async (
		props: VehicleModelParams
	): Promise<boolean> => {
		const mutate = !selectedModel
			? createModelMutateAsync
			: updateModelMutateAsync;

		const { message } = await mutate({ ...props, uuid: modeluuid });

		if (!message) return false;

		if (
			message === t("Vehicle Model updated successfully", { lng: "en" }) ||
			message === t("Vehicle Model created successfully", { lng: "en" })
		) {
			handleShowAlert({ message, severity: "success" });
			return true;
		}

		handleShowAlert({ message, severity: "error" });

		return false;
	};

	return (
		<DashboardLayout>
			<div className="flex flex-col">
				<div className="mb-5">
					<span className="text-2xl font-medium pb-10">
						{t("make and models", { ns: "navigation" })}
					</span>
				</div>

				<MakeAndModelsSettings
					isLoading={
						createVehicleMakeLoading ||
						createVehicleModelLoading ||
						modelIsLoading ||
						makeIsLoading ||
						updateVehicleMakeLoading ||
						updateVehicleModelLoading
					}
					makeData={sortedMakeData || []}
					modelData={sortedModelData || []}
					handleAddUpdateMake={handleAddUpdateMake}
					handleAddUpdateModel={handleAddUpdateModel}
				/>
			</div>
		</DashboardLayout>
	);
};

export default MakeAndModels;
