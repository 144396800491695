import { useTranslation } from "react-i18next";
import { scorePasswordStrength } from "../../utils";

const PasswordStrengthMeter = ({ password }) => {
	const { t } = useTranslation(["settings"]);
	const score = scorePasswordStrength(password);
	const color = !password
		? "border-gray-300"
		: score <= 2
		? "red-500"
		: score < 4
		? "yellow-300"
		: "primary-color";

	const weak = t("personal.passwordStrength.weak");
	const medium = t("personal.passwordStrength.medium");
	const strong = t("personal.passwordStrength.strong");
	const status = score <= 2 ? weak : score < 4 ? medium : strong;

	return (
		<div className="py-4">
			<div className="flex items-center">
				<div className="w-full h-2 grid grid-rows-1 grid-flow-col gap-4">
					{Array.from(Array(4).keys()).map((index) => {
						const isColored = index + 1 <= score;
						return (
							<div
								key={index}
								className={`h-1 border-b${isColored ? "-2" : ""} ${
									isColored ? `border-${color}` : "border-gray-300"
								}`}
							/>
						);
					})}
				</div>
				{password && <small className={`text-${color} ml-2`}>{status}</small>}
			</div>
		</div>
	);
};

export default PasswordStrengthMeter;
