import Breadcrumbs from "components/breadcrumbs";
import { useTranslation } from "react-i18next";
import { BreadCrumbsProps } from "types/index";
import { issueDataType } from "types/Support";
import {
	calcElapsedDayOrTime,
	convertUTCToLocalTimeString,
	switchSupportIcon,
} from "utils";
import parse from "html-react-parser";
import { IconType } from "react-icons/lib";
import Button from "components/common/Button";
import { BiReply } from "react-icons/bi";
import { GrNotes } from "react-icons/gr";
import OverdueIndicator from "./OverdueIndicator";
import SupportTicketActionForm from "./SupportTicketActionForm";

const SupportTicketDetails = ({
	issue,
	issueTypeOptions,
	statusOptions,
	priorityOptions,
	departmentOptions,
	defaultAgentOptions,
}: {
	issue: issueDataType;
	issueTypeOptions: any[];
	statusOptions: any[];
	priorityOptions: any[];
	departmentOptions: any[];
	defaultAgentOptions: any[];
}) => {
	const { t, i18n } = useTranslation(["navigation", "support", "common"]);

	const {
		key,
		subject,
		source,
		createdBy,
		agent,
		createdAt,
		description,
		overdue,
	} = issue;

	const HeaderIcon: IconType = switchSupportIcon(source);
	const btnClass =
		"pl-3 pr-4 py-2 rounded-sm border border-gray-300 mr-4 bg-white hover:bg-gray-100";

	const routes: BreadCrumbsProps[] = [
		{
			path: "/dashboards",
			breadcrumbName: t("home"),
		},
		{
			path: "/support",
			breadcrumbName: t("support"),
		},
		{
			path: `/support/ticket/${key}`,
			breadcrumbName: `${t("ticket", { ns: "support" })} #${key}`,
		},
	];

	return (
		<>
			<Breadcrumbs breadCrumb={routes} />
			<div className="bg-white px-10 md:px-24 lg:px-16 py-20 mt-7 grid grid-cols-7 gap-10">
				{/* Support ticket detail section */}
				<div className="col-start-1 col-span-5">
					<div className="flex items-center mb-4">
						<span className="text-2xl mr-5">
							<HeaderIcon />
						</span>
						<span className="text-xl font-bold mr-5">{subject}</span>
						{overdue && <OverdueIndicator />}
					</div>
					<p className="mb-10">
						{t("createdBy", { ns: "common" })}{" "}
						<span className="font-bold">{createdBy}</span>
					</p>
					<div className="mb-10">
						<p className="mb-1">
							<span className="font-bold">{agent}</span>{" "}
							{t("reportedVia", { ns: "support" })} {source}
						</p>
						<div className="italic">
							<span className="mr-2">{calcElapsedDayOrTime(createdAt)}</span>
							<span>
								(
								{convertUTCToLocalTimeString(
									createdAt,
									"medium",
									"short",
									i18n.language
								)}
								)
							</span>
						</div>
					</div>
					<div>{parse(description)}</div>

					{/* Support ticket action buttons */}
					<div className="bg-gray-100 p-4 mt-10 flex">
						<Button
							label={t("reply", { ns: "support" })}
							type="button"
							className={btnClass}
							icon={<BiReply size={22} />}
						/>
						<Button
							label={t("addNote", { ns: "support" })}
							type="button"
							className={btnClass}
							icon={<GrNotes size={14} className="mr-2" />}
						/>
						<Button
							label={t("forward", { ns: "support" })}
							type="button"
							className={btnClass}
							icon={<BiReply size={22} className="transform -scale-x-100" />}
						/>
					</div>
				</div>

				{/* Support ticket quick action section */}
				<div className="col-start-6 border p-7 col-span-2">
					<SupportTicketActionForm
						issueTypeOptions={issueTypeOptions}
						statusOptions={statusOptions}
						priorityOptions={priorityOptions}
						departmentOptions={departmentOptions}
						defaultAgentOptions={defaultAgentOptions}
					/>
				</div>
			</div>
		</>
	);
};

export default SupportTicketDetails;
