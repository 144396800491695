const Loading = ({ className = "h-6 w-6 mr-3", size = 12, ...other }) => (
	<svg
		className={`svg-container ${className}`}
		viewBox="0 0 100 100"
		width={size}
		height={size}
		{...other}
	>
		<circle className="loader-svg bg" cx="50" cy="50" r="45" />
		<circle className="loader-svg animate" cx="50" cy="50" r="45" />
	</svg>
);

export default Loading;
