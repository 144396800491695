import { IoHomeOutline, IoSettingsOutline } from "react-icons/io5";
import { AiOutlineUser } from "react-icons/ai";
import {
	MdOutlineHistory,
	MdOutlineLogout,
	MdOutlineKeyboardArrowDown,
	MdArrowForward,
	MdArrowBack,
} from "react-icons/md";
import { BsPinMapFill } from "react-icons/bs";
import { BiSupport } from "react-icons/bi";
import { RiFolderSettingsFill } from "react-icons/ri";
import { AuthContext } from "context/AuthContext";
import { useContext, useEffect, useState } from "react";
import usePermissionGate from "hooks/usePermissionGate";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { SideNavigationItem } from "types";
import { useLocation } from "react-router-dom";
import InitialsCircles from "./InitialsCircles";

const checkPermission = (permissionNames: string[], scopes: string[]) => {
	const { hasPermission } = usePermissionGate({
		permissionNames,
		scopes,
	});

	return hasPermission;
};
const getSidebarItems = ({
	userPermissions,
}: {
	userPermissions: string[];
}) => {
	const items = [
		{
			route: "/dashboard",
			label: "home",
			Icon: IoHomeOutline,
			permission: null,
		},
		{
			route: "/settings/personal",
			label: "settings",
			Icon: IoSettingsOutline,
			permission: null,
		},
		{
			route: "/",
			label: "transactions",
			Icon: MdOutlineHistory,
			permission: null,
		},
		{
			route: "/",
			label: "find charge point",
			Icon: BsPinMapFill,
			permission: null,
		},
		{
			route: "/support",
			label: "support",
			Icon: BiSupport,
			permission: null,
		},
		{
			route: "/user-management",
			label: "users management",
			Icon: AiOutlineUser,
			permission: "ROLE_BACKOFFICE",
			subMenuItems: [
				{
					route: "/site-owners",
					label: "site owners",
					permission: "ROLE_BACKOFFICE",
				},
				{
					route: "/organizations",
					label: "organizations",
					permission: "ROLE_CAN_VIEW_ORGANIZATION",
				},
				{
					route: "/users",
					label: "users",
					permission: "ROLE_CAN_VIEW_BACKOFFICE_USER",
				},
			],
		},
		{
			route: "/app-settings",
			label: "application management",
			Icon: RiFolderSettingsFill,
			permission: "ROLE_BACKOFFICE",
			subMenuItems: [
				{
					route: "/app-settings/roles",
					label: "roles",
					permission: "ROLE_CAN_VIEW_ROLE",
				},
				{
					route: "/app-settings/permissions",
					label: "permissions",
					permission: "ROLE_CAN_VIEW_ROLE",
				},
				{
					route: "/app-settings/organization-types",
					label: "organization types",
					permission: "ROLE_CAN_VIEW_ORGANIZATION",
				},
				{
					route: "/app-settings/make-and-models",
					label: "make and models",
					permission: "ROLE_CAN_VIEW_MAKE_AND_MODEL",
				},
				{
					route: "/app-settings/ev-cards",
					label: "ev cards",
					permission: "ROLE_CAN_VIEW_EV_CARDS",
				},
			],
		},
	];

	const permitedNavItems = items.filter((item) => {
		const isPermitted = item.permission
			? checkPermission(userPermissions || [], [item.permission])
			: true;

		if (isPermitted) {
			if (item.subMenuItems && item.subMenuItems?.length > 0) {
				const newSubMenu = item.subMenuItems.filter((subItem) => {
					const hasPermission = subItem.permission
						? checkPermission(userPermissions || [], [subItem.permission])
						: [];

					return hasPermission;
				});

				item.subMenuItems = newSubMenu;
			}
			return item;
		}
		return null;
	});

	return permitedNavItems;
};

const SideNav = ({ showMenu }: { showMenu?: boolean }) => {
	const [sidebarItems, setSidebarItems] = useState<SideNavigationItem[]>([]);
	const [expand, setExpand] = useState<boolean>(false);

	const location = useLocation();
	const { user, logout } = useContext(AuthContext);
	const { t } = useTranslation(["navigation"]);

	const { firstName, lastName, email } = user || {};

	const [menu, setMenu] = useState<Number | null>(null);

	useEffect(() => {
		const activeMenuItem = sidebarItems.reduce((acc, item) => {
			let newAcc = acc;
			const activeMenu =
				item.subMenuItems &&
				item.subMenuItems.find(
					(submenu) => submenu.route === location.pathname
				);

			if (activeMenu) newAcc = item;

			return newAcc;
		}, {} as any);

		const index = sidebarItems.findIndex(
			(item) => item.label === activeMenuItem.label
		);

		setMenu(index);
	}, [sidebarItems]);

	useEffect(() => {
		if (user) {
			const res = getSidebarItems({ userPermissions: user.permissionNames });

			setSidebarItems(res);
		}
	}, [user]);

	const setMenuValue = (props: Number) => {
		if (menu === props) {
			setMenu(null);
			return;
		}
		setMenu(props);
	};

	return (
		<div
			id="Main"
			className={classNames(
				showMenu && "absolute z-10 h-full",
				expand
					? "md:w-2 duration-100 ease-in-out"
					: "md:w-[22rem] duration-100 ease-in-out",
				!showMenu
					? "w-0 duration-100 ease-in-out"
					: "w-full duration-100 ease-in-out",
				"flex flex-col justify-between items-start bg-gray-900 md:relative"
			)}
		>
			{!expand && (
				<div className="w-full">
					{sidebarItems.map((item, ind) => (
						<div
							key={ind}
							className={classNames(
								"flex flex-col hover:bg-primary-color",
								location.pathname.split("/")[1] === item.route.split("/")[1] &&
									item.route !== "/" &&
									!item.subMenuItems &&
									"bg-primary-color"
							)}
						>
							{!expand && (
								<div
									tabIndex={0}
									role="button"
									onKeyDown={() => {}}
									onClick={() => {
										setMenuValue(ind);

										if (!item.subMenuItems) {
											window.location.replace(item.route);
										}
									}}
									className="flex justify-between items-center text-white px-4 py-5 "
								>
									<div className="focus:outline-none text-white flex items-center space-x-5">
										<item.Icon className="text-xl" />
										<p className="text-sm capitalize m-0 text-left">
											{t(item.label)}
										</p>
									</div>
									{item.subMenuItems && item.subMenuItems?.length > 0 && (
										<MdOutlineKeyboardArrowDown
											className={classNames(
												"transform duration-100 text-xl",
												menu !== ind ? "" : "rotate-180"
											)}
										/>
									)}
								</div>
							)}
							<div
								className={classNames(
									menu === ind ? "max-h-96" : "max-h-0",
									"overflow-hidden transition-all ease-in-out duration-300"
								)}
							>
								{item.subMenuItems &&
									item.subMenuItems.map((subItems, index) => (
										<div
											key={index}
											id="menu1"
											className={classNames(
												location.pathname === subItems.route
													? "bg-primary-color"
													: "bg-gray-800"
											)}
										>
											<button
												onClick={() => {
													if (item.route) {
														window.location.replace(subItems.route);
													}
												}}
												className="flex justify-start items-center text-white hover:bg-gray-700 px-4 py-5  w-full"
											>
												<p
													className={classNames(
														menu === ind ? "opacity-100" : "opacity-0",
														"text-sm capitalize m-0 text-left ml-10 transition-all ease-in-out duration-300"
													)}
												>
													{t(subItems.label)}
												</p>
											</button>
										</div>
									))}
							</div>
						</div>
					))}
				</div>
			)}
			<button
				onClick={() => setExpand(!expand)}
				className={classNames(
					!expand ? "rotate-180" : "-rotate-180 translate-x-[2rem]",
					"duration-100 ease-in-out hidden md:block absolute p-3 shadow-lg bg-white right-0 rounded-full translate-x-5 top-36"
				)}
			>
				{!expand ? (
					<MdArrowForward className="text-gray-900" />
				) : (
					<MdArrowBack className="text-gray-900" />
				)}
			</button>
			{!expand && (
				<div className="flex flex-col justify-between items-end pb-6 px-5 w-full">
					<div />
					<div className="text-white bg-gray-800 h-12 w-12 rounded-full items-center flex justify-center">
						<button onClick={logout}>
							<MdOutlineLogout className="text-white text-2xl" />
						</button>
					</div>
					<div className=" flex justify-between items-center w-full">
						<div className="flex justify-center items-center  space-x-2">
							<div>
								<InitialsCircles.Large
									firstName={firstName}
									lastName={lastName}
								/>
							</div>
							<div className="flex justify-start flex-col items-start">
								<p className="cursor-pointer text-sm leading-5 text-white">
									{firstName} {lastName}
								</p>
								<p className="cursor-pointer text-xs leading-3 text-gray-300">
									{email}
								</p>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default SideNav;
