import { SiteOwnerDataSource } from "types/Stations";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import MoreOptionsMenu from "components/common/MoreOptionsMenu";
import StationOwnersToolbar from "./SiteOwnersToolbar";

const SiteOwnersList = ({
	siteOwners,
	handleToggleShowAddEditModal,
	siteOwnerIsLoading,
	handlePageChange,
	handleEditSiteOwner,
	pageTotal,
}: {
	siteOwners: SiteOwnerDataSource[];
	handleToggleShowAddEditModal: () => void;
	siteOwnerIsLoading: boolean;
	pageTotal: number;
	handleEditSiteOwner: (siteOwner: SiteOwnerDataSource) => void;
	handlePageChange: (page: number, size: number) => void;
}) => {
	const { t } = useTranslation(["common"]);
	const [tableData, setTableData] = useState<SiteOwnerDataSource[]>([]);

	/* Show the search result in the table */
	const handleSearch = (searchTerm: string) => {
		const result = tableData.filter(
			(data: SiteOwnerDataSource) =>
				data.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0 ||
				data.address.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
		);
		setTableData(result);
		if (!searchTerm) {
			setTableData(siteOwners);
		}
	};

	useEffect(() => {
		setTableData(siteOwners);
	}, [siteOwners]);

	const columns: ColumnsType<SiteOwnerDataSource> = [
		{
			dataIndex: "name",
			title: t("name"),
			key: "name",
			sorter: (a, b) => a.name.length - b.name.length,
			render: (item, record) => (
				<a
					className="text-primary-color uppercase"
					href={`/site-owners/site-owner/${record.key}`}
				>
					{item}
				</a>
			),
		},
		{
			dataIndex: "address",
			title: t("locations.address"),
			key: "address",
		},
		{
			dataIndex: "createdBy",
			title: t("createdBy"),
			key: "createdBy",
			responsive: ["md"],
		},
		{
			title: t("action"),
			key: "operation",
			fixed: "right",
			align: "center",
			width: 100,
			render: (item) => <MoreOptionsMenu menuList={List} data={item} />,
		},
	];

	const List = [
		{
			label: t("edit"),
			onClick: (data: SiteOwnerDataSource) => handleEditSiteOwner(data),
		},
	];

	return (
		<div>
			<StationOwnersToolbar
				handleShowAddStationOwnerModal={handleToggleShowAddEditModal}
				handleSubmit={handleSearch}
			/>

			<Table
				loading={siteOwnerIsLoading}
				dataSource={tableData || []}
				columns={columns}
				scroll={{ y: 450, x: 900 }}
				pagination={{
					total: pageTotal,
					pageSizeOptions: ["10", "20", "50", "100"],
					showSizeChanger: true,
					onChange: (page, pageSize) => handlePageChange(page, pageSize),
				}}
			/>
		</div>
	);
};

export default SiteOwnersList;
