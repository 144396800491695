import { AuthContext } from "context/AuthContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { pathRolePermissions } from "../../constants/index";

const getPermissionLevel = (route: string) => pathRolePermissions.get(route);

const getSidebarItems = () => {
	const items: {
		route: string;
		label: string;
		permission: string | undefined;
	}[] = [
		{
			route: "/settings/personal",
			label: "menu.personal",
			permission: getPermissionLevel("/settings/personal"),
		},
		{
			route: "/settings/vehicles",
			label: "menu.vehicles",
			permission: getPermissionLevel("/settings/vehicles"),
		},
		{
			route: "/settings/integrations",
			label: "menu.integrations",
			permission: getPermissionLevel("/settings/integrations"),
		},
	];

	return items;
};

const SettingsNavigation = () => {
	const { user } = useContext(AuthContext);
	const { t } = useTranslation(["settings"]);

	if (!user) return null;

	const items = getSidebarItems().filter((item) => {
		if (item.permission) return user.permissionNames.includes(item.permission);
		return true;
	});

	const selectedItem = items.find((item) =>
		item.route.includes(window.location.pathname)
	);

	return (
		<div className="text-sm font-medium text-center text-gray-500 ">
			<ul className="flex flex-wrap -mb-px">
				{items.map((item) => {
					const isSelected = selectedItem && selectedItem.label === item.label;
					return (
						<li key={item.label} className="mr-2">
							<a
								key={item.label}
								href={item.route}
								className={
									isSelected
										? "inline-block p-4 text-primary-color font-semibold rounded-t-lg border-b-2 border-primary-color active "
										: "inline-block p-4 rounded-t-lg border-b-2 border-transparent text-gray-600 hover:text-primary-color hover:border-primary-color"
								}
							>
								{t(item.label)}
							</a>
						</li>
					);
				})}
			</ul>
			<hr className="border-gray-300 mb-3" />
		</div>
	);
};

export default SettingsNavigation;
