import {
	ChargingStationParams,
	SiteOwnerParams,
	SiteParams,
} from "types/Stations";
import apiRequestHandler from "utils/apiRequestHandler";

export const CreateSiteOwners = (params: SiteOwnerParams): any => {
	const res = apiRequestHandler({
		url: "/site-owners",
		data: params,
		method: "post",
	});
	return res;
};

export const GetAllSiteOwners = (page = 1, size = 2) => {
	const res = apiRequestHandler({
		url: `/site-owners?page=${page}&size=${size}`,
		data: {},
		method: "get",
	});
	return res;
};

export const GetASiteOwner = (uuid: string): any => {
	const res = apiRequestHandler({
		url: `/site-owners/${uuid}`,
		data: {},
		method: "get",
	});
	return res;
};

export const UpdateASiteOwner = ({ uuid, ...rest }: any): any => {
	const res = apiRequestHandler({
		url: `/site-owners/${uuid}`,
		data: rest,
		method: "put",
	});
	return res;
};

export const CreateSites = (params: SiteParams): any => {
	const res = apiRequestHandler({
		url: "/sites/add",
		data: params,
		method: "post",
	});
	return res;
};

export const UpdateSites = ({ uuid, ...rest }: any): any => {
	const res = apiRequestHandler({
		url: `/sites/${uuid}`,
		data: rest,
		method: "put",
	});
	return res;
};

export const GetAllSites = () => {
	const res = apiRequestHandler({
		url: "/sites",
		data: {},
		method: "get",
	});
	return res;
};

export const GetAllSitesBySiteID = (
	siteOwnerUuid: string,
	page = 0,
	size = 10
) => {
	const res = apiRequestHandler({
		url: `/sites/site-owner/${siteOwnerUuid}?page=${page}&size=${size}`,
		data: {},
		method: "get",
	});
	return res;
};

export const CreateChargingStations = (params: ChargingStationParams): any => {
	const res = apiRequestHandler({
		url: "/charging-stations/add",
		data: params,
		method: "post",
	});
	return res;
};

export const UpdateChargingStations = ({
	uuid,
	...rest
}: ChargingStationParams): any => {
	const res = apiRequestHandler({
		url: `/charging-stations/${uuid}`,
		data: rest,
		method: "put",
	});
	return res;
};

export const GetChargingStations = (): any => {
	const res = apiRequestHandler({
		url: `/charging-stations`,
		data: {},
		method: "get",
	});
	return res;
};

export const GetChargingStationsBySiteUUID = (siteUuid: string): any => {
	const res = apiRequestHandler({
		url: `/charging-stations/site-uuid/${siteUuid}`,
		data: {},
		method: "get",
	});
	return res;
};

export const GetSiteBySiteUUID = (siteUuid: string): any => {
	const res = apiRequestHandler({
		url: `/sites/view/${siteUuid}`,
		data: {},
		method: "get",
	});
	return res;
};
