import { EVCardParams } from "types/EVCards";
import apiRequestHandler from "utils/apiRequestHandler";

export const getAllUsers = (): any => {
	const res = apiRequestHandler({
		url: `/users`,
		data: {},
		method: "get",
	});
	return res;
};

export const getAllVehiclesByUserUuid = (userUuid = ""): any => {
	const res = apiRequestHandler({
		url: `/vehicles/user/${userUuid}`,
		data: {},
		method: "get",
	});
	return res;
};

export const createEVCard = (params: EVCardParams): any => {
	const res = apiRequestHandler({
		url: `/ev-cards/add`,
		data: params,
		method: "post",
	});
	return res;
};

export const updateEVCard = ({ uuid, ...rest }: EVCardParams): any => {
	const res = apiRequestHandler({
		url: `/ev-cards/${uuid}`,
		data: rest,
		method: "put",
	});
	return res;
};

export const getAllEVCards = (page = 1, size = 2): any => {
	const res = apiRequestHandler({
		url: `/ev-cards/?page=${page}&size=${size}`,
		data: {},
		method: "get",
	});
	return res;
};

export const getEVCardByUuid = (cardUuid: string): any => {
	const res = apiRequestHandler({
		url: `/ev-cards/view/${cardUuid}`,
		data: {},
		method: "get",
	});
	return res;
};
