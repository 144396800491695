import { AuthContext } from "context/AuthContext";
import usePermissionGate from "hooks/usePermissionGate";
import { ReactNode, useContext } from "react";

export default function PermissionsGate({
	children,
	scopes = [],
}: {
	children: ReactNode;
	scopes: string[];
}) {
	const { user } = useContext(AuthContext);
	const { permissionNames } = user || {};
	const { hasPermission } = usePermissionGate({ permissionNames, scopes });

	if (!hasPermission) return null;

	return <div>{children}</div>;
}
