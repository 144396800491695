import { useTranslation } from "react-i18next";
import Breadcrumbs from "components/breadcrumbs";
import "antd/dist/antd.min.css";
import { AutoComplete, Checkbox, Form, Input, Select } from "antd";
import { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import { BreadCrumbsProps } from "types";
import "react-quill/dist/quill.snow.css";
import classnames from "classnames";
import Button from "components/common/Button";
import AddContact from "./AddContact";

const CreateTicket = ({
	handleSubmitAddContact,
	contactOptions,
	issueTypeOptions,
	sourceOptions,
	statusOptions,
	priorityOptions,
	departmentOptions,
	defaultAgentOptions,
	companyOptions,
}: {
	contactOptions: any;
	issueTypeOptions: any;
	sourceOptions: any;
	statusOptions: any;
	priorityOptions: any;
	departmentOptions: any;
	defaultAgentOptions: any;
	companyOptions: any;
	handleSubmitAddContact: (data: any) => void;
}) => {
	const { t } = useTranslation(["support", "navigation"]);
	const { Option } = Select;
	const [descriptionValue, setDescriptionValue] = useState("");
	const [form] = Form.useForm();
	const selectedDepartment = Form.useWatch("department", form);
	const [showCC, setShowCC] = useState(false);
	const [showAddContactModal, setShowAddContactModal] = useState(false);
	const [agentOptions, setAgentOptions] = useState(defaultAgentOptions);
	const [contactSearchResult, setContactSearchResult] = useState<string[]>([]);
	const [ccSearchResult, setCcSearchResult] = useState<string[]>([]);
	const [departmentSearchResult, setDepartmentSearchResult] = useState<
		string[]
	>([]);
	const [agentSearchResult, setAgentSearchResult] = useState<string[]>([]);

	/* Search handling functions for auto complete inputs */
	const handleAutoCompleteSearch = (
		searchTerm: string,
		defaultOptions: [],
		setResFunc: (res: any) => void
	) => {
		const result = defaultOptions.filter((element: any) =>
			element.name.toLowerCase().includes(searchTerm.toLowerCase())
		);
		setResFunc(result);
		if (!searchTerm) {
			setResFunc(defaultOptions);
		}
	};

	/* Filter agent options by selected department */
	const filterAgents = () => {
		if (selectedDepartment) {
			setAgentOptions(
				defaultAgentOptions.filter(
					(agent: any) => agent.department === selectedDepartment
				)
			);
		}
	};

	useEffect(() => filterAgents(), [selectedDepartment]);

	const formItemLayout = {
		labelCol: { span: 24 },
		wrapperCol: { span: 24 },
	};

	const routes: BreadCrumbsProps[] = [
		{
			path: "/dashboards",
			breadcrumbName: t("home", { ns: "navigation" }),
		},
		{
			path: "/support",
			breadcrumbName: t("support", { ns: "navigation" }),
		},
		{
			path: "/support/create-ticket",
			breadcrumbName: t("createSupportTicket"),
		},
	];

	const toggleCC = () => {
		setShowCC(!showCC);
	};

	const toggleAddContactModal = () => {
		setShowAddContactModal(!showAddContactModal);
	};

	const onFinish = (values: any) => {
		// eslint-disable-next-line no-console
		console.log("Received values of form: ", values);
	};

	return (
		<>
			<Breadcrumbs breadCrumb={routes} />
			<div className="bg-white px-10 md:px-24 lg:px-60 py-20 mt-7">
				<Form
					{...formItemLayout}
					form={form}
					name="register"
					onFinish={onFinish}
					scrollToFirstError
				>
					{/* Contact input */}
					<Form.Item
						name="contact"
						label={t("contact")}
						rules={[
							{
								required: true,
								message: t("errorMsg.contact"),
							},
						]}
					>
						<AutoComplete
							onSearch={(term) =>
								handleAutoCompleteSearch(
									term,
									contactOptions,
									setContactSearchResult
								)
							}
							placeholder={t("typeToSearch")}
							className="contact-auto-complete"
							allowClear
							/* Refer to index.css for the "contact-auto-complete" class */
						>
							{contactSearchResult.map((contact: any) => (
								<Option key={contact.key} value={contact.name}>
									&quot;{contact.name}&quot; &lt;{contact.email}&gt;
								</Option>
							))}
						</AutoComplete>
					</Form.Item>

					{/* Add new contact and Add cc buttons */}
					<div className="text-right">
						<button
							type="button"
							className={classnames(
								!showCC ? "mr-4" : "",
								" text-primary-color"
							)}
							onClick={toggleAddContactModal}
						>
							{t("addNewContact")}
						</button>
						{!showCC && (
							<>
								|
								<button
									type="button"
									className="text-primary-color ml-4"
									onClick={toggleCC}
								>
									{t("addCc")}
								</button>
							</>
						)}
					</div>

					{/* CC input */}
					{showCC && (
						<>
							<Form.Item name="cc" label="Cc">
								<AutoComplete
									onSearch={(term) =>
										handleAutoCompleteSearch(
											term,
											contactOptions,
											setCcSearchResult
										)
									}
									placeholder={t("typeToSearch")}
									className="contact-auto-complete"
									allowClear
									/* Refer to index.css for the "contact-auto-complete" class */
								>
									{ccSearchResult.map((contact: any) => (
										<Option key={contact.key} value={contact.name}>
											&quot;{contact.name}&quot; &lt;{contact.email}&gt;
										</Option>
									))}
								</AutoComplete>
							</Form.Item>
							<div className="text-right">
								<button
									type="button"
									className="text-primary-color"
									onClick={toggleCC}
								>
									{t("hideCc")}
								</button>
							</div>
						</>
					)}

					{/* Subject input */}
					<Form.Item
						name="subject"
						label={t("subject")}
						rules={[
							{
								required: true,
								message: t("errorMsg.subject"),
							},
						]}
					>
						<Input />
					</Form.Item>

					{/* Issue Type select */}
					<Form.Item
						name="type"
						label={t("type")}
						rules={[
							{
								required: true,
								message: t("errorMsg.type"),
							},
						]}
					>
						<Select>
							{issueTypeOptions.map((issueType: any) => (
								<Option key={issueType.key} value={issueType.name}>
									{t(issueType.name)}
								</Option>
							))}
						</Select>
					</Form.Item>

					{/* Source select */}
					<Form.Item
						name="source"
						label={t("source")}
						rules={[
							{
								required: true,
								message: t("errorMsg.source"),
							},
						]}
					>
						<Select>
							{sourceOptions.map((sourceType: any) => (
								<Option key={sourceType.key} value={sourceType.name}>
									{t(sourceType.name)}
								</Option>
							))}
						</Select>
					</Form.Item>

					{/* Status select */}
					<Form.Item
						name="status"
						label={t("status")}
						rules={[
							{
								required: true,
								message: t("errorMsg.status"),
							},
						]}
					>
						<Select>
							{statusOptions.map((status: any) => (
								<Option key={status.key} value={status.name}>
									{t(status.name)}
								</Option>
							))}
						</Select>
					</Form.Item>

					{/* Priority select */}
					<Form.Item
						name="priority"
						label={t("priority")}
						rules={[
							{
								required: true,
								message: t("errorMsg.priority"),
							},
						]}
					>
						<Select>
							{priorityOptions.map((priority: any) => (
								<Option key={priority.key} value={priority.name}>
									{t(priority.name)}
								</Option>
							))}
						</Select>
					</Form.Item>

					{/* Department select */}
					<Form.Item
						name="department"
						label={t("department")}
						rules={[
							{
								required: true,
								message: t("errorMsg.department"),
							},
						]}
					>
						<AutoComplete
							onSearch={(term) =>
								handleAutoCompleteSearch(
									term,
									departmentOptions,
									setDepartmentSearchResult
								)
							}
							placeholder={t("typeToSearch")}
							className="contact-auto-complete"
							allowClear
							/* Refer to index.css for the "contact-auto-complete" class */
							onClear={() => setAgentSearchResult(defaultAgentOptions)}
						>
							{departmentSearchResult.map((department: any) => (
								<Option key={department.key} value={department.name}>
									{department.name}
								</Option>
							))}
						</AutoComplete>
					</Form.Item>

					{/* Agent select */}
					<div className="border-l-2 pl-4 py-1 mb-4">
						<Form.Item
							name="agent"
							label={t("agent")}
							style={{ marginBottom: "1%" }}
							rules={[
								{
									required: true,
									message: t("errorMsg.agent"),
								},
							]}
						>
							<AutoComplete
								onSearch={(term) =>
									handleAutoCompleteSearch(
										term,
										agentOptions,
										setAgentSearchResult
									)
								}
								placeholder={
									!selectedDepartment
										? t("selectDepartmentFirst")
										: t("typeToSearch")
								}
								className="contact-auto-complete"
								disabled={!selectedDepartment}
								allowClear
								/* Refer to index.css for the "contact-auto-complete" class */
							>
								{agentSearchResult.map((agent: any) => (
									<Option key={agent.key} value={agent.name}>
										{agent.name}
									</Option>
								))}
							</AutoComplete>
						</Form.Item>
					</div>

					{/* Description input */}
					<Form.Item
						name="description"
						label={t("description")}
						rules={[
							{
								required: true,
								message: t("errorMsg.description"),
							},
						]}
					>
						<ReactQuill
							value={descriptionValue}
							onChange={setDescriptionValue}
							className="h-32 mb-10"
						/>
					</Form.Item>

					<hr />

					<div className="mt-5 flex justify-between">
						<Form.Item name="createAnother" valuePropName="checked">
							<Checkbox>{t("createAnother")}</Checkbox>
						</Form.Item>
						<Form.Item>
							<div className="flex">
								<Button
									type="button"
									className="px-4 py-2 rounded-sm border border-gray-300 mr-4 hover:bg-gray-100"
									label={t("cancel")}
									handleClick={() => window.location.replace("/support")}
								/>
								<Button
									className="px-4 py-2 rounded-sm bg-primary-color text-white hover:bg-green-800"
									type="submit"
									label={t("create")}
								/>
							</div>
						</Form.Item>
					</div>
				</Form>
			</div>

			<AddContact
				handleShowModal={toggleAddContactModal}
				showModal={showAddContactModal}
				isLoading={false}
				companyOptions={companyOptions}
				onSubmitForm={handleSubmitAddContact}
			/>
		</>
	);
};

export default CreateTicket;
