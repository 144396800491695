import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import Accordion from "components/common/Accordion";
import MoreOptionsMenu from "components/common/MoreOptionsMenu";
import SearchInput from "components/common/SearchInput";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MakeParams, MakeResponse } from "types/Settings";
import { VehicleMakeDataSource } from "types/Stations";

const MakesAccordion = ({
	makeData = [],
	isLoading,
	handleEditMake,
}: {
	makeData: MakeResponse[];
	isLoading: boolean;
	handleEditMake: (make: MakeParams) => void;
}) => {
	const [tableData, setTableData] = useState<VehicleMakeDataSource[]>([]);
	const { t } = useTranslation(["common", "settings"]);

	useEffect(() => {
		setTableData(dataSource);
	}, [makeData]);

	const dataSource = makeData.map(
		({ uuid: key, name, description, enabled }) => ({
			key,
			name,
			uuid: key,
			description,
			enabled,
		})
	);

	/* Show the search result in the table */
	const handleSearch = (searchTerm: string) => {
		const result = dataSource.filter(
			(data: VehicleMakeDataSource) =>
				data.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0 ||
				data.description.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
		);
		setTableData(result);
		if (!searchTerm) {
			setTableData(dataSource);
		}
	};

	const columns: ColumnsType<VehicleMakeDataSource> = [
		{
			dataIndex: "name",
			title: t("name"),
			key: "name",
			sorter: (a, b) => a.name.length - b.name.length,
		},
		{
			dataIndex: "description",
			title: t("description"),
			key: "description",
		},
		{
			title: t("action"),
			key: "operation",
			fixed: "right",
			align: "center",
			width: 100,
			render: (item) => <MoreOptionsMenu menuList={List} data={item} />,
		},
	];

	const List = [
		{
			label: t("edit"),
			onClick: (data: MakeParams) => handleEditMake(data),
		},
	];

	return (
		<Accordion title={t("vehicles.vehicleMake", { ns: "settings" })}>
			<div className="mb-5">
				<SearchInput
					handleSubmit={handleSearch}
					placeholder={t("vehicles.searchMakePlaceholder", { ns: "settings" })}
				/>
			</div>
			<Table
				loading={isLoading}
				dataSource={tableData || []}
				columns={columns}
				scroll={{ y: 450, x: 500 }}
				pagination={{
					// total: pageTotal,
					pageSizeOptions: ["10", "20", "50", "100"],
					showSizeChanger: true,
					// onChange: (page, pageSize) => handlePageChange(page, pageSize),
				}}
			/>
		</Accordion>
	);
};

export default MakesAccordion;
