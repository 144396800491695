import { Disclosure } from "@headlessui/react";
import classNames from "classnames";
import { ReactNode } from "react";
import { IoIosArrowDown } from "react-icons/io";

const Accordion = ({
	children,
	title,
	link,
	url,
	onClick,
}: {
	children: ReactNode;
	title: string;
	link?: boolean;
	url?: string;
	onClick?: () => void;
}) => (
	<Disclosure>
		{({ open }) => (
			<div className="my-2">
				<Disclosure.Button
					onClick={onClick}
					className={classNames(
						"p-3 border bg-white w-full items-center flex justify-between",
						!open ? "rounded-md" : "rounded-t-md"
					)}
				>
					{!link ? (
						<span className="font-semibold text-base">{title}</span>
					) : (
						<a href={url} className="text-base text-primary-color underline">
							{title}
						</a>
					)}
					<div className="p-2 border rounded-md">
						<IoIosArrowDown
							size={24}
							className={`${open ? "transform rotate-180" : ""}`}
						/>
					</div>
				</Disclosure.Button>

				<Disclosure.Panel className="text-gray-500 bg-white w-full p-5">
					{children}
				</Disclosure.Panel>
			</div>
		)}
	</Disclosure>
);

export default Accordion;
