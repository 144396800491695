import { Switch } from "@headlessui/react";
import {
	AddressField,
	SubmitButton,
	TextField,
} from "components/form/InputField";
import Modal from "components/modals/Modal";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { SiteOwnerParams, SiteOwnerResponse } from "types/Stations";
import { testCanadianPostalCode } from "utils";

const AddEditSiteOwners = ({
	handleShowModal,
	showModal,
	selectedSiteOwner,
	isLoading,
	onSubmitForm,
}: {
	handleShowModal: (data?: SiteOwnerResponse) => void;
	showModal: boolean;
	isLoading: boolean;
	onSubmitForm: (params: SiteOwnerParams) => void;
	selectedSiteOwner: SiteOwnerResponse | null;
}) => {
	const { t } = useTranslation(["common", "sites"]);
	const { address, name, enabled } = selectedSiteOwner || {};
	const { city, country, postalCode, region, addressLine1 } = address || {};

	const [isEnabled, setIsEnabled] = useState(!enabled);

	const form = useForm<SiteOwnerParams>({
		defaultValues: {
			addressLine1: addressLine1 || "",
			name: name || "",
			enabled: !enabled,
			region: region || "",
			postalCode: postalCode || "",
			country: country || "",
			city: city || "",
		},
	});

	const {
		handleSubmit,
		register,
		reset,
		setError: setFormError,
		setValue,
	} = form;

	useEffect(() => {
		reset({
			addressLine1: addressLine1 || "",
			name: name || "",
			region: region || "",
			postalCode: postalCode || "",
			country: country || "",
			city: city || "",
		});
	}, [selectedSiteOwner]);

	const onSubmit = async (props: SiteOwnerParams) => {
		let params = props;

		if (selectedSiteOwner && selectedSiteOwner.uuid) {
			params = { ...props, enabled: isEnabled, uuid: selectedSiteOwner.uuid };
		} else {
			params = { ...props, enabled: isEnabled };
		}
		onSubmitForm(params);
		handleShowModal();
	};

	return (
		<Modal
			title={t(selectedSiteOwner ? "updateSiteOwner" : "createSiteOwner", {
				ns: "sites",
			})}
			show={showModal}
			onClose={() => handleShowModal()}
		>
			<FormProvider {...form}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="mb-2">
						<TextField
							className="mb-2"
							name="name"
							label={t("name")}
							required
							ref={register({ required: true })}
						/>
					</div>

					<div className="mt-2">
						<AddressField
							data-testid="address"
							className="mb-2"
							name="addressLine1"
							label={t("locations.address")}
							placeholder={t("locations.addressPlaceholder")}
							required
							getLocationData={(data: any) => {
								const postcode = data.formatted_address.split(",")[2].trim();
								const isValidPostCode = testCanadianPostalCode(
									postcode.slice(3)
								);

								if (!isValidPostCode) {
									const err = {
										message: t("locations.invalidAddressMsg"),
										type: "manual",
									};

									setFormError("addressLine1", {
										type: "manual",
										message: t("locations.selectFullAddress"),
									});
									setFormError("city", err);
									setFormError("region", err);
									setFormError("country", err);
									setFormError("postalCode", err);
									return;
								}

								const addressLine = data.formatted_address.split(",")[0].trim();
								const cityData = data.formatted_address.split(",")[1].trim();
								const province = data.formatted_address
									.split(",")[2]
									.trim()
									.split(" ")[0];
								const countryData = data.formatted_address.split(",")[3].trim();

								setValue("city", cityData);
								setValue("region", province);
								setValue("country", countryData);
								setValue("postalCode", postcode.slice(3));
								setValue("addressLine1", addressLine);
							}}
							ref={register({ required: true })}
						/>
					</div>

					<div className="mt-2">
						<TextField
							data-testid="city"
							className="mb-2"
							name="city"
							label={t("locations.city")}
							required
							disabled
							ref={register({ required: true })}
						/>
					</div>
					<div className="mt-2">
						<TextField
							data-testid="signin-email"
							className="mb-2"
							name="region"
							label={t("locations.province")}
							required
							disabled
							ref={register({ required: true })}
						/>
					</div>
					<div className="mt-2">
						<TextField
							data-testid="postalCode"
							className="mb-2"
							name="postalCode"
							label={t("locations.postalCode")}
							required
							disabled
							ref={register({ required: true })}
						/>
					</div>

					<div className="mt-2">
						<TextField
							data-testid="country"
							className="mb-2"
							name="country"
							label={t("locations.country")}
							required
							disabled
							ref={register({ required: true })}
						/>
					</div>

					{selectedSiteOwner && (
						<Switch.Group>
							<div className="mt-4  flex items-center">
								<Switch.Label
									passive
									className="mr-10 font-medium text-gray-700"
								>
									{t("enableSiteOwner", { ns: "sites" })}
								</Switch.Label>
								<Switch
									checked={isEnabled}
									onChange={() => setIsEnabled(!isEnabled)}
									className={`${
										isEnabled ? "bg-primary-color" : "bg-gray-200"
									} relative inline-flex h-6 w-12 items-center rounded-full`}
								>
									<span
										className={`${
											!isEnabled ? "translate-x-6" : "translate-x-1"
										} inline-block h-4 w-4 transform rounded-full bg-white`}
									/>
								</Switch>
							</div>
						</Switch.Group>
					)}

					<SubmitButton
						type="submit"
						loading={isLoading}
						label={t(
							selectedSiteOwner ? "updateSiteOwner" : "createSiteOwner",
							{ ns: "sites" }
						)}
						className="mt-8 flex m-auto my-5 text-center"
					/>
				</form>
			</FormProvider>
		</Modal>
	);
};
export default AddEditSiteOwners;
