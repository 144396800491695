import SingleSiteMap from "components/common/SingleSiteMap";
import Modal from "components/modals/Modal";
import { SiteResponse, ChargingStationResponse } from "types/Stations";

const SiteMap = ({
	handleShowModal,
	showModal,
	site,
	chargers,
	handleFetchChargers,
	handleToggleSiteDetail,
	isLoading,
}: {
	handleShowModal: () => void;
	showModal: boolean;
	site: SiteResponse | null;
	handleFetchChargers: (uuid: string) => void;
	chargers: ChargingStationResponse[];
	handleToggleSiteDetail: () => void;
	isLoading: boolean;
}) => (
	<Modal
		title={site?.name}
		size="md"
		show={showModal}
		onClose={handleShowModal}
	>
		{site && (
			<SingleSiteMap
				site={site}
				isLoading={isLoading}
				chargers={chargers}
				handleFetchChargers={handleFetchChargers}
				handleToggleSiteDetail={handleToggleSiteDetail}
			/>
		)}
	</Modal>
);
export default SiteMap;
