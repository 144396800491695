import { useTranslation } from "react-i18next";

const OverdueIndicator = ({ className }: { className?: string }) => {
	const { t } = useTranslation(["support"]);
	return (
		<div className={className}>
			<span className="bg-red-200 py-1 px-2 rounded border border-red-600 text-red-700 text-sm">
				{t("overdue")}
			</span>
		</div>
	);
};

export default OverdueIndicator;
