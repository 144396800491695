import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Table, { ColumnsType } from "antd/lib/table";
import { SubmitButton } from "components/form/InputField";
import MoreOptionsMenu from "components/common/MoreOptionsMenu";
import SearchInput from "components/common/SearchInput";
import ActionDropdown from "components/common/ActionDropdown";
import {
	ChargingStationResponse,
	ChargingStationDataSource,
} from "types/Stations";

const ChargersList = ({
	chargingStations,
	pageTotal,
	handlePageChange,
	handleToggleShowAddEditChargingStationModal,
}: {
	chargingStations: ChargingStationResponse[];
	pageTotal: number;
	handlePageChange: (page: number, pageSize: number) => void;
	handleToggleShowAddEditChargingStationModal: (
		data?: ChargingStationDataSource
	) => void;
}) => {
	const { t } = useTranslation(["sites", "common"]);

	const [chargingStationsTableData, setChargingStationsTableData] = useState<
		ChargingStationDataSource[]
	>([]);

	useEffect(() => {
		const stations: ChargingStationDataSource[] = chargingStations.map(
			({
				availabilityStatus,
				chargingStationIdentifier,
				connectors,
				iccid,
				meterSerialNumber,
				meterType,
				model,
				registrationStatus,
				serialNumber,
				vendor,
				uuid: key,
			}) => ({
				key,
				availabilityStatus,
				chargingStationIdentifier,
				connectors: connectors.length,
				iccid,
				meterSerialNumber,
				meterType,
				model,
				registrationStatus,
				serialNumber,
				vendor,
			})
		);

		setChargingStationsTableData(stations);
	}, [chargingStations]);

	const columns: ColumnsType<ChargingStationDataSource> = [
		{
			title: t("chargers.chargerInfo.vendor"),
			dataIndex: "vendor",
			key: "vendor",
		},
		{
			dataIndex: "connectors",
			title: t("chargers.chargerInfo.connectors"),
			key: "connectors",
		},
		{
			dataIndex: "chargingStationIdentifier",
			title: t("chargers.chargerInfo.identifier"),
			key: "chargingStationIdentifier",
		},
		{
			dataIndex: "availabilityStatus",
			title: t("chargers.chargerInfo.availabilityStatus"),
			key: "availabilityStatus",
		},
		{
			dataIndex: "iccid",
			title: t("chargers.chargerInfo.iccid"),
			key: "iccid",
		},
		{
			dataIndex: "meterSerialNumber",
			title: t("chargers.chargerInfo.meterSerialNumber"),
			key: "meterSerialNumber",
		},
		{
			dataIndex: "meterType",
			title: t("chargers.chargerInfo.meterType"),
			key: "meterType",
		},
		{
			dataIndex: "meterSerialNumber",
			title: t("chargers.chargerInfo.meterSerialNumber"),
			key: "meterSerialNumber",
		},
		{
			dataIndex: "serialNumber",
			title: t("chargers.chargerInfo.serialNumber"),
			key: "serialNumber",
		},
		{
			title: t("action", { ns: "common" }),
			key: "operation",
			fixed: "right",
			align: "center",
			width: 100,
			render: (item) => <MoreOptionsMenu menuList={List} data={item} />,
		},
	];

	const List = [
		{
			label: t("edit", { ns: "common" }),
			onClick: (data: ChargingStationDataSource) =>
				handleToggleShowAddEditChargingStationModal(data),
		},
	];

	return (
		<>
			<div className="md:flex items-start lg:items-center justify-between mb-10 sm:mb-5">
				<SubmitButton
					className="rounded-[5px] mb-3 sm:mb-2"
					type="button"
					onClick={() => {
						handleToggleShowAddEditChargingStationModal();
					}}
					label={t("chargers.createCharger")}
				/>
				<div className="flex flex-wrap lg:flex-nowrap gap-3 md:w-3/5 md:justify-end">
					<ActionDropdown
						options={[]}
						onClick={() => {}}
						selectedOption=""
						setSelectedOption={(_) => {}}
						wrapperMargin="sm:mr-7"
						wrapperWidth="w-full sm:w-52"
						buttonWidth="w-14"
						roundedClass="rounded-lg"
					/>
					<div className="w-full sm:w-auto">
						<SearchInput
							handleSubmit={() => {}}
							placeholder="Search Chargers"
						/>
					</div>
				</div>
			</div>
			<Table
				dataSource={chargingStationsTableData}
				columns={columns}
				scroll={{ y: 240 }}
				pagination={{
					total: pageTotal,
					pageSizeOptions: ["10", "20", "50", "100"],
					showSizeChanger: true,
					onChange: (page, pageSize) => handlePageChange(page, pageSize),
				}}
			/>
		</>
	);
};

export default ChargersList;
