import {
	useState,
	useMemo,
	useCallback,
	useRef,
	useEffect,
	useContext,
} from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import config from "config";
import { ChargingStationResponse, SiteResponse } from "types/Stations";
import { SiteContext } from "context/SitesContext";
import Places from "./Places";
import InfoCard from "./InfoCard";
import Loading from "../../assets/svg/assets/Loading";

type LatLngLiteral = google.maps.LatLngLiteral;
type MapOptions = google.maps.MapOptions;

const containerStyle = {
	width: "100vw",
	height: "100vh",
};

const MapView = ({
	isLoading,
	sites,
	chargers,
	inViewLoading,
	handleFetchChargers,
	handleToggleSiteDetail,
}: {
	isLoading: boolean;
	sites: SiteResponse[];
	inViewLoading: boolean;
	handleFetchChargers: (uuid: string) => void;
	chargers: ChargingStationResponse[];
	handleToggleSiteDetail: () => void;
}) => {
	const [coordinates, setCoordinates] = useState<{ lat: number; lng: number }>({
		lat: 49.246292,
		lng: -123.116226,
	});

	const { handleSelectedSite, selectedSite } = useContext(SiteContext);

	const mapRef = useRef<GoogleMap>();
	const center = useMemo<LatLngLiteral>(
		() => ({ lat: coordinates.lat, lng: coordinates.lng }),
		[coordinates]
	);
	const options = useMemo<MapOptions>(
		() => ({
			mapId: "87536500f6766fbe",
			disableDefaultUI: true,
			clickableIcons: false,
			gestureHandling: "greedy",
		}),
		[]
	);

	const onLoad = useCallback((map: any) => (mapRef.current = map), []);

	const { isLoaded } = useJsApiLoader({
		id: "google-map-script",
		libraries: ["places"],
		googleMapsApiKey: config.googleClientId,
	});

	const getLocation = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position) => {
				setCoordinates({
					lat: position.coords.latitude,
					lng: position.coords.longitude,
				});
			});
		}
	};

	useEffect(() => {
		getLocation();
	}, []);

	if (!isLoaded || isLoading) {
		return (
			<div className="h-[100vh] flex justify-center items-center">
				<Loading />
			</div>
		);
	}

	const handleCenterMap = () => {
		mapRef.current?.panTo(coordinates);
	};

	return (
		<GoogleMap
			mapContainerStyle={containerStyle}
			center={center}
			zoom={12}
			options={options}
			onLoad={onLoad}
		>
			<Places
				getLocation={handleCenterMap}
				setOffice={(position) => {
					mapRef.current?.panTo(position);
				}}
			/>

			{sites.map((site) => (
				<Marker
					key={site.uuid}
					onClick={() => {
						handleSelectedSite(site);
						handleFetchChargers(site.uuid);
					}}
					position={{
						lat: Number(site.locationLattitude),
						lng: Number(site.locationLongitude),
					}}
				/>
			))}

			{selectedSite && (
				<InfoCard
					handleShowMore={handleToggleSiteDetail}
					selectedSite={selectedSite}
					isLoading={inViewLoading}
					chargers={chargers}
					onCloseClick={() => {
						handleSelectedSite(null);
					}}
				/>
			)}
		</GoogleMap>
	);
};

export default MapView;
