import { Dialog, Transition } from "@headlessui/react";
import { Fragment, ReactElement } from "react";

const SlidePage = ({
	title,
	children,
	toggleOpen,
	open,
}: {
	title: string;
	children: ReactElement;
	toggleOpen: () => void;
	open: boolean;
}) => (
	<Transition.Root show={open} as={Fragment}>
		<Dialog as="div" className="relative z-10" onClose={toggleOpen}>
			<Transition.Child
				as={Fragment}
				enter="ease-in-out duration-500"
				enterFrom="opacity-0"
				enterTo="opacity-100"
				leave="ease-in-out duration-500"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
			>
				<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
			</Transition.Child>

			<div className="fixed inset-0 overflow-hidden">
				<div className="absolute inset-0 overflow-hidden">
					<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
						<Transition.Child
							as={Fragment}
							enter="transform transition ease-in-out duration-500 sm:duration-700"
							enterFrom="translate-x-full"
							enterTo="translate-x-0"
							leave="transform transition ease-in-out duration-500 sm:duration-700"
							leaveFrom="translate-x-0"
							leaveTo="translate-x-full"
						>
							<Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
								<Transition.Child
									as={Fragment}
									enter="ease-in-out duration-500"
									enterFrom="opacity-0"
									enterTo="opacity-100"
									leave="ease-in-out duration-500"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
								>
									<div className="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
										<button
											type="button"
											className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
											onClick={toggleOpen}
										>
											<span className="sr-only">Close panel</span>
											<svg
												className="h-6 w-6"
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												strokeWidth="2"
												stroke="currentColor"
												aria-hidden="true"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													d="M6 18L18 6M6 6l12 12"
												/>
											</svg>
										</button>
									</div>
								</Transition.Child>
								<div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
									<div className="px-4 sm:px-6">
										<Dialog.Title className="text-lg font-medium text-gray-900">
											{title}
										</Dialog.Title>
									</div>
									<div className="relative mt-6 flex-1 px-4 sm:px-6">
										{children}
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</div>
		</Dialog>
	</Transition.Root>
);

export default SlidePage;
