import { addRoles, fetchRoles, updateRoles } from "api/application-management";
import RoleSettings from "components/applicationSettings/roles";
import { AlertContext } from "context/AlertContext";
import { ApplicationManagementContext } from "context/ApplicationManagementContext";
import DashboardLayout from "Layouts/DashboardLayout";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ErrorResponse } from "types";
import { RolesParams, RolesResponse } from "types/ApplicationManagement";

const Roles = () => {
	const queryClient = useQueryClient();
	const { t } = useTranslation(["constants", "navigation"]);

	/* Fetch Roles and sort it by the date they were created */
	const { isLoading: rolesLoading, data: rolesData } = useQuery<
		RolesResponse[]
	>("roles", () => fetchRoles());
	const sortedRolesData = rolesData?.sort((a, b) =>
		a.createdAt < b.createdAt ? 1 : -1
	);

	const { handleShowAlert } = useContext(AlertContext);
	const { selectedRole } = useContext(ApplicationManagementContext);

	const { mutateAsync, isLoading } = useMutation<
		RolesResponse,
		ErrorResponse,
		RolesParams
	>(addRoles, {
		onSuccess: () => {
			queryClient.invalidateQueries("roles");
		},
	});

	const { mutateAsync: updateMutateAsync, isLoading: updateIsloading } =
		useMutation<RolesResponse, ErrorResponse, RolesParams>(updateRoles, {
			onSuccess: () => {
				queryClient.invalidateQueries("roles");
			},
		});

	const handleSubmitAddEditRoles = async (
		props: RolesParams
	): Promise<boolean> => {
		const mutate = !selectedRole ? mutateAsync : updateMutateAsync;

		const { message } = await mutate({ uuid: selectedRole?.uuid, ...props });

		if (!message) return true;

		if (
			message === t("Role created successfully", { lng: "en" }) ||
			message === t("Role updated successfully", { lng: "en" })
		) {
			handleShowAlert({ message, severity: "success" });
			return true;
		}

		handleShowAlert({ message, severity: "error" });

		return false;
	};

	return (
		<DashboardLayout>
			<div className="flex flex-col">
				<div className="mb-5">
					<span className="text-2xl font-medium pb-10">
						{t("roles", { ns: "navigation" })}
					</span>
				</div>
				<RoleSettings
					roles={sortedRolesData || []}
					isLoading={rolesLoading || isLoading || updateIsloading}
					handleSubmitAddEditRoles={handleSubmitAddEditRoles}
				/>
			</div>
		</DashboardLayout>
	);
};

export default Roles;
