import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

const ActionDropdown = ({
	options,
	onClick,
	selectedOption,
	setSelectedOption,
	/* Pass the tailwind class to change the width and the height of each element */
	wrapperWidth,
	wrapperHeight = "h-full",
	wrapperMargin,
	buttonWidth,
	roundedClass = "rounded-md",
}: {
	options: { name: string; value: string }[];
	onClick: (value: string) => void;
	selectedOption: string;
	setSelectedOption: (value: string) => void;
	wrapperWidth?: string;
	wrapperMargin?: string;
	wrapperHeight?: string;
	buttonWidth?: string;
	roundedClass?: string;
}) => {
	const { t } = useTranslation(["common"]);

	return (
		<div
			className={classNames(
				"flex justify-evenly items-center bg-white rounded-md",
				[wrapperWidth, wrapperMargin]
			)}
		>
			<select
				onChange={(e) => setSelectedOption(e.target.value)}
				defaultValue=""
				name="actionItem"
				id="actionItem"
				className={classNames("grow border-primary-color mr-3", [
					!selectedOption ? "text-gray-400" : "",
					wrapperHeight,
					roundedClass,
				])}
			>
				<option value="" disabled>
					{t("selectAction")}
				</option>
				{options.map(
					(
						option: {
							value: string;
							name: string;
						},
						i: React.Key
					) => (
						<option key={i} value={option.value}>
							{option.name}
						</option>
					)
				)}
			</select>
			<button
				disabled={!selectedOption}
				onClick={() => onClick(selectedOption)}
				className={classNames(
					"bg-primary-color text-white text-center px-4 py-2",
					[
						selectedOption === "" ? "opacity-50" : "",
						wrapperHeight,
						buttonWidth,
						roundedClass,
					]
				)}
				type="button"
			>
				{t("go")}
			</button>
		</div>
	);
};

export default ActionDropdown;
