import { useTranslation } from "react-i18next";
import Logo from "../../assets/images/logo.png";
import LanguageSwitcher from "./LanguageSwitcher";

const StationsHeader = () => {
	const { t } = useTranslation(["navigation"]);
	return (
		<nav className="h-[15vh] md:h-[7vh] bg-white border-gray-200 flex items-center z-10 fixed w-full">
			<div className="flex w-full items-center justify-between flex-wrap z-auto px-5 gap-3">
				<a href="/" className="flex items-center">
					<img src={Logo} className="mr-3 h-6 sm:h-9" alt="Flowbite Logo" />
				</a>
				<div className="flex items-center md:order-2">
					<div className="px-3">
						<LanguageSwitcher />
					</div>
					<button
						onClick={() => window.location.replace("/dashboard")}
						type="button"
						className="focus:outline-none text-white bg-primary-color hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5"
					>
						{t("dashboard")}
					</button>
				</div>
			</div>
		</nav>
	);
};

export default StationsHeader;
