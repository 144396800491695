import { Alert, Snackbar } from "@mui/material";
import Slide, { SlideProps } from "@mui/material/Slide";

const SlideTransition = (props: SlideProps) => (
	<Slide {...props} direction="left" />
);

const Alerts = ({
	open,
	handleClose,
	message,
	severity = "info",
}: {
	open: boolean;
	handleClose?: () => void;
	message: string;
	severity: "success" | "error" | "warning" | "info";
}) => (
	<Snackbar
		open={open}
		autoHideDuration={6000}
		TransitionComponent={SlideTransition}
		onClose={handleClose}
		anchorOrigin={{ vertical: "top", horizontal: "right" }}
	>
		<Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
			{message}
		</Alert>
	</Snackbar>
);

export default Alerts;
