import { ColumnsType } from "antd/lib/table";
import { Table } from "antd";
import { BreadCrumbsProps } from "types/index";
import {
	PermissionsDataType,
	PermissionsResponse,
} from "types/ApplicationManagement";
import { useEffect, useState } from "react";
import { SubmitButton } from "components/form/InputField";
import Breadcrumbs from "components/breadcrumbs";
import { useTranslation } from "react-i18next";

const AssignPermissions = ({
	permissions,
	isLoading,
	roleName,
	roleUuid,
	previousPermissions,
	handleSubmitPermissions,
}: {
	permissions: PermissionsResponse[];
	isLoading: boolean;
	roleName: string;
	roleUuid: string;
	previousPermissions: string[];
	handleSubmitPermissions: (data: string[]) => void;
}) => {
	const { t } = useTranslation(["navigation", "application", "common"]);

	const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
	const [updated, setUpdated] = useState<boolean>(true);

	useEffect(() => {
		setSelectedRowKeys(previousPermissions);
	}, [previousPermissions]);

	const dataSource = permissions.map(({ name, description }) => ({
		key: name,
		name,
		description,
	}));

	const columns: ColumnsType<PermissionsDataType> = [
		{
			dataIndex: "name",
			title: t("name", { ns: "common" }),
			key: "name",
		},
		{
			dataIndex: "description",
			title: t("description", { ns: "common" }),
			key: "description",
		},
	];

	const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
		setUpdated(false);
		setSelectedRowKeys(newSelectedRowKeys as string[]);
	};

	const routes: BreadCrumbsProps[] = [
		{
			path: "/dashboard",
			breadcrumbName: t("home"),
		},
		{
			path: "/app-settings/roles",
			breadcrumbName: t("roles", { ns: "application" }),
		},
		{
			path: `/app-settings/roles/${roleUuid}`,
			breadcrumbName: roleName,
		},
	];

	return (
		<>
			<Breadcrumbs breadCrumb={routes} />
			<div className="mt-5 mb-5 flex justify-end">
				<SubmitButton
					type="button"
					label={t("save", { ns: "common" })}
					className="mb-5"
					disabled={updated}
					onClick={() => handleSubmitPermissions(selectedRowKeys as string[])}
				/>
			</div>
			<Table
				rowSelection={{
					type: "checkbox",
					onChange: onSelectChange,
					selectedRowKeys,
				}}
				loading={isLoading}
				dataSource={dataSource}
				columns={columns}
				scroll={{ y: "70vh", x: 700 }}
				pagination={{ defaultPageSize: 10, showSizeChanger: true }}
			/>
		</>
	);
};

export default AssignPermissions;
