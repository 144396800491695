import { SubmitButton } from "components/form/InputField";
import { useTranslation } from "react-i18next";

const MakesToolbar = ({
	handleAddMakeModal,
	handleAddModelModal,
}: {
	handleAddMakeModal: () => void;
	handleAddModelModal: () => void;
}) => {
	const { t } = useTranslation(["settings"]);
	return (
		<div className="py-4 justify-end items-end md:items-center flex flex-col md:flex-row">
			<div className="flex justify-between flex-wrap w-1/2 md:w-auto">
				<SubmitButton
					className="my-5 md:mx-5 rounded-[5px]"
					type="button"
					onClick={() => handleAddMakeModal()}
					label={t("vehicles.addVehicleMake")}
				/>
				<SubmitButton
					className="md:my-5 rounded-[5px]"
					type="button"
					onClick={() => handleAddModelModal()}
					label={t("vehicles.addVehicleModel")}
				/>
			</div>
		</div>
	);
};

export default MakesToolbar;
