import UserManagement from "components/usersManagement/users";
import { UserContext } from "context/UserContext";
import DashboardLayout from "Layouts/DashboardLayout";
import { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { UserParams, UsersResponse } from "types/UsersManagement";
import { useTranslation } from "react-i18next";
import {
	createBackOfficeUser,
	getAllUsers,
	updateBackOfficeUser,
} from "api/user-management";
import { ErrorResponse } from "types";
import { AlertContext } from "context/AlertContext";
import { RolesResponse } from "types/ApplicationManagement";
import { fetchRoles } from "api/application-management";
import { GetAllOrganization } from "api/users-management";
import { OrganizationResponse } from "types/UserManagement";

const UsersPage = () => {
	const [pagination, setPaginationState] = useState({
		page: 0,
		pageSize: 10,
		search: "",
	});
	const { t } = useTranslation(["common"]);
	const { selectedUser } = useContext(UserContext);
	const { handleShowAlert } = useContext(AlertContext);

	/* ==============================================================================
		Fetch Queries
	============================================================================== */

	/* Fetch Roles and sort it in the alphabetical order */
	const { isLoading: rolesLoading, data: rolesData } = useQuery<
		RolesResponse[]
	>("roles", fetchRoles);
	const sortedRolesData = rolesData?.sort((a, b) =>
		a.name.localeCompare(b.name)
	);

	const { search, page, pageSize } = pagination;

	const { isLoading: usersLoading, data: usersData } = useQuery<
		RolesResponse[]
	>("users", () => getAllUsers(page, pageSize));

	const usersList: UsersResponse[] = _.get(usersData, "content") || [];
	const totalPages: number = _.get(usersData, "totalPages");

	const sortedUsersData = usersList.sort((a, b) =>
		a.firstName.localeCompare(b.firstName)
	);

	const queryClient = useQueryClient();

	const { mutateAsync, isLoading } = useMutation<
		UsersResponse,
		ErrorResponse,
		UserParams
	>(createBackOfficeUser, {
		onSuccess: () => {
			queryClient.invalidateQueries("users");
		},
		onMutate: (data) => {
			queryClient.setQueryData("users", data);
		},
	});

	const { mutateAsync: updateMutateAsync, isLoading: updateIsLoading } =
		useMutation<UsersResponse, ErrorResponse, UserParams>(
			updateBackOfficeUser,
			{
				onSuccess: () => {
					queryClient.invalidateQueries("users");
				},
				onMutate: (data) => {
					queryClient.setQueryData("users", data);
				},
			}
		);

	const handlePageChange = (pageNumber: number, size: number) => {
		setPaginationState({ page: pageNumber, pageSize: size, search: "" });
	};

	const {
		isLoading: organizationIsLoading,
		data: organizationData,
		refetch,
	} = useQuery("organizations", () =>
		GetAllOrganization(page, pageSize, search)
	);

	const organizations: OrganizationResponse[] =
		_.get(organizationData, "content") || [];

	useEffect(() => {
		refetch();
	}, [pagination]);

	/* ==============================================================================
		A Handler for adding or updating users
	============================================================================== */

	/* For now it just modifies the static data */
	const handleSubmitAddEditUsers = async (
		props: UserParams
	): Promise<boolean> => {
		const mutate = !selectedUser ? mutateAsync : updateMutateAsync;

		const { message } = await mutate({
			uuid: selectedUser?.uuid,
			...props,
		});

		if (!message) return false;

		if (
			message === t("The user was created successfully", { lng: "en" }) ||
			message === t("The user was updated successfully", { lng: "en" })
		) {
			handleShowAlert({ message, severity: "success" });
			return true;
		}

		handleShowAlert({ message, severity: "error" });

		return false;
	};

	const handleSearchOrganization = (searchWord: string) => {
		setPaginationState({ page, pageSize, search: searchWord });
	};

	return (
		<DashboardLayout>
			<div className="flex flex-col">
				<span className="text-2xl font-medium pb-10">{t("users")}</span>
				<UserManagement
					users={sortedUsersData}
					rolesOptions={sortedRolesData || []}
					isLoading={
						rolesLoading ||
						usersLoading ||
						isLoading ||
						updateIsLoading ||
						organizationIsLoading
					}
					organizations={organizations}
					handleSearchOrganization={handleSearchOrganization}
					handleSubmitAddEditUser={handleSubmitAddEditUsers}
					handlePageChange={handlePageChange}
					pageTotal={totalPages}
				/>
			</div>
		</DashboardLayout>
	);
};
export default UsersPage;
