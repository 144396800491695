import { SubmitButton, TextField, Select } from "components/form/InputField";
import Modal from "components/modals/Modal";
import { useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { MakeResponse, VehicleModelParams } from "types/Settings";
import { Switch } from "@headlessui/react";
import { useTranslation } from "react-i18next";

const AddModel = ({
	handleShowModelModal,
	showModal,
	onSubmitForm,
	selectedModel,
	vehicleMakes,
	isLoading,
	handleSelectedModel,
}: {
	handleShowModelModal: () => void;
	showModal: boolean;
	isLoading: boolean;
	vehicleMakes: MakeResponse[];
	selectedModel: VehicleModelParams | null;
	onSubmitForm: (params: VehicleModelParams) => void;
	handleSelectedModel: (model: VehicleModelParams | null) => void;
}) => {
	const { description, name, enabled, make } = selectedModel || {};
	const [isEnabled, setIsEnabled] = useState(!enabled);
	const form = useForm<VehicleModelParams>({
		defaultValues: {
			name: name || "",
			description: description || "",
			vehicleMakeUuid: make || "",
		},
	});
	const { t } = useTranslation(["settings", "common"]);

	const vehicleOptions = vehicleMakes.map(({ uuid, name: vehicleName }) => ({
		value: uuid,
		name: vehicleName,
	}));

	const { handleSubmit, register, reset } = form;

	useEffect(() => {
		reset({
			name: name || "",
			description: description || "",
			vehicleMakeUuid: make || "",
		});
	}, [selectedModel]);

	const onSubmit = async (props: VehicleModelParams) => {
		const {
			name: modelName,
			description: modelDescription,
			vehicleMakeUuid,
		} = props;

		const params = {
			name: modelName,
			description: modelDescription,
			enabled: isEnabled,
			vehicleMakeUuid,
		};

		onSubmitForm(params);
	};

	return (
		<Modal
			title={
				selectedModel
					? t("vehicles.updateVehicleModel")
					: t("vehicles.createVehicleModel")
			}
			show={showModal}
			onClose={() => {
				handleShowModelModal();
				handleSelectedModel(null);
			}}
		>
			<FormProvider {...form}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="mb-2">
						<TextField
							data-testid="name"
							className="mb-2"
							name="name"
							label={t("vehicles.model")}
							required
							ref={register({ required: true })}
							autoComplete="name"
						/>
					</div>

					<div className="mb-2">
						<TextField
							data-testid="description"
							className="mb-2"
							name="description"
							label={t("description", { ns: "common" })}
							required
							ref={register({ required: true })}
							autoComplete="description"
						/>
					</div>

					<div className="mb-2">
						<Select
							rootClass="col-lg-6 mb-2"
							name="vehicleMakeUuid"
							label={t("vehicles.make")}
							placeholder="Select Vehcile Make"
							required
							ref={register({ required: true })}
							options={vehicleOptions}
						/>
					</div>

					{selectedModel && (
						<Switch.Group>
							<div className="mt-4  flex items-center">
								<Switch.Label
									passive
									className="mr-10 font-medium text-gray-700"
								>
									{t("vehicles.enableModel")}
								</Switch.Label>
								<Switch
									checked={isEnabled}
									onChange={() => setIsEnabled(!isEnabled)}
									className={`${
										isEnabled ? "bg-primary-color" : "bg-gray-200"
									} relative inline-flex h-6 w-12 items-center rounded-full`}
								>
									<span
										className={`${
											!isEnabled ? "translate-x-6" : "translate-x-1"
										} inline-block h-4 w-4 transform rounded-full bg-white`}
									/>
								</Switch>
							</div>
						</Switch.Group>
					)}

					<SubmitButton
						type="submit"
						loading={isLoading}
						label={
							selectedModel
								? t("vehicles.updateModel")
								: t("vehicles.createModel")
						}
						className="mt-8 flex m-auto my-5 text-center"
					/>
				</form>
			</FormProvider>
		</Modal>
	);
};
export default AddModel;
