import { SubmitButton, TextField } from "components/form/InputField";
import Modal from "components/modals/Modal";
import { useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { MakeParams } from "types/Settings";
import { Switch } from "@headlessui/react";
import { useTranslation } from "react-i18next";

const AddMake = ({
	handleShowMakeModal,
	showModal,
	selectedMake,
	onSubmitForm,
	isLoading,
	handleSelectedMake,
}: {
	handleShowMakeModal: (data?: any) => void;
	showModal: boolean;
	selectedMake: MakeParams | null;
	onSubmitForm: (params: MakeParams) => void;
	isLoading: boolean;
	handleSelectedMake: (make: MakeParams | null) => void;
}) => {
	const { description, name, enabled } = selectedMake || {};
	const [isEnabled, setIsEnabled] = useState(!enabled);
	const { t } = useTranslation(["common", "settings"]);

	const form = useForm<MakeParams>({
		defaultValues: {
			name: name || "",
			description: description || "",
		},
	});

	const { handleSubmit, register, reset } = form;

	useEffect(() => {
		reset({
			name: name || "",
			description: description || "",
		});
	}, [selectedMake]);

	const onSubmit = async (props: MakeParams) => {
		let params = props;

		params = { ...props, enabled: isEnabled };
		onSubmitForm(params);
	};

	return (
		<Modal
			title={
				selectedMake
					? t("vehicles.updateVehicleMake", { ns: "settings" })
					: t("vehicles.createVehicleMake", { ns: "settings" })
			}
			show={showModal}
			onClose={() => {
				handleShowMakeModal();
				handleSelectedMake(null);
			}}
		>
			<FormProvider {...form}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="mb-2">
						<TextField
							data-testid="name"
							className="mb-2"
							name="name"
							label={t("name")}
							required
							ref={register({ required: true })}
							autoComplete="name"
						/>
					</div>

					<div className="mb-2">
						<TextField
							data-testid="description"
							className="mb-2"
							name="description"
							label={t("description")}
							required
							ref={register({ required: true })}
							autoComplete="description"
						/>
					</div>

					{selectedMake && (
						<Switch.Group>
							<div className="mt-4  flex items-center">
								<Switch.Label
									passive
									className="mr-10 font-medium text-gray-700"
								>
									{t("vehicles.enableMake", { ns: "settings" })}
								</Switch.Label>
								<Switch
									checked={isEnabled}
									onChange={() => setIsEnabled(!isEnabled)}
									className={`${
										isEnabled ? "bg-primary-color" : "bg-gray-200"
									} relative inline-flex h-6 w-12 items-center rounded-full`}
								>
									<span
										className={`${
											!isEnabled ? "translate-x-6" : "translate-x-1"
										} inline-block h-4 w-4 transform rounded-full bg-white`}
									/>
								</Switch>
							</div>
						</Switch.Group>
					)}

					<SubmitButton
						type="submit"
						loading={isLoading}
						label={
							selectedMake
								? t("vehicles.updateMake", { ns: "settings" })
								: t("vehicles.createMake", { ns: "settings" })
						}
						className="mt-8 flex m-auto my-5 text-center"
					/>
				</form>
			</FormProvider>
		</Modal>
	);
};
export default AddMake;
