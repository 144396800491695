import { useTranslation } from "react-i18next";
import { HiOutlineCheck } from "react-icons/hi";

const IssuePopOverMenu = ({
	menuItems,
	subMenuItems,
	selected,
	subSelected,
	selectMenuItem,
	selectSubMenuItem,
}: {
	menuItems: any[];
	subMenuItems?: any[];
	selected: string;
	subSelected?: string;
	selectMenuItem: (item: string) => void;
	selectSubMenuItem?: (item: string) => void;
}) => {
	const { t } = useTranslation(["support"]);
	return (
		<div className="w-44 p-2">
			{menuItems.map((item) => (
				<button
					className="hover:bg-gray-300 focus:bg-green-200 w-full p-1 rounded-sm text-left flex items-center justify-between"
					key={item.key}
					onClick={() => selectMenuItem(item.name)}
				>
					<span>{t(item.name)}</span>
					{selected === item.name && <HiOutlineCheck />}
				</button>
			))}
			{subMenuItems && (
				<div className="border-t mt-2 pt-2">
					{subMenuItems.map((item) => (
						<button
							className="hover:bg-gray-300 focus:bg-green-200 w-full p-1 rounded-sm text-left flex items-center justify-between"
							key={item.key}
							onClick={() => selectSubMenuItem && selectSubMenuItem(item.name)}
						>
							<span>{t(item.name)}</span>
							{subSelected === item.name && <HiOutlineCheck />}
						</button>
					))}
				</div>
			)}
		</div>
	);
};

export default IssuePopOverMenu;
