export const evCardList = [
	{
		key: "1",
		createdAt: "2022-10-21 15:37:24",
		updatedAt: "2022-10-21 15:37:24",
		enabled: true,
		id: 1,
		uuid: "5f5c0942-8fa7-47c0-a9c4-858beb31a7e7",
		createdBy: null,
		deleted: false,
		deletedAt: null,
		tagId: "8200057335",
		cardNumber: "9128149635",
		currency: "CAD",
		balance: 63.67,
		evCardType: "CARD",
		userUuid: "ad3d3faf-24eb-4ca8-b98b-b996bda251ff",
		vehicleUuid: "209392803",
		parentTagId: null,
		maxActiveTransactionCount: 1,
		activeTransactionCount: 0,
		// TODO: Ask backend to add these fields in the evCardList response
		vehicleMake: "Tesla",
		vehicleModel: "Model 3",
	},
	{
		key: "2",
		createdAt: "2022-10-21 11:37:24",
		updatedAt: "2022-10-21 14:37:24",
		enabled: false,
		id: 2,
		uuid: "9eb5712d-9878-463f-a19e-a0e94c63bc66",
		createdBy: null,
		deleted: false,
		deletedAt: null,
		tagId: "9200057335",
		cardNumber: "9210812442",
		currency: "CAD",
		balance: 61.67,
		evCardType: "STICKER",
		userUuid: "bd3d3faf-24eb-4ca8-b98b-b996bda251ff",
		vehicleUuid: "309392803",
		parentTagId: null,
		maxActiveTransactionCount: 1,
		activeTransactionCount: 0,
		vehicleMake: "Toyota",
		vehicleModel: "Prius Prime",
	},
	{
		key: "3",
		createdAt: "2022-10-20 9:37:24",
		updatedAt: "2022-10-20 12:37:24",
		enabled: true,
		id: 3,
		uuid: "9eb5712d-9878-463f-a19e-a0e94c63bc67",
		createdBy: null,
		deleted: false,
		deletedAt: null,
		tagId: "9200157335",
		cardNumber: "9211812442",
		currency: "CAD",
		balance: 21.67,
		evCardType: "PHONE",
		userUuid: "bd3d4faf-24eb-4ca8-b98b-b996bda251ff",
		vehicleUuid: "309392801",
		parentTagId: null,
		maxActiveTransactionCount: 1,
		activeTransactionCount: 0,
		vehicleMake: "Hyundai",
		vehicleModel: "IONIQ",
	},
];

export const transactionList = [
	/* Transaction data for the card 1 */
	{
		key: "1",
		transactionId: "8eb5712d-9878-463f-a19e-a0e94c63bc11",
		cardUuid: "5f5c0942-8fa7-47c0-a9c4-858beb31a7e7",
		cardNumber: "9128149635",
		evCardType: "CARD",
		paidAmount: 12.1,
		currency: "CAD",
		date: "2022-10-22 11:30:24",
		location: "3615 Victoria Drive, Vancouver, British Columbia",
		duration: 1200000, // maybe milliseconds?
		paymentMethod: "EV Card",
		status: "Success",
	},
	{
		key: "2",
		transactionId: "8eb5711d-9878-463f-a19e-a0e94c63bc11",
		cardUuid: "5f5c0942-8fa7-47c0-a9c4-858beb31a7e7",
		cardNumber: "9128149635",
		evCardType: "CARD",
		paidAmount: 10,
		currency: "CAD",
		date: "2022-10-22 12:30:24",
		location: "876 W 46th Avenue, Vancouver, British Columbia",
		duration: 1102000,
		paymentMethod: "Credit Card",
		status: "Success",
	},
	{
		key: "3",
		transactionId: "8eb5711d-9871-463f-a19e-a0e94c63bc11",
		cardUuid: "5f5c0942-8fa7-47c0-a9c4-858beb31a7e7",
		cardNumber: "9128149635",
		evCardType: "CARD",
		paidAmount: 10,
		currency: "CAD",
		date: "2022-10-22 12:30:24",
		location: "876 W 46th Avenue, Vancouver, British Columbia",
		duration: 1102000,
		paymentMethod: "Credit Card",
		status: "Success",
	},
	/* Transaction data for the card 2 */
	{
		key: "4",
		transactionId: "8eb5711d-9878-263f-a19e-a0e94c63bc11",
		cardUuid: "9eb5712d-9878-463f-a19e-a0e94c63bc66",
		cardNumber: "9210812442",
		evCardType: "STICKER",
		paidAmount: 21,
		currency: "CAD",
		date: "2022-10-22 13:10:24",
		location: "876 W 46th Avenue, Vancouver, British Columbia",
		duration: 1302000,
		paymentMethod: "BC Hydro",
		status: "Failed",
	},
	{
		key: "5",
		transactionId: "8eb5711d-9878-263a-a19e-a0e94c63bc11",
		cardUuid: "9eb5712d-9878-463f-a19e-a0e94c63bc66",
		cardNumber: "9210812442",
		evCardType: "STICKER",
		paidAmount: 8.2,
		currency: "CAD",
		date: "2022-10-22 13:10:24",
		location: "876 W 46th Avenue, Vancouver, British Columbia",
		duration: 1302000,
		paymentMethod: "BC Hydro",
		status: "Failed",
	},
	{
		key: "6",
		transactionId: "8eb5721d-9878-263a-a19e-a0e94c63bc11",
		cardUuid: "9eb5712d-9878-463f-a19e-a0e94c63bc66",
		cardNumber: "9210812442",
		evCardType: "STICKER",
		paidAmount: 12.2,
		currency: "CAD",
		date: "2022-10-22 19:10:24",
		location: "3615 Victoria Drive, Vancouver, British Columbia",
		duration: 1202000,
		paymentMethod: "Credit Card",
		status: "Success",
	},
	/* Transaction data for the card 3 */
	{
		key: "7",
		transactionId: "8eb5721d-9878-163a-a19e-a0e94c63bc11",
		cardUuid: "9eb5712d-9878-463f-a19e-a0e94c63bc67",
		cardNumber: "9211812442",
		evCardType: "PHONE",
		paidAmount: 9.2,
		currency: "CAD",
		date: "2022-10-21 11:10:24",
		location: "100 W 49th Ave, Vancouver, British Columbia",
		duration: 902000,
		paymentMethod: "EV Card",
		status: "Pending",
	},
	{
		key: "8",
		transactionId: "8eb5721d-9878-163a-a19e-a0e94c63ba11",
		cardUuid: "9eb5712d-9878-463f-a19e-a0e94c63bc67",
		cardNumber: "9211812442",
		evCardType: "PHONE",
		paidAmount: 21.2,
		currency: "CAD",
		date: "2022-10-22 12:11:24",
		location: "4700 Kingsway, Burnaby, British Columbia",
		duration: 1102000,
		paymentMethod: "BC Hydro",
		status: "Success",
	},
	{
		key: "9",
		transactionId: "8eb5721d-9878-163a-a19e-a1e94c63ba11",
		cardUuid: "9eb5712d-9878-463f-a19e-a0e94c63bc67",
		cardNumber: "9211812442",
		evCardType: "PHONE",
		paidAmount: 30.1,
		currency: "CAD",
		date: "2022-10-23 12:11:24",
		location: "1428 Cedar Cottage Mews, Vancouver, British Columbia",
		duration: 1402000,
		status: "Success",
	},
];

// export const vehicles = [
// 	{
// 		createdAt: "2022-10-21 15:37:24",
// 		createdBy: "",
// 		deleted: "",
// 		deletedAt: "",
// 		enabled: "",
// 		id: "",
// 		registrationNumber: "",
// 		updatedAt: "",
// 		uuid: "209392803",
// 		userUuid: "32423423423",
// 		vehicleMake: "Tesla",
// 		vehicleModel: "Model 3",
// 		year: "",
// 	},
// ];

/* export const transactionList = [
	{
		userUuid: "ad3d3faf-24eb-4ca8-b98b-b996bda251ff",
		evCardUuid: "5f5c0942-8fa7-47c0-a9c4-858beb31a7e7",
		transactionId: "5aebfb3c-99d6-48db-ab4e-455da616d6bf",
		amount: "4.98",
		date: "2022-10-21 15:37:24",
		location: "Downtown, Vancouver",
		duration: "17mins",
		paymentMethod: "BC Hydro",
		paidStatus: "Billed BC Hydro",
	},
];
 */
