import CreateTicket from "components/support/CreateTicket";
import DashboardLayout from "Layouts/DashboardLayout";
import { useTranslation } from "react-i18next";
import {
	contactOptions,
	issueTypeOptions,
	sourceOptions,
	statusOptions,
	priorityOptions,
	departmentOptions,
	defaultAgentOptions,
	companyOptions,
} from "../../assets/mock/support";

const CreateTicketPage = () => {
	const { t } = useTranslation(["support"]);

	const handleSubmitAddContact = (data: any) => {
		// eslint-disable-next-line no-console
		console.log(data);
	};

	return (
		<DashboardLayout>
			<div className="flex flex-col">
				<div className="mb-5">
					<span className="text-2xl font-medium pb-10">
						{t("createSupportTicket")}
					</span>
				</div>
			</div>
			<CreateTicket
				contactOptions={contactOptions}
				issueTypeOptions={issueTypeOptions}
				sourceOptions={sourceOptions}
				statusOptions={statusOptions}
				priorityOptions={priorityOptions}
				departmentOptions={departmentOptions}
				defaultAgentOptions={defaultAgentOptions}
				companyOptions={companyOptions}
				handleSubmitAddContact={handleSubmitAddContact}
			/>
		</DashboardLayout>
	);
};

export default CreateTicketPage;
