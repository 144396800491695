import Modal from "components/modals/Modal";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DropDownOption, EVCardParams } from "types/EVCards";
import { Select, SubmitButton, TextField } from "components/form/InputField";
import { Switch } from "@headlessui/react";
import { evCardTypeOptions } from "constants/index";

const AddEditEVCard = ({
	handleShowEVCardModal,
	showModal,
	selectedCard,
	onSubmitForm,
	isLoading,
	parentCardOptions,
	userOptions,
	vehicleOptions,
	handleUserUuidChange,
}: {
	handleShowEVCardModal: () => void;
	showModal: boolean;
	selectedCard?: EVCardParams | null;
	onSubmitForm: (data: any) => Promise<void>;
	isLoading: boolean;
	parentCardOptions: DropDownOption[];
	userOptions: DropDownOption[];
	vehicleOptions: DropDownOption[];
	handleUserUuidChange: (userUuid: string) => void;
}) => {
	const {
		enabled,
		evCardType,
		maxActiveTransactionCount,
		parentTagId,
		userUuid,
		vehicleUuid,
	} = selectedCard || {};

	const { t } = useTranslation(["evCards", "common"]);

	const [isEnabled, setIsEnabled] = useState<boolean | undefined>(enabled);

	const parentCardOptionsForSelect = parentCardOptions.filter(
		(option) => option.value !== selectedCard?.tagId
	);

	const form = useForm<EVCardParams>({
		defaultValues: {
			enabled: enabled || false,
			evCardType: evCardType || "STICKER",
			maxActiveTransactionCount: maxActiveTransactionCount || 1,
			parentTagId: parentTagId || "",
			userUuid: userUuid || "",
			vehicleUuid: vehicleUuid || "",
		},
	});

	const { handleSubmit, register, reset } = form;

	useEffect(() => {
		reset({
			enabled: enabled || false,
			evCardType: evCardType || "STICKER",
			maxActiveTransactionCount: maxActiveTransactionCount || 1,
			parentTagId: parentTagId || "",
			userUuid: userUuid || "",
			vehicleUuid: vehicleUuid || "",
		});
		if (userUuid !== undefined) {
			handleUserUuidChange(userUuid);
		}
	}, [selectedCard]);

	useEffect(() => {
		setIsEnabled(enabled);
	}, [enabled]);

	const onSubmit = async (props: any) => {
		const params = {
			...props,
			enabled: isEnabled,
		};

		onSubmitForm(params);
	};

	return (
		<Modal
			title={!selectedCard ? t("addEVCard") : t("updateCard")}
			show={showModal}
			onClose={() => handleShowEVCardModal()}
		>
			<FormProvider {...form}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="mb-2">
						<Select
							rootClass="col-lg-6 mb-2"
							name="evCardType"
							label={t("type", { ns: "common" })}
							placeholder={t("typeOfCard")}
							required
							ref={register({ required: true })}
							options={evCardTypeOptions}
						/>
					</div>

					<div className="mb-2">
						<Select
							rootClass="col-lg-6 mb-2"
							name="parentTagId"
							label={t("parentCard")}
							placeholder={t("selectParentCard")}
							ref={register({ required: false })}
							options={parentCardOptionsForSelect}
						/>
					</div>

					<div className="mb-2">
						<Select
							rootClass="col-lg-6 mb-2"
							name="userUuid"
							label={t("user")}
							placeholder={t("selectAUser")}
							required
							ref={register({ required: true })}
							options={userOptions}
							onChange={(e: any) => handleUserUuidChange(e.target.value)}
						/>
					</div>

					<div className="mb-2">
						<Select
							rootClass="col-lg-6 mb-2"
							name="vehicleUuid"
							label={t("vehicle")}
							placeholder={t("selectAVehicle")}
							required
							ref={register({ required: true })}
							options={vehicleOptions}
						/>
					</div>

					<div className="mb-2">
						<TextField
							data-testid="roles-input"
							className="mb-2"
							name="maxActiveTransactionCount"
							label={t("maxActiveTransactionCount")}
							required
							type="number"
							min={1}
							ref={register({ required: true })}
						/>
					</div>

					{selectedCard && (
						<Switch.Group>
							<div className="mt-4  flex items-center">
								<Switch.Label
									passive
									className="mr-10 font-medium text-gray-700"
								>
									{t("enableCard")}
								</Switch.Label>
								<Switch
									checked={isEnabled || false}
									onChange={() => setIsEnabled(!isEnabled)}
									className={`${
										isEnabled ? "bg-primary-color" : "bg-gray-200"
									} relative inline-flex h-6 w-12 items-center rounded-full`}
								>
									<span
										className={`${
											!isEnabled ? "translate-x-6" : "translate-x-1"
										} inline-block h-4 w-4 transform rounded-full bg-white`}
									/>
								</Switch>
							</div>
						</Switch.Group>
					)}

					<SubmitButton
						type="submit"
						loading={isLoading}
						label={selectedCard ? t("updateCard") : t("addEVCard")}
						className="mt-8 flex m-auto my-5 text-center"
					/>
				</form>
			</FormProvider>
		</Modal>
	);
};

export default AddEditEVCard;
