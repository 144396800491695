import { useTranslation } from "react-i18next";
import SearchInput from "components/common/SearchInput";
import { SubmitButton } from "components/form/InputField";

const SupportToolbar = ({
	handleSubmit,
}: {
	handleSubmit: (data: string) => void;
}) => {
	const { t } = useTranslation(["support"]);

	return (
		<div className="py-4 justify-between items-end md:items-center flex flex-col md:flex-row">
			<SearchInput
				handleSubmit={handleSubmit}
				placeholder={t("searchSupportTickets")}
			/>
			<SubmitButton
				className="my-5 rounded-full"
				type="button"
				onClick={() => {
					window.location.replace("/support/create-ticket");
				}}
				label={t("createSupportTicket")}
			/>
		</div>
	);
};

export default SupportToolbar;
