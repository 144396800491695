import {
	CreateSites,
	GetAllSitesBySiteID,
	GetASiteOwner,
	UpdateSites,
	CreateChargingStations,
	UpdateChargingStations,
	GetChargingStationsBySiteUUID,
	UpdateASiteOwner,
} from "api/admin";
import SiteOwner from "components/usersManagement/siteOwner";
import { AlertContext } from "context/AlertContext";
import { SiteContext } from "context/SitesContext";
import DashboardLayout from "Layouts/DashboardLayout";
import { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { ErrorResponse } from "types";
import {
	ChargingStationParams,
	ChargingStationResponse,
	SiteOwnerParams,
	SiteOwnerResponse,
	SiteParams,
	SiteResponse,
} from "types/Stations";
import { useTranslation } from "react-i18next";

const SiteOwnerPage = () => {
	const [pagination, setPaginationState] = useState({ page: 0, pageSize: 10 });
	const [chargers, setChargers] = useState<ChargingStationResponse[]>([]);
	const [inViewLoading, setinViewLoading] = useState<boolean>(false);
	const { t } = useTranslation(["constants"]);
	const params: { id: string } = useParams();
	const { id } = params || { id: "" };

	const { data: siteOwner } = useQuery("siteowner", () => GetASiteOwner(id));

	const { name } = siteOwner || { name: "" };

	const { handleShowAlert } = useContext(AlertContext);
	const { selectedSite, selectedChargingStation } = useContext(SiteContext);

	const queryClient = useQueryClient();

	const { mutateAsync, isLoading } = useMutation<
		SiteResponse,
		ErrorResponse,
		SiteParams
	>(CreateSites, {
		onSuccess: () => {
			queryClient.invalidateQueries("sites");
		},
	});

	const {
		mutateAsync: createChargingStationMutateAsync,
		isLoading: isLoadingChargingStation,
	} = useMutation<SiteResponse, ErrorResponse, ChargingStationParams>(
		CreateChargingStations,
		{
			onSuccess: () => {
				queryClient.invalidateQueries("chargingStations");
			},
		}
	);

	const {
		mutateAsync: updateChargingStationMutateAsync,
		isLoading: isLoadingUpdateChargingStation,
	} = useMutation<SiteResponse, ErrorResponse, ChargingStationParams>(
		UpdateChargingStations,
		{
			onSuccess: () => {
				queryClient.invalidateQueries("chargingStations");
			},
		}
	);

	const { mutateAsync: updateMutateAsync, isLoading: updateSiteLoading } =
		useMutation<SiteResponse, ErrorResponse, SiteParams>(UpdateSites, {
			onSuccess: () => {
				queryClient.invalidateQueries("sites");
			},
		});

	const {
		mutateAsync: updateSiteOwnerMuteAsync,
		isLoading: updateSiteOwnerLoading,
	} = useMutation<SiteOwnerResponse, ErrorResponse, SiteOwnerParams>(
		UpdateASiteOwner,
		{
			onSuccess: () => {
				queryClient.invalidateQueries("siteowner");
			},
			onMutate: (data) => {
				queryClient.setQueryData("siteowner", data);
			},
		}
	);

	const handleSubmitAddEditSiteOwner = async (
		props: SiteOwnerParams
	): Promise<boolean> => {
		const mutate = updateSiteOwnerMuteAsync;

		const { message } = await mutate({ ...props });

		if (!message) return false;

		if (
			message === t("The site owner was updated successfully", { lng: "en" })
		) {
			handleShowAlert({ message, severity: "success" });
			return true;
		}

		handleShowAlert({ message, severity: "error" });

		return false;
	};

	const { page, pageSize } = pagination;

	const {
		isLoading: siteIsLoading,
		data,
		refetch,
	} = useQuery("sites", () => GetAllSitesBySiteID(id, page, pageSize));

	useEffect(() => {
		refetch();
	}, [pagination]);

	const sites: SiteResponse[] = _.get(data, "content") || [];

	const totalPages: number = _.get(data, "totalPages") || 0;

	const hanlePageChange = (pageNumber: number, size: number) => {
		setPaginationState({ page: pageNumber, pageSize: size });
	};

	const handleSubmitAddEditChargingStation = async (
		props: ChargingStationParams
	): Promise<boolean> => {
		const mutate = !selectedChargingStation
			? createChargingStationMutateAsync
			: updateChargingStationMutateAsync;

		const { message } = await mutate({
			...props,
			uuid: selectedChargingStation?.uuid,
		});

		if (!message) return true;

		if (
			message ===
				t("The charging station was updated successfully!", { lng: "en" }) ||
			message ===
				t("The charging station was added successfully!", { lng: "en" })
		) {
			handleShowAlert({ message, severity: "success" });
			return true;
		}

		handleShowAlert({ message, severity: "error" });

		return false;
	};

	const handleSubmitAddEditSites = async (
		props: SiteParams
	): Promise<boolean> => {
		const mutate = !selectedSite ? mutateAsync : updateMutateAsync;

		const { message } = await mutate({
			...props,
			siteOwnerUuid: id,
			uuid: selectedSite?.uuid,
		});

		if (!message) return true;

		if (
			message === t("The site was added successfully!", { lng: "en" }) ||
			message === t("The site was updated successfully!", { lng: "en" })
		) {
			handleShowAlert({ message, severity: "success" });
			return true;
		}

		handleShowAlert({ message, severity: "error" });

		return false;
	};

	const handleFetchChargers = async (uuid: string) => {
		setinViewLoading(true);
		const res = await GetChargingStationsBySiteUUID(uuid);

		const result: ChargingStationResponse[] = _.get(res, "content") || [];

		setinViewLoading(false);

		setChargers(result);
	};

	return (
		<DashboardLayout>
			<div className="flex flex-col">
				<span className="text-2xl font-medium pb-10">{name}</span>

				<SiteOwner
					handleSubmitAddEditSites={handleSubmitAddEditSites}
					handleSubmitAddEditChargingStation={
						handleSubmitAddEditChargingStation
					}
					handleSubmitAddEditSiteOwner={handleSubmitAddEditSiteOwner}
					isLoading={
						isLoading ||
						updateSiteLoading ||
						siteIsLoading ||
						isLoadingChargingStation ||
						isLoadingUpdateChargingStation ||
						updateSiteOwnerLoading
					}
					inViewLoading={inViewLoading}
					handleFetchChargers={handleFetchChargers}
					sites={sites}
					pageTotal={totalPages}
					handlePageChange={hanlePageChange}
					chargers={chargers}
					siteOwnerUUID={id}
					siteOwner={siteOwner}
				/>
			</div>
		</DashboardLayout>
	);
};

export default SiteOwnerPage;
