import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import Accordion from "components/common/Accordion";
import MoreOptionsMenu from "components/common/MoreOptionsMenu";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	ChargingStationDataSource,
	ChargingStationResponse,
	SiteResponse,
} from "types/Stations";
import { getFulladdress } from "utils";

const SitesAccordion = ({
	handleToggleShowAddEditModal,
	handleToggleShowAddEditChargingStationModal,
	handleShowMapModal,
	site,
	siteOwnerUUID,
	handleFetchChargingStation,
	chargingStations,
}: {
	handleToggleShowAddEditModal: (data?: SiteResponse) => void;
	handleToggleShowAddEditChargingStationModal: (
		uuid?: string,
		data?: ChargingStationDataSource
	) => void;
	handleFetchChargingStation: (uuid: string) => void;
	handleShowMapModal: (data: SiteResponse) => void;
	site: SiteResponse;
	siteOwnerUUID: string;
	chargingStations: ChargingStationResponse[];
}) => {
	const { t } = useTranslation(["sites", "common"]);
	const [chargingStationsTableData, setChargingStationsTableData] = useState<
		ChargingStationDataSource[]
	>([]);

	const { name, description, address } = site;

	useEffect(() => {
		const stations: ChargingStationDataSource[] = chargingStations.map(
			({
				availabilityStatus,
				chargingStationIdentifier,
				connectors,
				iccid,
				meterSerialNumber,
				meterType,
				model,
				registrationStatus,
				serialNumber,
				vendor,
				uuid: key,
			}) => ({
				key,
				availabilityStatus,
				chargingStationIdentifier,
				connectors: connectors.length,
				iccid,
				meterSerialNumber,
				meterType,
				model,
				registrationStatus,
				serialNumber,
				vendor,
			})
		);

		setChargingStationsTableData(stations);
	}, [chargingStations]);

	const columns: ColumnsType<ChargingStationDataSource> = [
		{
			title: t("chargers.chargerInfo.vendor"),
			dataIndex: "vendor",
			key: "vendor",
		},
		{
			dataIndex: "connectors",
			title: t("chargers.chargerInfo.connectors"),
			key: "connectors",
		},
		{
			dataIndex: "chargingStationIdentifier",
			title: t("chargers.chargerInfo.identifier"),
			key: "chargingStationIdentifier",
		},
		{
			dataIndex: "availabilityStatus",
			title: t("chargers.chargerInfo.availabilityStatus"),
			key: "availabilityStatus",
		},
		{
			dataIndex: "iccid",
			title: t("chargers.chargerInfo.iccid"),
			key: "iccid",
		},
		{
			dataIndex: "meterSerialNumber",
			title: t("chargers.chargerInfo.meterSerialNumber"),
			key: "meterSerialNumber",
		},
		{
			dataIndex: "meterType",
			title: t("chargers.chargerInfo.meterType"),
			key: "meterType",
		},
		{
			dataIndex: "meterSerialNumber",
			title: t("chargers.chargerInfo.meterSerialNumber"),
			key: "meterSerialNumber",
		},
		{
			dataIndex: "serialNumber",
			title: t("chargers.chargerInfo.serialNumber"),
			key: "serialNumber",
		},
		{
			title: t("action", { ns: "common" }),
			key: "operation",
			fixed: "right",
			align: "center",
			width: 80,
			render: (item) => <MoreOptionsMenu menuList={List} data={item} />,
		},
	];

	const List = [
		{
			label: t("edit", { ns: "common" }),
			onClick: (data: ChargingStationDataSource) =>
				handleToggleShowAddEditChargingStationModal(site.uuid, data),
		},
	];

	const siteOptionList = [
		{
			label: t("edit", { ns: "common" }),
			onClick: () => handleToggleShowAddEditModal(site),
		},
		{
			label: t("chargers.createCharger"),
			onClick: () => handleToggleShowAddEditChargingStationModal(site.uuid),
		},
		{
			label: t("locations.viewOnMap", { ns: "common" }),
			onClick: () => handleShowMapModal(site),
		},
	];

	return (
		<Accordion
			url={`/site-owners/site-owner/${siteOwnerUUID}/site/${site.uuid}`}
			link
			title={name}
			onClick={() => handleFetchChargingStation(site.uuid)}
		>
			<div className="mb-10 flex flex-wrap sm:flex-nowrap justify-end">
				<div className="w-full">
					<p>
						{t("locations.address", { ns: "common" })}:{" "}
						{getFulladdress(address)}{" "}
					</p>
					<p>
						{t("description", { ns: "common" })}: {description}
					</p>
				</div>
				<div>
					<MoreOptionsMenu
						buttonClassName="p-2"
						label={t("options", { ns: "common" })}
						size="md"
						menuList={siteOptionList}
					/>
				</div>
			</div>

			<div className="flex justify-between items-center">
				<h1 className="text-lg my-4">{t("chargers.title")}</h1>
				<a href={`/site-owners/site-owner/${siteOwnerUUID}/site/${site.uuid}`}>
					{t("seeMore", { ns: "common" })}
				</a>
			</div>
			<Table
				dataSource={chargingStationsTableData}
				columns={columns}
				scroll={{ x: 1200 }}
			/>
		</Accordion>
	);
};

export default SitesAccordion;
