import {
	AddressField,
	SubmitButton,
	TextField,
} from "components/form/InputField";
import { Switch } from "@headlessui/react";
import Modal from "components/modals/Modal";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ChargingStationParams, ChargingStationResponse } from "types/Stations";

const CreateEditChargingStations = ({
	handleShowModal,
	showModal,
	selectedChargingStation,
	isLoading,
	onSubmitForm,
	siteUuid,
}: {
	handleShowModal: () => void;
	showModal: boolean;
	isLoading: boolean;
	onSubmitForm: (params: ChargingStationParams) => void;
	selectedChargingStation: ChargingStationResponse | null;
	siteUuid: string | null;
}) => {
	const {
		connectors,
		description,
		iccid,
		imsi,
		locationLattitude,
		locationLongitude,
		meterSerialNumber,
		model,
		serialNumber,
		siteOwnerUuid,
		vendor,
	} = selectedChargingStation || {};

	const [isEnabled, setIsEnabled] = useState<boolean>(false);
	const [lngLat, setLngLat] = useState<{
		locationLattitude: string;
		locationLongitude: string;
	} | null>(null);

	const form = useForm<ChargingStationParams>({
		defaultValues: useMemo(
			() => ({
				connectorCount: connectors?.length || 0,
				description: description || "",
				iccid: iccid || "",
				imsi: imsi || "",
				locationLattitude: locationLattitude || "",
				locationLongitude: locationLongitude || "",
				meterSerialNumber: meterSerialNumber || "",
				model: model || "",
				serialNumber: serialNumber || "",
				siteUuid: siteOwnerUuid || "",
				vendor: vendor || "",
			}),
			[selectedChargingStation]
		),
	});

	const { handleSubmit, register, reset, setValue } = form;

	useEffect(() => {
		reset({
			connectorCount: connectors?.length || 0,
			description: description || "",
			iccid: iccid || "",
			imsi: imsi || "",
			locationLattitude: locationLattitude || "",
			locationLongitude: locationLongitude || "",
			meterSerialNumber: meterSerialNumber || "",
			model: model || "",
			serialNumber: serialNumber || "",
			siteUuid: siteOwnerUuid || "",
			vendor: vendor || "",
		});
	}, [selectedChargingStation]);

	const onSubmit = async (props: ChargingStationParams) => {
		if (!siteUuid) return;

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { address: _, ...rest } = props;

		const data: ChargingStationParams = {
			...rest,
			connectorCount: Number(props.connectorCount),
			locationLattitude: lngLat?.locationLattitude || "",
			locationLongitude: lngLat?.locationLongitude || "",
			siteUuid,
			registrationStatus: "PENDING",
		};

		onSubmitForm(data);
	};

	return (
		<Modal
			title={
				selectedChargingStation
					? "Update Charging Station"
					: "Create Charging Station"
			}
			show={showModal}
			onClose={() => handleShowModal()}
		>
			<FormProvider {...form}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="mb-2">
						<div className="mt-2">
							<TextField
								data-testid="vendor"
								className="mb-2"
								name="vendor"
								label="Vendor"
								required
								ref={register({ required: true })}
							/>
						</div>
						<TextField
							data-testid="connectorCount"
							className="mb-2"
							name="connectorCount"
							label="Connection Count"
							required
							type="number"
							ref={register({ required: true })}
						/>
					</div>
					<div className="mb-2">
						<TextField
							data-testid="description"
							className="mb-2"
							name="description"
							label="Description"
							required
							ref={register({ required: true })}
						/>
					</div>

					<div className="mt-2">
						<TextField
							data-testid="iccid"
							className="mb-2"
							name="iccid"
							label="ICCID"
							required
							ref={register({ required: true })}
						/>
					</div>
					<div className="mt-2">
						<TextField
							data-testid="imsi"
							className="mb-2"
							name="imsi"
							label="ISMI"
							required
							ref={register({ required: true })}
						/>
					</div>
					<div className="mt-2">
						<TextField
							data-testid="meterSerialNumber"
							className="mb-2"
							name="meterSerialNumber"
							label="Meter Serial Number"
							required
							ref={register({ required: true })}
						/>
					</div>

					<div className="mt-2">
						<TextField
							data-testid="model"
							className="mb-2"
							name="model"
							label="Model"
							required
							ref={register({ required: true })}
						/>
					</div>

					<div className="mt-2">
						<TextField
							data-testid="serialNumber"
							className="mb-2"
							name="serialNumber"
							label="Serial Number"
							required
							ref={register({ required: true })}
						/>
					</div>

					<div className="mt-2">
						<AddressField
							data-testid="address"
							className="mb-2"
							label="Address"
							name="address"
							required
							getLocationData={(data: any) => {
								setValue("address", data.formatted_address);

								setLngLat({
									locationLattitude: data.geometry.location.lat().toString(),
									locationLongitude: data.geometry.location.lng().toString(),
								});
							}}
							ref={register({ required: true })}
						/>
					</div>

					{selectedChargingStation && (
						<Switch.Group>
							<div className="mt-4  flex items-center">
								<Switch.Label
									passive
									className="mr-10 font-medium text-gray-700"
								>
									Enable Charging Station
								</Switch.Label>
								<Switch
									checked={isEnabled}
									onChange={() => setIsEnabled(!isEnabled)}
									className={`${
										isEnabled ? "bg-primary-color" : "bg-gray-200"
									} relative inline-flex h-6 w-12 items-center rounded-full`}
								>
									<span
										className={`${
											!isEnabled ? "translate-x-6" : "translate-x-1"
										} inline-block h-4 w-4 transform rounded-full bg-white`}
									/>
								</Switch>
							</div>
						</Switch.Group>
					)}

					<SubmitButton
						type="submit"
						loading={isLoading}
						label={selectedChargingStation ? "Update" : "Done"}
						className="mt-8 flex m-auto my-5 text-center"
					/>
				</form>
			</FormProvider>
		</Modal>
	);
};
export default CreateEditChargingStations;
