const staging = {
	graphUrl: "https://api.staging.getloop.ca/graphql",
	env: "staging",
	googleClientId: "AIzaSyDap-mhUpuHbYzDspnaV5R00HHE3Q-QEYg",
	chargingStationManagementBaseUrl: "http://localhost:8081/api/v1",
	userManagementBaseUrl: "http://localhost:8080/api/v1",
	baseUrl: "http://localhost:8443/api/v1",
	withoutGateway: true,
	logRocketId: "jgiqhe/tapgoev",
	sentryDsn:
		"https://8d20f627f1704a84968bf9ccce05dcfc@o1405899.ingest.sentry.io/6739234",
};

const config = {
	graphUrl: process.env.REACT_APP_GRAPH_URL || staging.graphUrl,
	env: process.env.REACT_APP_ENV || staging.env,
	withoutGateway:
		process.env.REACT_APP_DEV_WITHOUT_GATEWAY || staging.withoutGateway,
	googleClientId:
		process.env.REACT_APP_GOOGLE_PLACES_API_KEY || staging.googleClientId,
	baseUrl: process.env.BASE_URL || staging.baseUrl,
	logRocketId: process.env.REACT_LOG_ROCKET_ID || staging.logRocketId,
	sentryDsn: process.env.REACT_SENTRY_DSN || staging.sentryDsn,
	chargingStationManagementBaseUrl:
		process.env.REACT_APP_CHARGING_STATION_MANAGEMENT_BASE_URL ||
		staging.chargingStationManagementBaseUrl,
	userManagementBaseUrl:
		process.env.REACT_APP_USER_MANAGEMENT_BASE_URL ||
		staging.userManagementBaseUrl,
};

export default config;
