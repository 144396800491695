import {
	fetchPermissions,
	fetchSingleRole,
	updateRolePermissions,
} from "api/application-management";
import AssignPermissions from "components/applicationSettings/roles/AssignPermissionsPage";
import { AlertContext } from "context/AlertContext";
import DashboardLayout from "Layouts/DashboardLayout";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { ErrorResponse } from "types";
import {
	PermissionsResponse,
	RolesResponse,
} from "types/ApplicationManagement";

const AssignPermissionsPage = () => {
	const queryClient = useQueryClient();
	const { t } = useTranslation(["constants"]);
	const { handleShowAlert } = useContext(AlertContext);

	const { uuid } = useParams<{ uuid: string }>();
	const { isLoading: roleLoading, data: role } = useQuery<RolesResponse>(
		"role",
		() => fetchSingleRole(uuid)
	);

	const { isLoading: permissionsLoading, data: permissionsData } = useQuery<
		PermissionsResponse[]
	>("permissions", () => fetchPermissions());

	const { mutateAsync, isLoading: updateIsloading } = useMutation<
		PermissionsResponse,
		ErrorResponse,
		{ selectedPermissions: string[]; uuid: string }
	>(updateRolePermissions, {
		onSuccess: () => {
			queryClient.invalidateQueries("role");
		},
	});

	const handleSubmitPermissions = async (selectedPermissions: string[]) => {
		const { message } = await mutateAsync({ uuid, selectedPermissions });

		if (!message) return;

		if (
			message ===
			t("The permissions for the role updated successfully", { lng: "en" })
		) {
			handleShowAlert({ message, severity: "success" });
		}
	};

	const previousPermissions = role?.permissionNames || [];

	return (
		<DashboardLayout>
			<div className="flex flex-col">
				<div className="mb-5">
					<span className="text-2xl font-medium pb-10">{role?.name}</span>
				</div>

				<AssignPermissions
					permissions={permissionsData || []}
					roleName={role?.name || ""}
					roleUuid={role?.uuid || ""}
					previousPermissions={previousPermissions}
					handleSubmitPermissions={handleSubmitPermissions}
					isLoading={permissionsLoading || roleLoading || updateIsloading}
				/>
			</div>
		</DashboardLayout>
	);
};

export default AssignPermissionsPage;
