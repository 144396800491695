import { OrganizationParams } from "types/UserManagement";
import apiRequestHandler from "utils/apiRequestHandler";

export const CreateOrganization = (params: OrganizationParams): any => {
	const res = apiRequestHandler({
		url: "/organizations",
		data: params,
		method: "post",
	});
	return res;
};

export const GetAllOrganization = (page = 1, size = 10, search = "") => {
	const res = apiRequestHandler({
		url: `/organizations?page=${page}&size=${size}&searchParam=${search}`,
		data: {},
		method: "get",
	});
	return res;
};

export const UpdateOrganization = ({
	uuid,
	...rest
}: OrganizationParams): any => {
	const res = apiRequestHandler({
		url: `/organizations/${uuid}`,
		data: rest,
		method: "put",
	});
	return res;
};
