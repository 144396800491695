import SupportTicketDetails from "components/support/SupportTicketDetails";
import DashboardLayout from "Layouts/DashboardLayout";
import { useParams } from "react-router-dom";
import { issueDataType } from "types/Support";
import {
	issues,
	issueTypeOptions,
	statusOptions,
	priorityOptions,
	departmentOptions,
	defaultAgentOptions,
} from "../../assets/mock/support";

const TicketDetailPage = () => {
	const params: { ticketId: string } = useParams();
	const { ticketId } = params;

	const issue = issues.find((i: issueDataType) => i.key === ticketId);

	return (
		<DashboardLayout>
			{issue && (
				<div className="flex flex-col">
					<div className="mb-5">
						<span className="text-2xl font-medium pb-10">
							{issue.subject} #{issue.key}
						</span>
					</div>
					<SupportTicketDetails
						issue={issue}
						issueTypeOptions={issueTypeOptions}
						statusOptions={statusOptions}
						priorityOptions={priorityOptions}
						departmentOptions={departmentOptions}
						defaultAgentOptions={defaultAgentOptions}
					/>
				</div>
			)}
		</DashboardLayout>
	);
};

export default TicketDetailPage;
