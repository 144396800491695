import { Select, SubmitButton, TextField } from "components/form/InputField";
import Modal from "components/modals/Modal";
/* import { useState, useEffect } from "react"; */
import { FormProvider, useForm } from "react-hook-form";
import { MakeParams } from "types/Settings";
/* import { Switch } from "@headlessui/react"; */
import { useTranslation } from "react-i18next";

const AddContact = ({
	handleShowModal,
	showModal,
	isLoading,
	companyOptions,
	onSubmitForm,
}: {
	handleShowModal: (data?: any) => void;
	showModal: boolean;
	isLoading: boolean;
	companyOptions: {}[];
	onSubmitForm: (data?: any) => void;
}) => {
	/* const { description, name, enabled } = selectedMake || {};
	const [isEnabled, setIsEnabled] = useState(!enabled); */
	const { t } = useTranslation(["support"]);

	const form = useForm<MakeParams>();

	const { handleSubmit, register /* , reset */ } = form;

	/* useEffect(() => {
		reset({
			name: name || "",
			description: description || "",
		});
	}, [selectedMake]); */

	const onSubmit = async (props: any) => {
		let params = props;
		params = { ...props };
		onSubmitForm(params);
	};

	return (
		<Modal
			title={t("addNewContact_cap")}
			show={showModal}
			onClose={() => {
				handleShowModal();
				/* handleSelectedMake(null); */
			}}
		>
			<FormProvider {...form}>
				<form onSubmit={() => handleSubmit(onSubmit)}>
					<div className="mb-2">
						<TextField
							data-testid="name"
							className="mb-2"
							name="name"
							label={t("name")}
							required
							ref={register({ required: true })}
						/>
					</div>

					<div className="mb-2">
						<TextField
							data-testid="email"
							className="mb-2"
							name="email"
							label={t("email")}
							required
							ref={register({ required: true })}
						/>
					</div>

					<div className="mb-4">
						<Select
							rootClass="col-lg-6 mb-2"
							name="company"
							label={t("company")}
							/*  */ placeholder={t("companyPlaceholder")}
							required
							ref={register({ required: true })}
							options={companyOptions}
						/>
					</div>

					<div className="mb-2">
						<TextField
							data-testid="workPhone"
							className="mb-2"
							name="workPhone"
							label={t("workPhone")}
							required
							ref={register({ required: true })}
						/>
					</div>

					<div className="mb-2">
						<TextField
							data-testid="mobilePhone"
							className="mb-2"
							name="mobilePhone"
							label={t("mobilePhone")}
							required
							ref={register({ required: true })}
						/>
					</div>

					<div className="mb-2">
						<TextField
							data-testid="externalId"
							className="mb-2"
							name="externalId"
							label={t("externalId")}
							required
							ref={register({ required: true })}
						/>
					</div>

					<SubmitButton
						type="submit"
						loading={isLoading}
						label={t("addNewContact_cap")}
						className="mt-8 flex m-auto my-5 text-center"
					/>
				</form>
			</FormProvider>
		</Modal>
	);
};
export default AddContact;
