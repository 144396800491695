import { BsSliders } from "react-icons/bs";
import { Popover } from "@headlessui/react";
import { ReactNode } from "react";

const StationFilter = ({
	children,
	className = "p-4",
}: {
	children: ReactNode;
	className?: string;
}) => (
	<Popover className="relative">
		<Popover.Button
			className={`bg-white rounded-lg mr-2 lg:mr-5 border border-gray-300 ${className}`}
		>
			<BsSliders size={24} />
		</Popover.Button>

		<Popover.Panel className="absolute z-10 bg-white rounded-lg w-80 p-5 mt-5">
			{children}
		</Popover.Panel>
	</Popover>
);
export default StationFilter;
