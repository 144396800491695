import { Dialog, Transition } from "@headlessui/react";
import { ReactNode } from "react";
import { IoCloseSharp } from "react-icons/io5";

const Modal = ({
	show,
	onClose,
	children,
	title,
	size = "sm",
}: {
	show: boolean;
	onClose: () => void;
	children: ReactNode;
	title?: string;
	size?: "sm" | "md" | "lg";
}) => {
	const modalSize = {
		sm: "lg:w-1/4",
		md: "lg:w-1/3",
		lg: "lg:w-1/2",
	};
	return (
		<Transition.Root show={show}>
			<Dialog
				className="fixed z-10 overflow-y-scroll inset-0 tailwind-container"
				onClose={onClose}
			>
				<div className="flex items-center justify-center">
					<Transition.Child
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-20 transition-opacity" />
					</Transition.Child>
					<Transition.Child
						className="flex flex-col min-h-screen w-full items-center justify-center lg:py-12"
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-800"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div
							className={`w-full ${modalSize[size]} flex-grow lg:flex-grow-0 rounded-md bg-white transform transition-all`}
						>
							<button
								type="button"
								onClick={onClose}
								className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
							>
								<IoCloseSharp size={24} />
							</button>

							<div className="py-4 px-6 rounded-t border-b ">
								<h3 className="text-base font-semibold text-gray-900 lg:text-xl">
									{title}
								</h3>
							</div>
							<div className="p-5">{children}</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
};
export default Modal;
