import { getUser, getUserEVCards, getUserVehicles } from "api/user-management";
import UserDetail from "components/usersManagement/userDetail";
import DashboardLayout from "Layouts/DashboardLayout";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { UserInfo } from "types/Auth";
import { VehicleResponse } from "types/Settings";

const UserDetailPage = () => {
	const params: { id: string } = useParams();
	const { id } = params || { id: "" };

	const { isLoading: isLoadingUserData, data: userData } = useQuery<UserInfo>(
		"userData",
		() => getUser(id)
	);

	const { isLoading: isLoadingUserVehicleData, data: userVehicleData } =
		useQuery<VehicleResponse[]>("userVehicles", () => getUserVehicles(id));

	// TODO: change data type to EVCardResponse when it is merged with staging
	const { isLoading: isLoadingUserEvCardData, data: userEvCardData } =
		useQuery<any>("userEvCards", () => getUserEVCards(id));

	const { t } = useTranslation("common");
	return (
		<DashboardLayout>
			<div className="flex flex-col">
				<div className="mb-5">
					<span className="text-2xl font-medium pb-10">
						{t("userDetail.title")}
					</span>
				</div>
			</div>
			<UserDetail
				user={userData}
				isLoading={
					isLoadingUserData ||
					isLoadingUserVehicleData ||
					isLoadingUserEvCardData
				}
				userVehicleList={userVehicleData}
				userEvCardList={userEvCardData}
			/>
		</DashboardLayout>
	);
};

export default UserDetailPage;
