import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import ErrorBoundary from "components/errorBoundary/ErrorBoundary";
import Routes from "./Routes";
import reportWebVitals from "./reportWebVitals";
import "antd/dist/antd.min.css";
import "./i18n";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(
	<ErrorBoundary>
		<Routes />
	</ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
