import {
	createEVCard,
	getAllEVCards,
	getAllUsers,
	getAllVehiclesByUserUuid,
	updateEVCard,
} from "api/chargingstation-management";
import EVCards from "components/applicationSettings/evCards";
import { AlertContext } from "context/AlertContext";
import { EVCardContext } from "context/EVCardContext";
import DashboardLayout from "Layouts/DashboardLayout";
import _ from "lodash";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ErrorResponse } from "types";
import {
	DropDownOption,
	EVCardDataType,
	EVCardParams,
	UserResponse,
} from "types/EVCards";
import { VehicleResponse } from "types/Settings";

const EVCardsPage = () => {
	const queryClient = useQueryClient();
	const { selectedCard } = useContext(EVCardContext);
	const { handleShowAlert } = useContext(AlertContext);
	const { t } = useTranslation(["evCards"]);

	const [userUuid, setUserUuid] = useState("");

	const [pagination, setPaginationState] = useState({ page: 0, pageSize: 10 });

	const { page, pageSize } = pagination;

	const { isLoading: isEVCardListLoading, data: evCards } = useQuery<{
		content: EVCardDataType[];
	}>("evCardsList", () => getAllEVCards(page, pageSize));

	const totalPages: number = _.get(evCards, "totalPages");

	const evCardList: EVCardDataType[] =
		_.get(evCards, "content")?.map((card) => ({ key: card.uuid, ...card })) ||
		[];

	const { isLoading: isUserListLoading, data: userList } = useQuery<{
		content: UserResponse[];
	}>("users", () => getAllUsers());

	const userOptions =
		_.get(userList, "content")?.map<DropDownOption>((user) => ({
			name: `${user.firstName} ${user.lastName}`,
			value: user.uuid,
		})) || [];

	const { isLoading: isVehicleListLoading, data: vehicleList } = useQuery<
		VehicleResponse[]
	>(["userVehicles", userUuid], () => {
		if (userUuid !== "") {
			return getAllVehiclesByUserUuid(userUuid);
		}
		return Promise.resolve([]);
	});

	const userVehicleOptions =
		vehicleList?.map<DropDownOption>((vehicle) => ({
			name: vehicle.registrationNumber,
			value: vehicle.uuid,
		})) || [];

	const { mutateAsync: createCardMutateAsync, isLoading: createCardIsLoading } =
		useMutation<VehicleResponse, ErrorResponse, EVCardParams>(createEVCard, {
			onSuccess: () => {
				queryClient.invalidateQueries("evCardsList");
			},
		});

	/* Mutation function to update an EV card */
	const { mutateAsync: updateCardMutateAsync, isLoading: updateCardIsLoading } =
		useMutation<VehicleResponse, ErrorResponse, EVCardParams>(updateEVCard, {
			onSuccess: () => {
				queryClient.invalidateQueries("evCardsList");
			},
		});

	const handleSubmitAddEditCard = async (
		props: EVCardParams
	): Promise<boolean> => {
		/* If an EV cards is selected, execute a function to edit an EV card */
		const mutate = !selectedCard
			? createCardMutateAsync
			: updateCardMutateAsync;

		const { message } = await mutate({ uuid: selectedCard?.uuid, ...props });

		if (!message) return true;

		if (
			message === t("The EV card was added successfully!", { lng: "en" }) ||
			message === t("The EV Card was updated successfully!", { lng: "en" })
		) {
			handleShowAlert({ message, severity: "success" });
			return true;
		}

		handleShowAlert({ message, severity: "error" });
		return false;
	};

	const handleUserUuidChange = (value: string) => {
		setUserUuid(value);
	};

	const handlePageChange = (pageNumber: number, size: number) => {
		setPaginationState({ page: pageNumber, pageSize: size });
	};

	return (
		<DashboardLayout>
			<div className="flex flex-col">
				<div className="mb-5">
					<span className="text-2xl font-medium pb-10">
						{t("evCardsTitle")}
					</span>
				</div>
			</div>
			<EVCards
				evCardList={evCardList}
				isLoading={
					isUserListLoading ||
					isVehicleListLoading ||
					createCardIsLoading ||
					updateCardIsLoading ||
					isEVCardListLoading
				}
				userOptions={userOptions}
				userVehicleOptions={userVehicleOptions}
				handleUserUuidChange={handleUserUuidChange}
				handleSubmitAddEditCard={handleSubmitAddEditCard}
				clearVehicleList={() => handleUserUuidChange("")}
				handlePageChange={handlePageChange}
				pageTotal={totalPages}
			/>
		</DashboardLayout>
	);
};

export default EVCardsPage;
