import { Select, SubmitButton, TextField } from "components/form/InputField";
import { Switch } from "@headlessui/react";
import Modal from "components/modals/Modal";
import { roleTypes } from "constants/index";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RolesParams, RolesResponse } from "types/ApplicationManagement";

const AddRoles = ({
	handleShowRoleModal,
	showModal,
	selectedRole,
	onSubmitForm,
	isLoading,
}: {
	handleShowRoleModal: () => void;
	showModal: boolean;
	selectedRole?: RolesResponse | null;
	isLoading: boolean;
	onSubmitForm: (data: RolesParams) => Promise<void>;
}) => {
	const { name, roleType, description, enabled } = selectedRole || {};
	const { t } = useTranslation(["settings", "common"]);
	const [isEnabled, setIsEnabled] = useState<boolean | undefined>(enabled);

	const form = useForm<RolesParams>({
		defaultValues: {
			roleType: roleType || "",
			name: name || "",
			description: description || "",
			enabled: enabled || false,
		},
	});

	const { handleSubmit, register, reset } = form;

	useEffect(() => {
		reset({
			roleType: roleType || "",
			name: name || "",
			enabled: enabled || false,
			description: description || "",
		});
	}, [selectedRole]);

	useEffect(() => {
		setIsEnabled(enabled);
	}, [enabled]);

	const onSubmit = async (props: any) => {
		const params = {
			...props,
			enabled: isEnabled,
		};

		onSubmitForm(params);
	};

	return (
		<Modal
			title={!selectedRole ? t("roles.addRole") : t("roles.updateRole")}
			show={showModal}
			onClose={() => handleShowRoleModal()}
		>
			<FormProvider {...form}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="mb-2">
						<TextField
							data-testid="roles-input"
							className="mb-2"
							name="name"
							label={t("roles.role")}
							required
							ref={register({ required: true })}
						/>
					</div>

					<div className="mb-2">
						<TextField
							data-testid="signin-email"
							className="mb-2"
							name="description"
							label={t("description", { ns: "common" })}
							required
							ref={register({ required: true })}
						/>
					</div>

					<div className="mb-2">
						<Select
							rootClass="col-lg-6 mb-2"
							name="roleType"
							label={t("type", { ns: "common" })}
							placeholder={t("roles.typeOfRole")}
							required
							ref={register({ required: true })}
							options={roleTypes}
						/>
					</div>

					{selectedRole && (
						<Switch.Group>
							<div className="mt-4  flex items-center">
								<Switch.Label
									passive
									className="mr-10 font-medium text-gray-700"
								>
									{t("roles.enableRole")}
								</Switch.Label>
								<Switch
									checked={isEnabled || false}
									onChange={() => setIsEnabled(!isEnabled)}
									className={`${
										isEnabled ? "bg-primary-color" : "bg-gray-200"
									} relative inline-flex h-6 w-12 items-center rounded-full`}
								>
									<span
										className={`${
											!isEnabled ? "translate-x-6" : "translate-x-1"
										} inline-block h-4 w-4 transform rounded-full bg-white`}
									/>
								</Switch>
							</div>
						</Switch.Group>
					)}

					<SubmitButton
						type="submit"
						loading={isLoading}
						label={selectedRole ? t("roles.updateRole") : t("roles.addRole")}
						className="mt-8 flex m-auto my-5 text-center"
					/>
				</form>
			</FormProvider>
		</Modal>
	);
};
export default AddRoles;
