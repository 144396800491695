import { useMutation } from "react-query";
import AuthLayout from "Layouts/AuthLayout";
import { SubmitButton, TextField } from "components/form/InputField";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { ForgetPasswordParams, ForgotPasswordResponse } from "types/Auth";
import { ForgetPassword } from "api/auth";
import { ErrorResponse } from "types";

const Form = () => {
	const { t } = useTranslation(["accounts"]);
	const [error, setError] = useState("");

	const { mutate, isLoading } = useMutation<
		ForgotPasswordResponse,
		ErrorResponse,
		ForgetPasswordParams
	>(ForgetPassword);

	const form = useForm({ defaultValues: { emailOrPhone: "" } });

	const { handleSubmit, register } = form;

	const onSubmit = async (props: ForgetPasswordParams) => {
		setError("");

		mutate(props, {
			onSuccess: (res) => {
				if (res.message !== "Password reset email sent successfully.") {
					setError(res.message!);
					return;
				}

				window.location.href = "/reset_password";
			},
		});
	};

	return (
		<div>
			<h1 className="mb-3 text-3xl font-medium">{t("forgotPassword")}</h1>
			<div className="flex">
				<p>{t("rememberedPassword")}</p>
				<a href="/login">
					<p className="ml-1 text-primary-color">{t("signin")}</p>
				</a>
			</div>
			<FormProvider {...form}>
				<form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
					<div className="mb-2">
						<TextField
							data-testid="signin-email"
							className="mb-2"
							name="emailOrPhone"
							label={t("enterEmailAddress")}
							placeholder="example@example.com"
							required
							ref={register({ required: true })}
							autoComplete="emailOrPhone"
						/>
					</div>

					{error && (
						<div>
							<span className="text-red-500">{error}</span>
						</div>
					)}
					<SubmitButton
						type="submit"
						loading={isLoading}
						label={t("sendResetEmail")}
						className="mt-8 flex m-auto my-5 text-center"
					/>
				</form>
			</FormProvider>
		</div>
	);
};

const ForgotPassword = () => (
	<AuthLayout>
		<Form />
	</AuthLayout>
);

export default ForgotPassword;
