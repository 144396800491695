import DashboardLayout from "Layouts/DashboardLayout";
import PermissionsGate from "Layouts/PermissionGate";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
	const { t } = useTranslation(["navigation"]);
	return (
		<DashboardLayout>
			<PermissionsGate
				scopes={["ROLE_CAN_VIEW_ROLE", "ROLE_CAN_ADD_SITE_OWNER"]}
			>
				{t("dashboard")}
			</PermissionsGate>
		</DashboardLayout>
	);
};

export default Dashboard;
