import { pathRolePermissions } from "constants/index";

const useRouteAuth = ({
	permissionNames,
	path,
}: {
	permissionNames: string[] | undefined;
	path: string;
}): { hasAccess: boolean } => {
	const checkAccess = (): boolean => {
		const selectedPathPermission = pathRolePermissions.get(path);
		if (permissionNames && selectedPathPermission) {
			return permissionNames.includes(selectedPathPermission);
		}
		return true;
	};

	return { hasAccess: checkAccess() };
};

export default useRouteAuth;
