import { Switch } from "@headlessui/react";
import { SubmitButton, Select, TextField } from "components/form/InputField";
import Modal from "components/modals/Modal";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
	MakeResponse,
	ModelResponse,
	VehicleModelType,
	VehicleParams,
	VehicleResponse,
} from "types/Settings";
import { yearOptions } from "utils";

const AddVehicles = ({
	handleShowVehicleModal,
	showModal,
	selectedVehicle,
	makesOptions,
	modelsOptions,
	isLoading,
	setFilteredModelsOptions,
	defaultModelsOptions,
	selectedMake,
	setSelectedMake,
	handleSelectedVehicle,
	onSubmitForm,
}: {
	handleShowVehicleModal: () => void;
	showModal: boolean;
	selectedVehicle: VehicleResponse | null;
	makesOptions: MakeResponse[];
	modelsOptions: ModelResponse[];
	isLoading: boolean;
	setFilteredModelsOptions: (models: ModelResponse[]) => void;
	defaultModelsOptions: ModelResponse[];
	selectedMake: string;
	setSelectedMake: (make: string) => void;
	handleSelectedVehicle: (vehicle: VehicleResponse | null) => void;
	onSubmitForm: (data: VehicleModelType) => Promise<void>;
}) => {
	const { t } = useTranslation(["settings"]);
	const {
		vehicleMake,
		vehicleModel,
		vehicleYear,
		uuid,
		registrationNumber,
		enabled,
	} = selectedVehicle || {};

	const [isEnabled, setIsEnabled] = useState<boolean | undefined>(enabled);

	const form = useForm<VehicleParams>({
		defaultValues: {
			year: vehicleYear || undefined,
			model: vehicleModel || "",
			uuid: uuid || "",
			registrationNumber: registrationNumber || "",
			enabled: enabled || true,
		},
	});

	const { handleSubmit, register, reset, getValues, setValue, watch } = form;

	/* When the make selection is changed, reset the model selection
	 	 Because models are dependent on makes */
	const watchMakeField = watch("make", vehicleMake);
	useEffect(() => {
		setValue("model", "");
	}, [watchMakeField]);

	useEffect(() => {
		reset({
			year: vehicleYear || undefined,
			make: vehicleMake || "",
			model: vehicleModel || "",
			uuid: uuid || "",
			registrationNumber: registrationNumber || "",
			enabled: enabled || true,
		});
		setIsEnabled(enabled);
	}, [selectedVehicle]);

	const onSubmit = async (props: any) => {
		onSubmitForm({
			vehicleMake: props.make,
			vehicleModel: props.model,
			year: parseInt(props.year, 10),
			registrationNumber: props.registrationNumber,
			enabled: isEnabled || true,
		});
	};

	/* Change the options based on the selected make, when the value of the form is changed */
	const setFIlteredModelOptions = () => {
		const makeSelectedInForm = getValues("make");
		setSelectedMake(makeSelectedInForm);
		const filteredModels = defaultModelsOptions.filter(
			(m) => m.vehicleMake.name === makeSelectedInForm
		);
		setFilteredModelsOptions(filteredModels);
	};

	return (
		<Modal
			title={
				selectedVehicle ? t("vehicles.updateVehicle") : t("vehicles.addVehicle")
			}
			show={showModal}
			onClose={() => {
				handleShowVehicleModal();
				handleSelectedVehicle(null);
			}}
		>
			<FormProvider {...form}>
				<form
					onSubmit={handleSubmit(onSubmit)}
					onChange={() => setFIlteredModelOptions()}
				>
					<div className="mb-2">
						<Select
							rootClass="col-lg-6 mb-2"
							name="make"
							label={t("vehicles.make")}
							placeholder={t("vehicles.makePlaceholder")}
							required
							ref={register({ required: true })}
							options={makesOptions}
						/>
					</div>

					<div className="mb-2">
						<Select
							rootClass="col-lg-6 mb-2"
							name="model"
							label={t("vehicles.model")}
							placeholder={
								selectedMake
									? t("vehicles.modelPlaceholder")
									: t("vehicles.chooseMakeFirst")
							}
							required
							ref={register({ required: true })}
							options={modelsOptions}
						/>
					</div>

					<div className="mb-2">
						<Select
							rootClass="col-lg-6 mb-2"
							name="year"
							label={t("vehicles.year")}
							placeholder={t("vehicles.yearPlaceholder")}
							required
							ref={register({ required: true })}
							options={yearOptions}
						/>
					</div>

					<div className="mb-2">
						<TextField
							data-testid="add-vehicle-registration-number"
							className="mb-2"
							name="registrationNumber"
							label={t("vehicles.registrationNumber")}
							required
							ref={register({ required: true })}
						/>
					</div>

					{selectedVehicle && (
						<Switch.Group>
							<div className="mt-4  flex items-center">
								<Switch.Label
									passive
									className="mr-10 font-medium text-gray-700"
								>
									{t("vehicles.enableVehicle")}
								</Switch.Label>
								<Switch
									checked={isEnabled || false}
									onChange={() => setIsEnabled(!isEnabled)}
									className={`${
										isEnabled ? "bg-primary-color" : "bg-gray-200"
									} relative inline-flex h-6 w-12 items-center rounded-full`}
								>
									<span
										className={`${
											!isEnabled ? "translate-x-6" : "translate-x-1"
										} inline-block h-4 w-4 transform rounded-full bg-white`}
									/>
								</Switch>
							</div>
						</Switch.Group>
					)}

					<SubmitButton
						type="submit"
						loading={isLoading}
						label={
							selectedVehicle
								? t("vehicles.updateVehicle")
								: t("vehicles.addVehicle")
						}
						className="mt-8 flex m-auto my-5 text-center"
					/>
				</form>
			</FormProvider>
		</Modal>
	);
};
export default AddVehicles;
