import SearchInput from "components/common/SearchInput";
import { useTranslation } from "react-i18next";

export const EVCardTransactionToolbar = ({
	handleSubmit,
}: {
	handleSubmit: (data: string) => void;
}) => {
	const { t } = useTranslation(["evCards"]);
	return (
		<div className="my-6">
			<SearchInput
				handleSubmit={handleSubmit}
				placeholder={t("searchTransactionPlaceholder")}
			/>
		</div>
	);
};

export default EVCardTransactionToolbar;
