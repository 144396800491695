import { useTranslation } from "react-i18next";
import SearchInput from "components/common/SearchInput";
import { SubmitButton } from "components/form/InputField";

const PermissionToolbar = ({
	handleSubmit,
	handleShowPermissionModal,
}: {
	handleSubmit: (data: string) => void;
	handleShowPermissionModal: () => void;
}) => {
	const { t } = useTranslation(["settings"]);

	return (
		<div className="py-4 justify-between items-end md:items-center flex flex-col md:flex-row">
			<SearchInput
				handleSubmit={handleSubmit}
				placeholder={t("permissions.searchPermissionPlaceholder")}
			/>
			<SubmitButton
				className="my-5 rounded-[5px]"
				type="button"
				onClick={handleShowPermissionModal}
				label={t("permissions.addPermission")}
			/>
		</div>
	);
};

export default PermissionToolbar;
