import {
	createOrganizationType,
	fetchOrganizationType,
	updateOrganizationType,
} from "api/application-management";
import OrganizationType from "components/applicationSettings/organization-type";
import { AlertContext } from "context/AlertContext";
import { ApplicationManagementContext } from "context/ApplicationManagementContext";
import DashboardLayout from "Layouts/DashboardLayout";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ErrorResponse } from "types";
import {
	OrganizationTypeParams,
	OrganizationTypeResponse,
} from "types/ApplicationManagement";

const OrganisationTypeManagement = () => {
	const queryClient = useQueryClient();
	const { t } = useTranslation(["constants", "application"]);

	const { isLoading: organisationTypeLoading, data: organisationTypeData } =
		useQuery<OrganizationTypeResponse[]>(
			"fetchOrganizationType",
			fetchOrganizationType
		);

	const { handleShowAlert } = useContext(AlertContext);
	const { selectedOrganisationType } = useContext(ApplicationManagementContext);

	const { mutateAsync, isLoading } = useMutation<
		OrganizationTypeResponse,
		ErrorResponse,
		OrganizationTypeParams
	>(createOrganizationType, {
		onSuccess: () => {
			queryClient.invalidateQueries("fetchOrganizationType");
		},
	});

	const { mutateAsync: updateMutateAsync, isLoading: updateIsloading } =
		useMutation<
			OrganizationTypeResponse,
			ErrorResponse,
			OrganizationTypeParams
		>(updateOrganizationType, {
			onSuccess: () => {
				queryClient.invalidateQueries("fetchOrganizationType");
			},
		});

	const handleSubmitAddEditRoles = async (
		props: OrganizationTypeParams
	): Promise<boolean> => {
		const mutate = !selectedOrganisationType ? mutateAsync : updateMutateAsync;

		const { message } = await mutate({
			uuid: selectedOrganisationType?.uuid,
			...props,
		});

		if (!message) return true;

		if (
			message === t("Organization type created successfully", { lng: "en" }) ||
			message === t("Organization type updated successfully", { lng: "en" })
		) {
			handleShowAlert({ message, severity: "success" });
			return true;
		}

		handleShowAlert({ message, severity: "error" });

		return false;
	};

	return (
		<DashboardLayout>
			<div className="flex flex-col">
				<div className="mb-5">
					<span className="text-2xl font-medium pb-10">
						{t("Organization Types", { ns: "application" })}
					</span>
				</div>

				<OrganizationType
					organizationType={organisationTypeData || []}
					isLoading={organisationTypeLoading || isLoading || updateIsloading}
					handleSubmitAddEditOrganizationType={handleSubmitAddEditRoles}
				/>
			</div>
		</DashboardLayout>
	);
};

export default OrganisationTypeManagement;
