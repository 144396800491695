import {
	ForgetPasswordParams,
	LoginParams,
	ResetPasswordParams,
	ChangePasswordParams,
	SignupParams,
	TokenParams,
} from "types/Auth";
import apiRequestHandler from "utils/apiRequestHandler";

const Signup = (params: SignupParams): any => {
	const res = apiRequestHandler({
		url: "/auth/signup",
		data: params,
		method: "post",
	});
	return res;
};

const VerificationCode = (params: TokenParams): any => {
	const res = apiRequestHandler({
		url: "/auth/confirmation",
		data: params,
		method: "post",
	});
	return res;
};

const ResendCode = (params: TokenParams): any => {
	const res = apiRequestHandler({
		url: "/auth/resend-token",
		data: params,
		method: "post",
	});
	return res;
};

const Login = (params: LoginParams): any => {
	const res = apiRequestHandler({
		url: "/auth/signin",
		data: params,
		method: "post",
	});
	return res;
};

const ForgetPassword = (params: ForgetPasswordParams): any => {
	const res = apiRequestHandler({
		url: "/auth/forgot-password",
		data: params,
		method: "post",
	});
	return res;
};

const ResetPassword = (params: ResetPasswordParams): any => {
	const res = apiRequestHandler({
		url: "/auth/reset-password",
		data: params,
		method: "post",
	});
	return res;
};

const ChangePassword = (params: ChangePasswordParams): any => {
	const res = apiRequestHandler({
		url: "/account/change-password",
		data: params,
		method: "post",
	});
	return res;
};

const fetchLoggedInUser = (): any => {
	const res = apiRequestHandler({
		url: "/account",
		data: {},
		method: "get",
	});
	return res;
};

// update user info later
// const UpdateUser = (params: ChangePasswordParams, uuid: string): any => {
// 	const res = apiRequestHandler({
// 		url: `/site-owners/${uuid}`,
// 		data: params,
// 		method: "put",
// 	});
// 	return res;
// };

export {
	Signup,
	VerificationCode,
	ForgetPassword,
	ChangePassword,
	// UpdateUser,
	ResetPassword,
	ResendCode,
	Login,
	fetchLoggedInUser,
};
