import Alerts from "components/modals/AlertModal";
import SettingsLayout from "Layouts/SettingsLayout";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import {
	MakeResponse,
	ModelResponse,
	VehicleDataType,
	VehicleModelType,
	VehicleResponse,
} from "types/Settings";
import MoreOptionsMenu from "components/common/MoreOptionsMenu";
import { SettingsContext } from "context/SettingsContext";
import classnames from "classnames";
import { localeCompareForTable } from "utils";
import AddVehicles from "./AddVehicles";
import VehicleToolbar from "./VehicleToolbar";

const VehicleSettings = ({
	makesOptions,
	modelsOptions,
	vehicles,
	isLoading,
	handleSubmitAddEditVehicle,
}: {
	makesOptions: MakeResponse[];
	modelsOptions: ModelResponse[];
	vehicles: VehicleResponse[];
	isLoading: boolean;
	handleSubmitAddEditVehicle: (props: any) => Promise<boolean>;
}) => {
	const { t } = useTranslation(["settings", "common"]);
	const [tableData, setTableData] = useState<VehicleDataType[]>([]);
	const [showModal, setShowModal] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [filteredModelsOptions, setFilteredModelsOptions] = useState<
		ModelResponse[]
	>([]);
	const [selectedMake, setSelectedMake] = useState<string>("");

	const { handleSelectedVehicle, selectedVehicle } =
		useContext(SettingsContext);

	useEffect(() => {
		setTableData(dataSource);
	}, [vehicles]);

	useEffect(() => {
		setFilteredModelsOptions(modelsOptions);
	}, [modelsOptions]);

	/* Create the data for the table from the fetch vehicle result */
	const dataSource: VehicleDataType[] = vehicles.map(
		({ uuid, vehicleMake, vehicleModel, year, enabled }) => ({
			key: uuid,
			make: vehicleMake,
			model: vehicleModel,
			year,
			status: enabled,
		})
	);

	/* Show the search result in the table */
	const handleSearch = (searchTerm: string) => {
		const result = dataSource.filter(
			(data: VehicleDataType) =>
				data.make.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0 ||
				data.model.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0 ||
				data.year.toString().indexOf(searchTerm.toLowerCase()) >= 0
		);
		setTableData(result);
		if (!searchTerm) {
			setTableData(dataSource);
		}
	};

	const handleShowVehicleModal = (data?: VehicleDataType) => {
		if (data) {
			const vehicleRes = vehicles.find((vehicle) => vehicle.uuid === data.key);
			vehicleRes && handleSelectedVehicle(vehicleRes);
			/* This filters model options, based on make property of selected vehicle */
			setFilteredModelsOptions(
				modelsOptions.filter((model) => model.vehicleMake.name === data.make)
			);
			setSelectedMake(data.make);
		} else {
			handleSelectedVehicle(null);
		}
		setShowModal(!showModal);
	};

	const handleShowAlertModal = () => {
		setShowAlert(!showAlert);
	};

	const handleSubmit = async (data: VehicleModelType) => {
		const res = await handleSubmitAddEditVehicle(data);
		if (res) {
			handleShowVehicleModal();
		}
		handleSelectedVehicle(null);
		setShowModal(!showModal);
	};

	const columns: ColumnsType<VehicleDataType> = [
		{
			title: t("vehicles.make"),
			dataIndex: "make",
			key: "make",
			sorter: (a, b) => localeCompareForTable(a.make, b.make),
		},
		{
			title: t("vehicles.model"),
			dataIndex: "model",
			key: "model",
			sorter: (a, b) => localeCompareForTable(a.model, b.model),
		},
		{
			title: t("vehicles.year"),
			dataIndex: "year",
			key: "year",
			sorter: (a, b) => a.year - b.year,
		},
		{
			dataIndex: "status",
			title: t("status", { ns: "common" }),
			key: "status",
			render: (isEnabled: boolean) => (
				<p
					className={classnames(
						isEnabled ? "bg-primary-color" : "bg-gray-300",
						"rounded-full",
						"w-24",
						"py-2",
						"text-xs",
						"text-center",
						"text-white"
					)}
				>
					{isEnabled
						? t("enabled", { ns: "common" })
						: t("disabled", { ns: "common" })}
				</p>
			),
		},
		{
			title: t("action", { ns: "common" }),
			key: "operation",
			fixed: "right",
			align: "center",
			width: 100,
			render: (item) => <MoreOptionsMenu menuList={List} data={item} />,
		},
	];

	const List = [
		{
			label: t("edit", { ns: "common" }),
			onClick: (data: VehicleDataType) => handleShowVehicleModal(data),
		},
	];

	return (
		<SettingsLayout>
			<VehicleToolbar
				handleShowVehicleModal={handleShowVehicleModal}
				handleSubmit={handleSearch}
			/>
			<Table
				dataSource={tableData}
				columns={columns}
				scroll={{ y: "32vh", x: 900 }}
				pagination={{ defaultPageSize: 10, showSizeChanger: true }}
			/>
			<AddVehicles
				showModal={showModal}
				selectedVehicle={selectedVehicle}
				handleShowVehicleModal={handleShowVehicleModal}
				makesOptions={makesOptions}
				setFilteredModelsOptions={setFilteredModelsOptions}
				modelsOptions={filteredModelsOptions}
				defaultModelsOptions={modelsOptions}
				isLoading={isLoading}
				selectedMake={selectedMake}
				setSelectedMake={setSelectedMake}
				handleSelectedVehicle={handleSelectedVehicle}
				onSubmitForm={handleSubmit}
			/>
			<Alerts
				message={t("alerts.messages.confirmDeleteVehicle", { ns: "common" })}
				show={showAlert}
				type="warning"
				deny={handleShowAlertModal}
				onClose={handleShowAlertModal}
				confirm={handleShowAlertModal}
				confirmText={t("alerts.confirm", { ns: "common" })}
				denyText={t("alerts.deny", { ns: "common" })}
			/>
		</SettingsLayout>
	);
};

export default VehicleSettings;
