import { useTranslation } from "react-i18next";
import SearchInput from "components/common/SearchInput";
import { SubmitButton } from "components/form/InputField";
import { RoleDataType } from "types/ApplicationManagement";

const OrganizationToolbar = ({
	handleSubmit,
	handleShowAddStationOwnerModal,
}: {
	handleSubmit: (data: string) => void;
	handleShowAddStationOwnerModal: (data?: RoleDataType) => void;
}) => {
	const { t } = useTranslation(["usersManagement"]);

	return (
		<div className="py-4 justify-between items-end md:items-center flex flex-col md:flex-row">
			<SearchInput
				handleSubmit={handleSubmit}
				placeholder={t("seachOrganization")}
			/>
			<SubmitButton
				className="my-5 rounded-full"
				type="button"
				onClick={handleShowAddStationOwnerModal}
				label={t("createOrganization")}
			/>
		</div>
	);
};

export default OrganizationToolbar;
