import { useMutation } from "react-query";
import AuthLayout from "Layouts/AuthLayout";
import {
	PasswordField,
	SubmitButton,
	TextField,
} from "components/form/InputField";
import { AuthContext } from "context/AuthContext";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { Login } from "api/auth";
import { LoginParams, UserInfo } from "types/Auth";
import { ErrorResponse } from "types";

const Form = () => {
	const { t } = useTranslation(["accounts"]);
	const [error, setError] = useState("");
	const { login } = useContext(AuthContext);

	const { mutate, isLoading } = useMutation<
		UserInfo,
		ErrorResponse,
		LoginParams
	>(Login);

	const form = useForm<LoginParams>({
		defaultValues: { emailOrPhone: "", password: "" },
	});

	const { handleSubmit, register } = form;

	const onSubmit = async (props: LoginParams) => {
		setError("");
		mutate(props, {
			onSuccess: (data) => {
				if (data.message) {
					setError(data.message);
					return;
				}
				login(data);
			},
		});
	};

	return (
		<div>
			<h1 className="mb-3 text-3xl font-medium">{t("welcomeBack")}</h1>
			<div className="flex">
				<p>{t("noAccount")}</p>
				<a href="/signup">
					<p className="ml-1 text-primary-color">{t("createAccount")}</p>
				</a>
			</div>
			<FormProvider {...form}>
				<form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
					<TextField
						data-testid="signin-email"
						className="mb-2"
						name="emailOrPhone"
						label={t("emailAddress")}
						placeholder="example@example.com"
						required
						ref={register({ required: true })}
						autoComplete="username"
					/>

					<PasswordField
						data-testid="signin-password"
						className="mb-2"
						name="password"
						label={t("password")}
						ref={register({ required: true })}
						required
						placeholder="∗∗∗∗∗∗∗∗"
						autoComplete="current-password"
					/>

					{error && (
						<div>
							<span className="text-red-500">{error}</span>
						</div>
					)}
					<SubmitButton
						type="submit"
						loading={isLoading}
						disabled={isLoading}
						label={t("signin")}
						className="mt-8 flex m-auto my-5 text-center"
					/>

					<div className="text-center">
						<a href="/forgot_password">
							<span className="text-primary-color">
								{t("forgotPasswordPrompt")}
							</span>
						</a>
					</div>
				</form>
			</FormProvider>
		</div>
	);
};

const LoginPage = () => (
	<AuthLayout>
		<Form />
	</AuthLayout>
);

export default LoginPage;
