import { useMutation } from "react-query";
import AuthLayout from "Layouts/AuthLayout";
import { SubmitButton, PasswordField } from "components/form/InputField";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { ResetPassword } from "api/auth";
import { ResetPasswordParams, ResetPasswordResponse } from "types/Auth";
import { ErrorResponse } from "types";
import { CodeForm } from "./CodeVerification";

const Form = () => {
	const { t } = useTranslation(["accounts"]);
	const [error, setError] = useState<string>("");
	const [code, setCode] = useState<string>("");

	const { mutate, isLoading } = useMutation<
		ResetPasswordResponse,
		ErrorResponse,
		ResetPasswordParams
	>(ResetPassword);

	const form = useForm({
		defaultValues: { matchingPassword: "", password: "" },
	});

	const { handleSubmit, register } = form;

	const onSubmit = async (props: ResetPasswordParams) => {
		if (!code) return;

		setError("");
		mutate(
			{ ...props, token: code },
			{
				onSuccess: (res) => {
					if (
						res.message !==
						"Your password was changed successfully.Your password was changed successfully."
					) {
						setError(res.message!);
						setCode("");
						return;
					}

					window.location.href = "/login";
				},
			}
		);
	};

	const handleCodeSubmit = (data: string) => {
		setCode(data);
	};

	return (
		<div>
			<h1 className="mb-3 text-3xl font-medium">{t("resetPassword")}</h1>
			{code && (
				<div className="flex">
					<p>{t("rememberedPassword")}</p>
					<a href="/login">
						<p className="ml-1 text-primary-color">{t("signin")}</p>
					</a>
				</div>
			)}

			{!code ? (
				<div>
					<div className="flex">
						<p className="text-primary-color">{t("enterCodePrompt")}</p>
					</div>
					<CodeForm onSubmit={handleCodeSubmit} isForm />
					{error && (
						<div>
							<span className="text-red-600 text-xs">{error}</span>
						</div>
					)}
				</div>
			) : (
				<FormProvider {...form}>
					<form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
						<PasswordField
							data-testid="signin-password"
							className="mb-2"
							name="password"
							label={t("newPassword")}
							required
							placeholder={t("newPassword")}
							ref={register({ required: true })}
							autoComplete="password"
						/>
						<div className="mt-2">
							<PasswordField
								data-testid="signin-password-confirm"
								className="mb-2"
								name="matchingPassword"
								label={t("confirmPassword")}
								required
								placeholder={t("confirmPassword")}
								ref={register({ required: true })}
								autoComplete="matchingPassword"
							/>
						</div>

						{error && (
							<div>
								<span className="text-red-600 text-xs">{error}</span>
							</div>
						)}
						<SubmitButton
							type="submit"
							loading={isLoading}
							label={`${t("resetPassword")} ->`}
							className="mt-8 flex m-auto my-5 text-center"
						/>
					</form>
				</FormProvider>
			)}
		</div>
	);
};

const LoginPage = () => (
	<AuthLayout>
		<Form />
	</AuthLayout>
);

export default LoginPage;
