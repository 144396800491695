import DashboardLayout from "Layouts/DashboardLayout";
import { useState, useEffect, useContext } from "react";
import { AlertContext } from "context/AlertContext";
import { SiteContext } from "context/SitesContext";
import _ from "lodash";
import Site from "components/usersManagement/site/Site";
import { useParams } from "react-router-dom";
import {
	CreateChargingStations,
	UpdateChargingStations,
	GetSiteBySiteUUID,
	GetChargingStationsBySiteUUID,
} from "api/admin";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ErrorResponse } from "types";
import {
	ChargingStationParams,
	ChargingStationResponse,
	SiteResponse,
} from "types/Stations";
import { useTranslation } from "react-i18next";

const SitePage = () => {
	const params: { siteUUID: string } = useParams();
	const { siteUUID } = params || { siteUUID: "" };
	const [pagination, setPaginationState] = useState({ page: 0, pageSize: 10 });
	const { t } = useTranslation(["constants"]);

	const { handleShowAlert } = useContext(AlertContext);
	const { selectedChargingStation } = useContext(SiteContext);
	const queryClient = useQueryClient();

	// Get Site
	const { isLoading: siteIsLoading, data: site } = useQuery("site", () =>
		GetSiteBySiteUUID(siteUUID)
	);
	const { name } = site || { name: "" };

	// Get Chargers
	const {
		isLoading: chargersIsLoading,
		data,
		refetch,
	} = useQuery("chargingStations", () =>
		GetChargingStationsBySiteUUID(siteUUID)
	);

	const chargers: ChargingStationResponse[] = _.get(data, "content") || [];
	const totalPages: number = _.get(data, "totalPages") || 0;

	useEffect(() => {
		refetch();
	}, [pagination]);

	const handlePageChange = (pageNumber: number, size: number) => {
		setPaginationState({ page: pageNumber, pageSize: size });
	};

	// Create Charger
	const {
		mutateAsync: createChargingStationMutateAsync,
		isLoading: isLoadingCreateChargingStation,
	} = useMutation<SiteResponse, ErrorResponse, ChargingStationParams>(
		CreateChargingStations,
		{
			onSuccess: () => {
				queryClient.invalidateQueries("chargingStations");
			},
		}
	);

	// Update Charger
	const {
		mutateAsync: updateChargingStationMutateAsync,
		isLoading: isLoadingUpdateChargingStation,
	} = useMutation<SiteResponse, ErrorResponse, ChargingStationParams>(
		UpdateChargingStations,
		{
			onSuccess: () => {
				queryClient.invalidateQueries("chargingStations");
			},
		}
	);

	const handleSubmitAddEditChargingStation = async (
		props: ChargingStationParams
	): Promise<boolean> => {
		const mutate = !selectedChargingStation
			? createChargingStationMutateAsync
			: updateChargingStationMutateAsync;

		const { message } = await mutate({
			...props,
			uuid: selectedChargingStation?.uuid,
		});

		if (!message) return true;

		if (
			message ===
				t("The charging station was updated successfully!", { lng: "en" }) ||
			message ===
				t("The charging station was added successfully!", { lng: "en" })
		) {
			handleShowAlert({ message, severity: "success" });
			return true;
		}

		handleShowAlert({ message, severity: "error" });

		return false;
	};

	return (
		<DashboardLayout>
			<div className="flex flex-col">
				<span className="text-2xl font-medium pb-10">{name}</span>
				{!siteIsLoading ? (
					<Site
						site={site}
						isLoading={
							siteIsLoading ||
							chargersIsLoading ||
							isLoadingCreateChargingStation ||
							isLoadingUpdateChargingStation
						}
						chargers={chargers}
						handlePageChange={handlePageChange}
						pageTotal={totalPages}
						handleSubmitAddEditChargingStation={
							handleSubmitAddEditChargingStation
						}
					/>
				) : null}
			</div>
		</DashboardLayout>
	);
};
export default SitePage;
