import {
	addPermissions,
	fetchPermissions,
	updatePermissions,
} from "api/application-management";
import PermissionsList from "components/applicationSettings/permissions";
import { AlertContext } from "context/AlertContext";
import { useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ErrorResponse } from "types";
import {
	PermissionsParams,
	PermissionsResponse,
} from "types/ApplicationManagement";
import DashboardLayout from "Layouts/DashboardLayout";
import { useTranslation } from "react-i18next";
import { ApplicationManagementContext } from "context/ApplicationManagementContext";

const Permissions = () => {
	const { t } = useTranslation(["constants", "settings"]);
	const queryClient = useQueryClient();

	const { isLoading: permissionsLoading, data: permissionsData } = useQuery<
		PermissionsResponse[]
	>("permissions", () => fetchPermissions());
	const sortedPermissions = permissionsData?.sort((a, b) =>
		a.createdAt < b.createdAt ? 1 : -1
	);

	const { handleShowAlert } = useContext(AlertContext);
	const { selectedPermission } = useContext(ApplicationManagementContext);

	const { mutateAsync, isLoading } = useMutation<
		PermissionsResponse,
		ErrorResponse,
		PermissionsParams
	>(addPermissions, {
		onSuccess: () => {
			queryClient.invalidateQueries("permissions");
		},
	});

	const { mutateAsync: updateMutateAsync, isLoading: updateIsloading } =
		useMutation<PermissionsResponse, ErrorResponse, PermissionsParams>(
			updatePermissions,
			{
				onSuccess: () => {
					queryClient.invalidateQueries("permissions");
				},
			}
		);

	const handleSubmitAddEditPermissions = async (
		props: PermissionsParams
	): Promise<boolean> => {
		const mutate = !selectedPermission ? mutateAsync : updateMutateAsync;

		const { message } = await mutate({
			uuid: selectedPermission?.uuid,
			...props,
		});

		if (!message) return true;

		if (
			message === t("Permission created successfully", { lng: "en" }) ||
			message === t("Permission updated successfully", { lng: "en" })
		) {
			handleShowAlert({ message, severity: "success" });
			return true;
		}

		handleShowAlert({ message, severity: "error" });

		return false;
	};

	return (
		<DashboardLayout>
			<div className="flex flex-col">
				<div className="mb-5">
					<span className="text-2xl font-medium pb-10">
						{t("menu.permissions", { ns: "settings" })}
					</span>
				</div>

				<PermissionsList
					permissions={sortedPermissions || []}
					isLoading={permissionsLoading || isLoading || updateIsloading}
					handleSubmitAddEditPermissions={handleSubmitAddEditPermissions}
				/>
			</div>
		</DashboardLayout>
	);
};

export default Permissions;
