import { useTranslation } from "react-i18next";
import SearchInput from "components/common/SearchInput";
import { SubmitButton } from "components/form/InputField";
import { RoleDataType } from "types/ApplicationManagement";
import MultiSelection from "components/common/MultiSelection";
import { MdKeyboardArrowDown } from "react-icons/md";

const FilterButton = ({ label }: { label: string }) => (
	<span className="flex items-center text-bold">
		<MdKeyboardArrowDown className="mr-2" /> {label}
	</span>
);

const RolesToolbar = ({
	handleSubmit,
	handleShowRoleModal,
	filterByRoleType,
	setFilterByRoleType,
	roleTypes,
}: {
	handleSubmit: (data: string) => void;
	handleShowRoleModal: (data?: RoleDataType) => void;
	filterByRoleType: string[];
	setFilterByRoleType: (data: string[]) => void;
	roleTypes: { name: string; value: string }[];
}) => {
	const { t } = useTranslation(["settings", "application"]);

	const onChangeFilterByRoleType = (checkedValues: string[]) => {
		setFilterByRoleType(checkedValues);
	};

	const roleTypeOptions = roleTypes.map((role) => ({
		label: role.name,
		value: role.value,
	}));

	return (
		<div className="py-4 justify-between items-end md:items-center flex flex-col md:flex-row">
			<div className="flex items-center">
				<SearchInput
					handleSubmit={handleSubmit}
					placeholder={t("roles.searchRolePlaceholder")}
				/>
				<span className="ml-10">
					<MultiSelection
						btnIcon={
							<FilterButton
								label={t("filterByRoleTypes", { ns: "application" })}
							/>
						}
						options={roleTypeOptions}
						pnlClassName="p-4 w-44 border"
						onChange={(checkedValues) =>
							onChangeFilterByRoleType(checkedValues)
						}
						defaultValue={filterByRoleType}
					/>
				</span>
			</div>
			<SubmitButton
				className="my-5 rounded-full"
				type="button"
				onClick={handleShowRoleModal}
				label={t("roles.addRole")}
			/>
		</div>
	);
};

export default RolesToolbar;
