const usePermissionGate = ({
	permissionNames,
	scopes,
}: {
	permissionNames: string[] | undefined;
	scopes: string[];
}): { hasPermission: boolean } => {
	const hasPermission = (): boolean =>
		scopes.some((scope) => {
			if (permissionNames && permissionNames.includes(scope)) {
				return true;
			}
			return false;
		});

	return { hasPermission: hasPermission() };
};

export default usePermissionGate;
