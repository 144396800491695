import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { BreadCrumbsProps } from "types/index";
import { getTodayDate } from "utils";

const Breadcrumbs = ({ breadCrumb }: { breadCrumb: BreadCrumbsProps[] }) => {
	const itemRender = (route: any, _: any, routes: any, __: any) => {
		const last = routes.indexOf(route) === routes.length - 1;
		return last ? (
			<span>{route.breadcrumbName}</span>
		) : (
			<Link to={route.path} replace>
				{route.breadcrumbName}
			</Link>
		);
	};

	return (
		<div className="flex justify-between mb-5">
			<Breadcrumb itemRender={itemRender} routes={breadCrumb} />
			<span className="hidden md:block">{getTodayDate()}</span>
		</div>
	);
};

export default Breadcrumbs;
