import Breadcrumbs from "components/breadcrumbs";
import {
	UserDataSource,
	UserParams,
	UsersResponse,
} from "types/UsersManagement";
import { useTranslation } from "react-i18next";
import { BreadCrumbsProps } from "types/index";
import { UserContext } from "context/UserContext";
import { useContext, useState } from "react";
import { RolesResponse } from "types/ApplicationManagement";
import { OrganizationResponse } from "types/UserManagement";
import UserList from "./UserList";
import AddEditUser from "./AddEditUsers";

const UserManagement = ({
	users,
	rolesOptions,
	isLoading,
	handleSubmitAddEditUser,
	handlePageChange,
	pageTotal,
	organizations,
	handleSearchOrganization,
}: {
	users: UsersResponse[];
	rolesOptions: RolesResponse[];
	isLoading: boolean;
	pageTotal: number;
	organizations: OrganizationResponse[];
	handlePageChange: (pageNumber: number, size: number) => void;
	handleSubmitAddEditUser: (data: UserParams) => Promise<boolean>;
	handleSearchOrganization: (value: string) => void;
}) => {
	const { t } = useTranslation(["navigation", "common"]);
	const [showModal, setShowModal] = useState(false);
	const { handleSelectedUser, selectedUser } = useContext(UserContext);

	const routes: BreadCrumbsProps[] = [
		{
			path: "/dashboard",
			breadcrumbName: t("home"),
		},
		{
			path: "/site-owners",
			breadcrumbName: t("users", { ns: "common" }),
		},
	];

	const userListForTable = users.map(
		({ uuid: key, firstName, lastName, email, enabled, roleNames }) => ({
			key,
			firstName,
			lastName,
			email,
			enabled,
			roleNames,
		})
	);

	const handleShowUserModal = (data?: UserDataSource) => {
		if (data) {
			const userRes = users.find((user) => user.firstName === data.firstName);
			userRes && handleSelectedUser(userRes);
		} else {
			handleSelectedUser(null);
		}
		setShowModal(!showModal);
	};

	const handleSubmit = async (data: UserParams) => {
		const res = await handleSubmitAddEditUser(data);
		if (res) {
			handleShowUserModal();
			handleSelectedUser(null);
		}
	};

	return (
		<>
			<Breadcrumbs breadCrumb={routes} />
			<UserList
				handleShowUserModal={handleShowUserModal}
				users={userListForTable}
				pageTotal={pageTotal}
				handlePageChange={handlePageChange}
			/>
			<AddEditUser
				handleShowUserModal={handleShowUserModal}
				showModal={showModal}
				selectedUser={selectedUser}
				rolesOptions={rolesOptions}
				onSubmitForm={handleSubmit}
				isLoading={isLoading}
				organizations={organizations}
				handleSearchOrganization={handleSearchOrganization}
			/>
		</>
	);
};

export default UserManagement;
