import React, { useState } from "react";
import { MakeParams, VehicleModelParams } from "types/Settings";
import {
	RolesResponse,
	PermissionsResponse,
	OrganizationTypeResponse,
} from "types/ApplicationManagement";

const ApplicationManagementContext = React.createContext<{
	selectedMake: MakeParams | null;
	handleSelectedMake: (data: MakeParams | null) => void;
	selectedModel: VehicleModelParams | null;
	handleSelectedModel: (data: VehicleModelParams | null) => void;
	selectedRole: RolesResponse | null;
	handleSelectedRole: (data: RolesResponse | null) => void;
	selectedPermission: PermissionsResponse | null;
	handleSelectedPermission: (data: PermissionsResponse | null) => void;
	selectedOrganisationType: OrganizationTypeResponse | null;
	handleSelectedOrganisationType: (
		data: OrganizationTypeResponse | null
	) => void;
}>({
	selectedMake: null,
	handleSelectedMake: () => {},
	selectedRole: null,
	handleSelectedRole: () => {},
	selectedModel: null,
	handleSelectedModel: () => {},
	selectedPermission: null,
	handleSelectedPermission: () => {},
	selectedOrganisationType: null,
	handleSelectedOrganisationType: () => {},
});

const ApplicationManagementContextProvider = (props: {
	children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}) => {
	const [selectedMake, setSelectedMake] = useState<MakeParams | null>(null);
	const [selectedModel, setSelectedModel] = useState<VehicleModelParams | null>(
		null
	);
	const [selectedRole, setSelectedRole] = useState<RolesResponse | null>(null);
	const [selectedPermission, setSelectedPermission] =
		useState<PermissionsResponse | null>(null);
	const [selectedOrganisationType, setSelectedOrganisationType] =
		useState<OrganizationTypeResponse | null>(null);

	const handleSelectedMake = (data: MakeParams | null) => {
		setSelectedMake(data);
	};

	const handleSelectedModel = (data: VehicleModelParams | null) => {
		setSelectedModel(data);
	};

	const handleSelectedRole = (data: RolesResponse | null) => {
		setSelectedRole(data);
	};

	const handleSelectedPermission = (data: PermissionsResponse | null) => {
		setSelectedPermission(data);
	};

	const handleSelectedOrganisationType = (
		data: OrganizationTypeResponse | null
	) => {
		// eslint-disable-next-line no-console
		console.log("context", data);
		setSelectedOrganisationType(data);
	};

	const value = React.useMemo(
		() => ({
			selectedMake,
			selectedModel,
			selectedRole,
			selectedPermission,
			selectedOrganisationType,
			handleSelectedMake,
			handleSelectedModel,
			handleSelectedRole,
			handleSelectedPermission,
			handleSelectedOrganisationType,
		}),
		[
			selectedMake,
			selectedModel,
			selectedOrganisationType,
			selectedRole,
			selectedPermission,
		]
	);

	return (
		<ApplicationManagementContext.Provider value={value}>
			{props.children}
		</ApplicationManagementContext.Provider>
	);
};

export { ApplicationManagementContext, ApplicationManagementContextProvider };
