import { AutoComplete, Form, Select } from "antd";
import { SubmitButton } from "components/form/InputField";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const SupportTicketActionForm = ({
	issueTypeOptions,
	statusOptions,
	priorityOptions,
	departmentOptions,
	defaultAgentOptions,
}: {
	issueTypeOptions: any[];
	statusOptions: any[];
	priorityOptions: any[];
	departmentOptions: any[];
	defaultAgentOptions: any[];
}) => {
	const { t } = useTranslation(["support", "navigation"]);
	const [form] = Form.useForm();
	const { Option } = Select;
	const selectedDepartment = Form.useWatch("department", form);
	const [agentOptions, setAgentOptions] = useState(defaultAgentOptions);
	const [departmentSearchResult, setDepartmentSearchResult] = useState<
		string[]
	>([]);
	const [agentSearchResult, setAgentSearchResult] = useState<string[]>([]);

	/* Search handling functions for auto complete inputs */
	const handleAutoCompleteSearch = (
		searchTerm: string,
		defaultOptions: any[],
		setResFunc: (res: any) => void
	) => {
		const result = defaultOptions.filter((element: any) =>
			element.name.toLowerCase().includes(searchTerm.toLowerCase())
		);
		setResFunc(result);
		if (!searchTerm) {
			setResFunc(defaultOptions);
		}
	};

	/* Filter agent options by selected department */
	const filterAgents = () => {
		if (selectedDepartment) {
			setAgentOptions(
				defaultAgentOptions.filter(
					(agent: any) => agent.department === selectedDepartment
				)
			);
		}
	};

	useEffect(() => filterAgents(), [selectedDepartment]);

	const formItemLayout = {
		labelCol: { span: 24 },
		wrapperCol: { span: 24 },
	};

	const onFinish = (values: any) => {
		// eslint-disable-next-line no-console
		console.log("Received values of form: ", values);
	};

	return (
		<div>
			<p className="text-base font-bold">{t("changeProperties")}</p>
			<Form
				form={form}
				{...formItemLayout}
				name="register"
				onFinish={onFinish}
				scrollToFirstError
			>
				{/* Issue Type select */}
				<Form.Item
					name="type"
					label={t("type")}
					rules={[
						{
							required: true,
							message: t("errorMsg.type"),
						},
					]}
				>
					<Select>
						{issueTypeOptions.map((issueType: any) => (
							<Option key={issueType.key} value={issueType.name}>
								{t(issueType.name)}
							</Option>
						))}
					</Select>
				</Form.Item>

				{/* Status select */}
				<Form.Item
					name="status"
					label={t("status")}
					rules={[
						{
							required: true,
							message: t("errorMsg.status"),
						},
					]}
				>
					<Select>
						{statusOptions.map((status: any) => (
							<Option key={status.key} value={status.name}>
								{t(status.name)}
							</Option>
						))}
					</Select>
				</Form.Item>

				{/* Priority select */}
				<Form.Item
					name="priority"
					label={t("priority")}
					rules={[
						{
							required: true,
							message: t("errorMsg.priority"),
						},
					]}
				>
					<Select>
						{priorityOptions.map((priority: any) => (
							<Option key={priority.key} value={priority.name}>
								{t(priority.name)}
							</Option>
						))}
					</Select>
				</Form.Item>

				{/* Department select */}
				<Form.Item
					name="department"
					label={t("department")}
					rules={[
						{
							required: true,
							message: t("errorMsg.department"),
						},
					]}
				>
					<AutoComplete
						onSearch={(term) =>
							handleAutoCompleteSearch(
								term,
								departmentOptions,
								setDepartmentSearchResult
							)
						}
						placeholder={t("typeToSearch")}
						className="contact-auto-complete"
						allowClear
						/* Refer to index.css for the "contact-auto-complete" class */
						onClear={() => setAgentSearchResult(defaultAgentOptions)}
					>
						{departmentSearchResult.map((department: any) => (
							<Option key={department.key} value={department.name}>
								{department.name}
							</Option>
						))}
					</AutoComplete>
				</Form.Item>

				{/* Agent select */}
				<div className="border-l-2 pl-4 py-1 mb-4">
					<Form.Item
						name="agent"
						label={t("agent")}
						style={{ marginBottom: "1%" }}
						rules={[
							{
								required: true,
								message: t("errorMsg.agent"),
							},
						]}
					>
						<AutoComplete
							onSearch={(term) =>
								handleAutoCompleteSearch(
									term,
									agentOptions,
									setAgentSearchResult
								)
							}
							placeholder={
								!selectedDepartment ? t("selectDepartment") : t("typeToSearch")
							}
							className="contact-auto-complete"
							disabled={!selectedDepartment}
							allowClear
							/* Refer to index.css for the "contact-auto-complete" class */
						>
							{agentSearchResult.map((agent: any) => (
								<Option key={agent.key} value={agent.name}>
									{agent.name}
								</Option>
							))}
						</AutoComplete>
					</Form.Item>
				</div>
				<div className="flex justify-center mt-10">
					<SubmitButton
						className="rounded w-2/4 justify-center"
						type="submit"
						onClick={() => {}}
						label="Update"
					/>
				</div>
			</Form>
		</div>
	);
};

export default SupportTicketActionForm;
