import Breadcrumbs from "components/breadcrumbs";
import SearchInput from "components/common/SearchInput";
import StationFilter from "components/stations/StationFilter";
import {
	ChargingStationDataSource,
	ChargingStationParams,
	ChargingStationResponse,
	SiteParams,
	SiteResponse,
	SiteOwnerParams,
	SiteOwnerResponse,
} from "types/Stations";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import AlertModal from "components/modals/AlertModal";
import { SubmitButton } from "components/form/InputField";
import { SiteContext } from "context/SitesContext";
import MoreOptionsMenu from "components/common/MoreOptionsMenu";
import SlidePage from "components/common/SlidePage";
import { Pagination } from "antd";
import { BreadCrumbsProps } from "types";
import SiteMap from "./SiteMap";
import CreateEditSites from "./CreateEditSites";
import SitesAccordion from "./SitesAccordion";
import Loading from "../../../assets/svg/assets/Loading";
import CreateEditChargingStations from "./CreateEditChargingStations";
import AddEditSiteOwners from "../siteOwners/AddEditSiteOwners";

const SiteOwner = ({
	handleSubmitAddEditSites,
	isLoading,
	sites,
	siteOwnerUUID,
	pageTotal,
	handleSubmitAddEditChargingStation,
	inViewLoading,
	chargers,
	handlePageChange,
	handleFetchChargers,
	handleSubmitAddEditSiteOwner,
	siteOwner,
}: {
	handleSubmitAddEditSites: (props: SiteParams) => Promise<boolean>;
	isLoading: boolean;
	sites: SiteResponse[];
	pageTotal: number;
	inViewLoading: boolean;
	siteOwnerUUID: string;
	chargers: ChargingStationResponse[];
	handleSubmitAddEditChargingStation: (
		props: ChargingStationParams
	) => Promise<boolean>;
	handleFetchChargers: (uuid: string) => void;
	handlePageChange: (page: number, pageSize: number) => void;
	handleSubmitAddEditSiteOwner: (props: SiteOwnerParams) => Promise<boolean>;
	siteOwner: SiteOwnerResponse;
}) => {
	const { t } = useTranslation(["sites", "navigation", "common"]);
	const [showMapModal, setShowMapModal] = useState<boolean>(false);
	const [showAlert, setShowAlert] = useState<boolean>(false);
	const [selectedSiteUuid, setSelectedSiteUuid] = useState<string | null>(null);
	const [showSlidInPage, setShowSlidInPage] = useState<boolean>(false);
	const [showAddEditSiteOwnerModal, setShowAddEditSiteOwnerModal] =
		useState<boolean>(false);
	const [showAddEditModal, setShowAddEditModal] = useState<boolean>(false);
	const [showAddEditChargingStationModal, setShowAddEditChargingStationModal] =
		useState<boolean>(false);

	const {
		handleSelectedSite,
		selectedSite,
		handleSelectedChargingStation,
		selectedChargingStation,
	} = useContext(SiteContext);

	const routes: BreadCrumbsProps[] = [
		{
			path: "/dashboards",
			breadcrumbName: t("home", { ns: "navigation" }),
		},
		{
			path: "/site-owners",
			breadcrumbName: t("siteOwners", { ns: "navigation" }),
		},
		{
			path: `/site-owners/site-owner/${siteOwnerUUID}`,
			breadcrumbName: t("siteOwner", { ns: "navigation" }),
		},
	];

	const handleToggleShowAddEditSiteOwnerModal = () => {
		setShowAddEditSiteOwnerModal(!showAddEditSiteOwnerModal);
	};

	const handleSiteOwnerSubmit = async (props: SiteOwnerParams) => {
		handleSubmitAddEditSiteOwner(props);
	};

	const handleShowMapModal = (data?: SiteResponse) => {
		setShowMapModal(!showMapModal);
		handleSelectedSite(data || null);
	};

	const handleShowAlertModal = () => {
		setShowAlert(!showAlert);
	};

	const handleToggleShowAddEditModal = (data?: SiteResponse) => {
		setShowAddEditModal(!showAddEditModal);
		handleSelectedSite(data || null);
	};

	const handleToggleShowAddEditChargingStationModal = (
		uuid?: string,
		data?: ChargingStationDataSource
	) => {
		setShowAddEditChargingStationModal(!showAddEditChargingStationModal);

		if (uuid) {
			setSelectedSiteUuid(uuid);
		}

		if (data) {
			const station = chargers.find(
				(chargingStation) => chargingStation.vendor === data.vendor
			);

			station && handleSelectedChargingStation(station);
		} else {
			handleSelectedChargingStation(null);
		}
	};

	const handleSubmit = async (data: SiteParams) => {
		const res = await handleSubmitAddEditSites(data);
		if (res) {
			handleToggleShowAddEditModal();
		}
	};

	const handleSubmitChargingStation = async (data: ChargingStationParams) => {
		const res = await handleSubmitAddEditChargingStation(data);
		if (res) {
			handleToggleShowAddEditChargingStationModal();
		}
	};

	const siteOptionList = [
		{
			label: t("editSiteOwner"),
			onClick: () => handleToggleShowAddEditSiteOwnerModal(),
		},
	];

	return (
		<div>
			<Breadcrumbs breadCrumb={routes} />
			<div className="flex w-full justify-end items-center mt-5">
				<MoreOptionsMenu
					buttonClassName="py-2 px-6 rounded-md mr-2 bg-white text-black"
					label={t("options", { ns: "common" })}
					size="md"
					menuList={siteOptionList}
				/>
			</div>

			<div className="flex mt-5 items-center justify-end sm:justify-between flex-wrap gap-4">
				<SubmitButton
					className="rounded-[5px] order-1"
					type="button"
					onClick={() => handleToggleShowAddEditModal()}
					label={t("createSite")}
				/>
				<div className="flex items-center">
					<StationFilter>
						<h1>{t("filter", { ns: "common" })}</h1>
						<div className="border-solid border-b my-3" />
						<div className="flex justify-between">
							{t("filterOptions.availability")}
						</div>
					</StationFilter>
					<div className="w-64 sm:w-full">
						<SearchInput handleSubmit={() => {}} placeholder="Search Sites" />
					</div>
				</div>
			</div>

			<div className="w-full">
				<h1 className="text-xl my-5">
					{t("sites")} ({sites.length})
				</h1>
				{!isLoading ? (
					<>
						{sites.map((site) => (
							<SitesAccordion
								handleToggleShowAddEditChargingStationModal={
									handleToggleShowAddEditChargingStationModal
								}
								handleFetchChargingStation={handleFetchChargers}
								handleToggleShowAddEditModal={handleToggleShowAddEditModal}
								handleShowMapModal={handleShowMapModal}
								key={site.uuid}
								site={site}
								siteOwnerUUID={siteOwnerUUID}
								chargingStations={chargers}
							/>
						))}
					</>
				) : (
					<div className="w-full flex m-auto">
						<Loading />
					</div>
				)}
				<div className="w-full flex items-end">
					<Pagination
						defaultCurrent={1}
						total={pageTotal}
						hideOnSinglePage
						responsive
						showLessItems
						onChange={(page, pageSize) => handlePageChange(page, pageSize)}
					/>
				</div>
			</div>

			<SiteMap
				handleShowModal={handleShowMapModal}
				showModal={showMapModal}
				site={selectedSite}
				chargers={chargers}
				isLoading={inViewLoading}
				handleToggleSiteDetail={() => {}}
				handleFetchChargers={handleFetchChargers}
			/>

			<AlertModal
				message="Are you sure you want to delete this product?"
				show={showAlert}
				type="warning"
				deny={handleShowAlertModal}
				onClose={handleShowAlertModal}
				confirm={handleShowAlertModal}
			/>

			<CreateEditSites
				isLoading={isLoading}
				showModal={showAddEditModal}
				selectedSite={selectedSite}
				handleShowModal={handleToggleShowAddEditModal}
				onSubmitForm={handleSubmit}
			/>
			<CreateEditChargingStations
				isLoading={isLoading}
				showModal={showAddEditChargingStationModal}
				selectedChargingStation={selectedChargingStation}
				handleShowModal={handleToggleShowAddEditChargingStationModal}
				onSubmitForm={handleSubmitChargingStation}
				siteUuid={selectedSiteUuid}
			/>

			<AddEditSiteOwners
				isLoading={isLoading}
				showModal={showAddEditSiteOwnerModal}
				handleShowModal={handleToggleShowAddEditSiteOwnerModal}
				onSubmitForm={handleSiteOwnerSubmit}
				selectedSiteOwner={siteOwner}
			/>

			<SlidePage
				open={showSlidInPage}
				toggleOpen={() => setShowSlidInPage(!showSlidInPage)}
				title="Charging Stations"
			>
				<p>rwt</p>
			</SlidePage>
		</div>
	);
};

export default SiteOwner;
