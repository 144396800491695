import AuthLayout from "Layouts/AuthLayout";
import CodeInput from "components/form/CodeInput";
import { SubmitButton } from "components/form/InputField";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ResendCode, VerificationCode } from "api/auth";

const totalDigit = 6;

export const CodeForm = ({
	onSubmit,
	isForm = false,
}: {
	isForm?: boolean;
	onSubmit: (code: string) => any;
}) => {
	const { t } = useTranslation(["accounts", "common"]);
	const [code, setCode] = useState(new Array(totalDigit).fill(" ").join(""));
	const [smsCodeResent, setSMSCodeResent] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState("");

	const nonEmptyDigits = (code && code.replace(/\s/g, "").length) || 0;

	const onSubmitCode = async () => {
		setIsLoading(true);
		const res = await onSubmit(code);
		if (res) setError(res);
		setIsLoading(false);
	};

	const handleUpdateCode = (input: string) => {
		setCode(input);
		setError("");
	};

	const handleClickResend = async () => {
		await ResendCode({ token: code });
		// TODO: resend code
		setSMSCodeResent(true);
	};

	useEffect(() => {
		if (nonEmptyDigits === totalDigit) {
			onSubmitCode();
		}
	}, [nonEmptyDigits]);

	return (
		<div>
			{!isForm && (
				<>
					<h1 className="mb-3 text-3xl font-medium">{t("welcomeBack")}</h1>
					<div className="flex">
						<a href="/signup">
							<p className="ml-1 text-primary-color">{t("backToSignup")}</p>
						</a>
					</div>
				</>
			)}
			<div className="w-full flex justify-center items-center">
				<CodeInput
					code={code}
					onUpdate={handleUpdateCode}
					onSubmit={onSubmitCode}
				/>
			</div>

			{!isForm && (
				<>
					<p className="mt-8 mb-2">{t("resendCode.prompt")}</p>
					{smsCodeResent ? (
						<p className="text-primary-color">{t("resendCode.success")}</p>
					) : (
						<div
							onKeyDown={handleClickResend}
							onClick={handleClickResend}
							role="button"
							tabIndex={0}
							className="cursor-pointer"
						>
							<span className="text-primary-color">
								{t("resendCode.submit")}
							</span>
						</div>
					)}
				</>
			)}

			{error && (
				<span className="text-red-500 my-5">{t("resendCode.error")}</span>
			)}

			<SubmitButton
				onClick={onSubmitCode}
				type="submit"
				className="mt-8 flex m-auto my-5 text-center"
				label={t("verify", { ns: "common" })}
				loading={isLoading}
				disabled={!code || nonEmptyDigits < totalDigit}
			/>
		</div>
	);
};

const CodeVerification = () => {
	const onSubmit = async (code: string): Promise<string | null> => {
		const res = await VerificationCode({ token: code });

		// Do not use translation, string is returned from auth API:
		if (res.message !== "Your account was confirmed successfully") {
			return res.message;
		}
		window.location.href = "/login";
		return null;
	};

	return (
		<AuthLayout>
			<CodeForm onSubmit={onSubmit} />
		</AuthLayout>
	);
};

export default CodeVerification;
