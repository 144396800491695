import { Checkbox } from "antd";
import { Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import classNames from "classnames";

const MultiSelection = ({
	options,
	btnIcon,
	btnLabel,
	componentClassName,
	btnClassName,
	pnlClassName,
	onChange,
	columns,
	defaultValue,
}: {
	options: { label: string; value: string | boolean | number }[];
	btnIcon?: string | React.ReactNode;
	btnLabel?: string | React.ReactNode;
	componentClassName?: string;
	btnClassName?: string;
	pnlClassName?: string;
	onChange: (checkedValues: any[]) => any[] | void;
	columns?: string;
	defaultValue: any[];
}): JSX.Element => {
	const content = (
		<Checkbox.Group
			options={options}
			onChange={onChange}
			className={`!grid !grid-cols-${columns} gap-1`}
			defaultValue={defaultValue}
		/>
	);

	return (
		<Popover className={classNames("relative", componentClassName)}>
			<Popover.Button className={btnClassName}>
				{btnIcon} {btnLabel}
			</Popover.Button>
			<Transition
				as={Fragment}
				enter="transition ease-out duration-200"
				enterFrom="opacity-0 translate-y-0"
				enterTo="opacity-100 translate-y-1"
				leave="transition ease-in duration-150"
				leaveFrom="opacity-100 translate-y-1"
				leaveTo="opacity-0 translate-y-0"
			>
				<Popover.Panel
					className={classNames(
						"absolute z-10 bg-white rounded-lg p-2 mt-2 shadow-md",
						pnlClassName
					)}
				>
					{content}
				</Popover.Panel>
			</Transition>
		</Popover>
	);
};

export default MultiSelection;
